import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Appt Components
import Box from '../../components/simple/box/Box'
import DataTable from '../../components/simple/dataTable/DataTable.js'
import Button from '../../components/simple/button/Button'
import Text from '../../components/simple/text/Text'

// Services
import FormService from '../../services/FormService'
import LocalStorageService from '../../services/LocalStorageService'

const IncidentSearchResults = (props) => {
  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')

  const [submissions, setSubmissions] = useState([])
  const [responses, setResponses] = useState([])

  const history = useHistory()

  useEffect(() => {
    let unmounted = false

    // Get submissions
    const getSubmissions = async () => {
      const where = {
        organisationId: activeOrg.id,
        type: 'incidentreport',
        status: 'Published'
      }

      const incidentReportSubmissions = await FormService.getSubmissions(apiToken, where)

      if (!unmounted) {
        setSubmissions(incidentReportSubmissions.data)
        // console.log(userDetails.data)
      }
    }

    getSubmissions()

    return () => { unmounted = true }
  }, [])

  useEffect(() => {
    // Get responses
    const getResponses = async () => {
      const submissionIds = []

      for (const submission of submissions) {
        submissionIds.push(submission.id)
      }

      // submissions.forEach(submission => {
      //   submissionIds.push(submission.id)
      // })
      const where = {
        organisationId: activeOrg.id,
        submission: submissionIds
      }

      console.log('WHERE ', where)
      const incidentReportResponses = await FormService.getResponses(apiToken, where)
      console.log(incidentReportResponses)
      setResponses(incidentReportResponses.data)
    }

    if (submissions?.length) {
      getResponses()
    }
  }, [submissions])

  const resultColumns = [
    {
      property: 'id',
      header: <Text>Incident No</Text>
    },
    {
      property: 'user',
      header: <Text>Child</Text>
    },
    {
      property: 'yearGroup',
      header: <Text>Year Group</Text>
    },
    {
      property: 'studentGroup',
      header: <Text>Student Group</Text>
    },
    {
      property: 'actions',
      header: <Text>Actions</Text>,
      render: datum => (
        <>
          <Box direction='row' width='100%'>
            <Button
              onClick={() => { history.push(`/incident/${datum.id}/edit`) }}
              target='_self'
              plain
              disabled
              children={
                <Box pad='5px'>
                  <FontAwesomeIcon icon={['fal', 'edit']} color='#fe8300' />
                  {/* <FormEdit color='primary' size='medium' disabled /> */}
                </Box>
              }
            />

            <Button
              target='_self'
              plain
              // onClick={() => showDeleteUserOptions(datum)}
              disabled
              children={
                <Box pad='5px'>
                  <FontAwesomeIcon icon={['fal', 'download']} color='#fe8300' />
                  {/* <Download color='primary' size='medium' disabled /> */}
                </Box>
              }
            />
          </Box>
        </>
      )
    }
  ]

  return (
    <>
      <DataTable
        columns={resultColumns}
        data={props.results}
        primaryKey='id'
        // sort={sort}
        // onSort={setSort}
      />
    </>
  )
}

export default IncidentSearchResults
