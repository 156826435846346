import React, { useState, useEffect } from 'react'

import { useHistory, useParams } from 'react-router-dom'
import { useStore } from 'react-context-hook'

// Error handler
// import useErrorHandler from '../../utils/useErrorHandler'
// import FlashMessage from '../../utils/FlashMessage'

import ApiService from '../../services/ApiService'
import LocalStorageService from '../../services/LocalStorageService'
import NotificationService from '../../services/NotificationService'
import OrganisationService from '../../services/OrganisationService'
import UserService from '../../services/UserService'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor'
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import Divider from '../../components/simple/divider/Divider'
import Form from '../../components/simple/form/Form.js'
import FormField from '../../components/simple/formField/FormField.js'
import Select from '../../components/simple/input/Select'
import Text from '../../components/simple/text/Text'
import TextInput from '../../components/simple/input/TextInput'

function UserEdit () {
  const history = useHistory()
  // const { error, showError } = useErrorHandler(null)

  // const [apiToken, setApiToken, deleteApiToken] = useStore()
  // const [currentUser, setCurrentUser, deleteCurrentUser] = useStore({})
  // const [currentOrgs, setCurrentOrgs, deleteCurrentOrgs] = useStore([])
  // const [activeOrg, setActiveOrg, deleteActiveOrg] = useStore()

  const apiToken = LocalStorageService.get('apiToken')

  // Initialize userDetails otherwise we get error
  // A component is changing an uncontrolled input of type text to be controlled
  const [userDetails, setUserDetails] = useState({
    organisation: 0,
    role: 0,
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    jobtitle: ''
  })

  // Contains array of org objects for populating the School dropdown
  const [orgValues, setOrgValues] = useState([])

  // Contains an Organisation object that has been selected from the School dropdown
  const [selectedOrg, setSelectedOrg] = useState(null)

  // Contains array of role objects for populating the Role dropdown
  const [roleValues, setRoleValues] = useState([])

  // const roles = ['Teacher', 'Supervisor', 'Headteacher']

  const params = useParams()

  const userId = params.userid

  // Submit form values
  const submitForm = async (values) => {
    // console.log('submitForm ', values)

    if (userId === 'new') {
      var userAdded = await UserService.inviteUser(apiToken, values)
      if (userAdded.error) {
        NotificationService.error('Error saving the user, please try again - ', userAdded.error)
      } else {
        NotificationService.info('User added')
        history.push('/users', { message: userAdded.data.message })
      }
    } else {
      var userUpdated = await UserService.updateUser(apiToken, userId, values)
      if (userUpdated.error) {
        NotificationService.error('Error saving the user, please try again - ', userUpdated.error)
      } else {
        NotificationService.error('User updated successfully')
        history.push('/users', { message: 'User updated successfully' })
      }
    }
  }

  // A different school has been selected
  const changeOrganisation = (org) => {
    setSelectedOrg(org)
  }

  // Reset User password
  const resetPassword = async (email) => {
    try {
      var passwordResetStatus = await ApiService.resetPassword(null, email)
    } catch (err) {
      NotificationService.error('Password reset error - ', err)
      console.log(err)
      return
    }

    NotificationService.error('Password reset email has been sent', passwordResetStatus)
  }

  // Go Back
  const goBack = () => {
    history.goBack()
  }

  // Basically componentDidMount
  useEffect(() => {
    let unmounted = false

    const getOrganisations = async () => {
      // const apiToken = LocalStorageService.get('apiToken')

      const organisations = LocalStorageService.get('userOrganisations')
      if (organisations.error) {
        NotificationService.error(organisations.error)
      } else {
        if (!unmounted) {
          // const mappedOrganisations = organisations.data.map((data, index) => ({
          //   id: data.id,
          //   name: data.name,
          //   createdAt: data.createdAt
          // }))
          setOrgValues(organisations)
        }
      }
    }

    const getUserDetails = async (userId) => {
      // const apiToken = LocalStorageService.get('apiToken')

      const userDetails = await UserService.getUser(apiToken, userId)
      if (userDetails.error) {
        NotificationService.error(userDetails.error)
      } else {
        if (!unmounted) {
          setUserDetails(userDetails.data[0])
        }
      }
    }

    getOrganisations()

    if (userId !== 'new') {
      getUserDetails(userId)
    }

    return () => { unmounted = true }
  }, [])

  // School has changed so get roles asssoicated with that school
  useEffect(() => {
    // Get roles associated with the selected school
    const getRoles = async () => {
      // const apiToken = LocalStorageService.get('apiToken')

      const roles = await OrganisationService.getRolesForOrg(apiToken, selectedOrg.id)
      if (roles.error) {
        NotificationService.error(roles.error)
      } else {
        console.log('roles ', roles)
        setRoleValues(roles.data)
      }
    }

    if (selectedOrg) {
      getRoles()
    }
  }, [selectedOrg])

  return (
    <Box width='xlarge'>
      <Box background='white' elevation='small' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>
        <Box direction='column' pad={{ horizontal: 'medium' }}>
          <Text margin={{ top: 'small' }} size='xlarge'>User: {userDetails.id ? userDetails.firstName + ' ' + userDetails.lastName : 'New User'}</Text>
          {/* {error &&
            <FlashMessage message={error} />} */}
          <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'><Anchor href='/'>Snowdrop CRM</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></Text>
        </Box>
      </Box>

      {userDetails.id &&
        <Box background='white' elevation='small' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>
          <Box direction='row-responsive' justify='between' pad={{ horizontal: 'medium', vertical: 'small' }}>
            <Box>
              <Text size='xxlarge' weight='400' margin={{ top: 'none' }}>Quick Actions</Text>
            </Box>
            <Box alignSelf='center' direction='row'>
              <Button label={<Text><FontAwesomeIcon icon={['fal', 'key']} /> Reset Password</Text>} margin={{ horizontal: 'xsmall' }} onClick={() => { resetPassword(userDetails.email) }} target='_self' />
              <Button href='userdelete' label={<Text><FontAwesomeIcon icon={['fal', 'trash']} /> Delete User</Text>} margin={{ horizontal: 'xsmall' }} target='_self' />
            </Box>
          </Box>
        </Box>}

      <Box background='white' elevation='small' direction='column' gap='small' round='small'>
        <Box
          gap='small'
          margin={{ horizontal: 'small' }}
          pad='small'
          round='small'
        >
          <Box direction='column' gap='medium'>
            <Text margin={{ top: 'xsmall' }} size='xlarge'>Permissions</Text>
            <Form
              //   validate='blur'
              onChange={nextValue => {
                // console.log(nextValue)
                setUserDetails(nextValue)
              }}
              onSubmit={({ value: nextValue }) => {
                // console.log(nextValue)
                submitForm(nextValue)
              }}
              value={userDetails}
            >
              <FormField
                label='Organisation'
                name='organisation'
                required
              >
                <Select
                  id='organisation'
                  name='organisation'
                  labelKey='name'
                  placeholder='Organisation'
                  onChange={({ option }) => changeOrganisation(option)}
                  options={orgValues}
                  valueKey={{ key: 'id', reduce: true }}
                />
              </FormField>

              <Box direction='row' gap='medium'>
                <FormField
                  label='Username'
                  name='username'
                  required
                >
                  <TextInput
                    name='username'
                    type='text'
                  />
                </FormField>
                <FormField
                  label='System Role'
                  name='role'
                  required
                >
                  <Select
                    id='role'
                    name='role'
                    labelKey='title'
                    placeholder='System Role'
                    options={roleValues}
                    valueKey={{ key: 'id', reduce: true }}
                  />
                </FormField>
              </Box>

              <Text margin={{ top: 'xsmall' }} size='xlarge'>User Details</Text>
              <Box direction='row' gap='medium'>
                <FormField
                  label='Firstname'
                  name='firstName'
                  required
                >
                  <TextInput
                    name='firstName'
                    type='text'
                  />
                </FormField>

                <FormField
                  label='Lastname'
                  name='lastName'
                  required
                >
                  <TextInput
                    name='lastName'
                    type='text'
                  />
                </FormField>
              </Box>

              <Box direction='row' gap='medium'>
                <FormField
                  label='Email'
                  name='email'
                  required
                >
                  <TextInput
                    name='email'
                    type='email'
                  />
                </FormField>

                <FormField
                  label='Job Title'
                  name='jobtitle'
                  required
                >
                  <TextInput
                    name='jobtitle'
                    type='text'
                  />
                </FormField>
              </Box>

              {userId !== 'new' &&
                <>
                </>}
              <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />

              <Box direction='row' justify='between' margin={{ top: 'medium' }}>
                <Button label='< Back' onClick={() => goBack()} secondary />
                <Button type='submit' label='Save' primary />
              </Box>
            </Form>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default UserEdit
