import React, { useContext, useState, useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import { constants } from '../../utils/constants'
import Responsive from '../../utils/Responsive'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import moment from 'moment'

// Spinner
import PulseLoader from 'react-spinners/PulseLoader'

// Appt Components
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import DateInput from '../../components/simple/input/DateInput'
import DataTable from '../../components/simple/dataTable/DataTable.js'
import Divider from '../../components/simple/divider/Divider'
import Form from '../../components/simple/form/Form.js'
import FormField from '../../components/simple/formField/FormField.js'
import H2 from '../../components/simple/heading/H2.js'
import OptionButtons from '../../components/compound/optionButtons/OptionButtons'
import Select from '../../components/simple/input/Select'
import Text from '../../components/simple/text/Text'
import TextArea from '../../components/simple/input/TextArea'
import TextInput from '../../components/simple/input/TextInput'

function Personal (props) {
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [localUserDetails, setLocalUserDetails] = useState(props.userDetails)

  const [questions, setQuestions] = useState(props.questions)

  // Children
  const [children, setChildren] = useState([])
  const [childrenAddButton, setChildrenAddButton] = useState(false)
  const [mappedChildren, setMappedChildren] = useState([])
  const [childrenColumns, setChildrenDataColumns] = useState()

  const addChild = () => {
    // Don't save if all fields empty
    if (localUserDetails.firstNameChild !== '' || localUserDetails.lastNameChild !== '' || localUserDetails.dobChild !== '' || localUserDetails.livingWithClientChild !== '') {
      const childrenData = {
        childKey: children.length,
        firstNameChild: localUserDetails.firstNameChild,
        lastNameChild: localUserDetails.lastNameChild,
        dobChild: localUserDetails.dobChild,
        livingWithClientChild: localUserDetails.livingWithClientChild
      }

      setChildren(prevState => [...prevState, childrenData])

      setLocalUserDetails(prevState => ({
        ...prevState,
        firstNameChild: '',
        lastNameChild: '',
        dobChild: '',
        livingWithClientChild: ''
      }))

      setChildrenAddButton(false)
    }
  }

  const removeChild = (data) => {
    const newChildren = children.filter((item) => item.childKey !== data.childKey)

    // Renumber the children keys
    newChildren.forEach((child, index) => {
      child.childKey = index
    })

    setChildren(newChildren)
  }

  const editChild = (data) => {
    setLocalUserDetails(prevState => ({
      ...prevState,
      firstNameChild: data.firstNameChild,
      lastNameChild: data.lastNameChild,
      dobChild: moment(data.dobChild).format('YYYY-DD-MM'),
      livingWithClientChild: data.livingWithClientChild
    }))

    removeChild(data)
  }

  // Repeat for renovations
  const [renovations, setRenovations] = useState([])
  const [renovationsAddButton, setRenovationsAddButton] = useState(false)
  const [mappedRenovations, setMappedRenovations] = useState([])
  const [renovationColumns, setRenovationDataColumns] = useState()

  const addRenovation = () => {
    // Don't save if all fields empty
    if (localUserDetails.renovationDate !== '' || localUserDetails.renovationDescription !== '') {
      const renovation = {
        renovationKey: renovations.length,
        renovationDate: localUserDetails.renovationDate,
        renovationDescription: localUserDetails.renovationDescription
      }
      setRenovations(prevState => [...prevState, renovation])

      setLocalUserDetails(prevState => ({
        ...prevState,
        renovationDate: '',
        renovationDescription: ''
      }))
      setRenovationsAddButton(false)
    }
  }

  const removeRenovation = (data) => {
    const newRenovations = renovations.filter((item) => item.renovationKey !== data.renovationKey)

    // Renumber the renovations keys
    newRenovations.forEach((renovation, index) => {
      renovation.renovationKey = index
    })

    setRenovations(newRenovations)
  }

  const editRenovation = (data) => {
    setLocalUserDetails(prevState => ({
      ...prevState,
      renovationDate: moment(data.renovationDate).format('YYYY-DD-MM'),
      renovationDescription: data.renovationDescription
    }))

    removeRenovation(data)
  }

  // Submit User Details
  const submitUserDetails = () => {
    props.submitForm(localUserDetails)
  }

  useEffect(() => {
    if (children && children.length) {
      setChildrenDataColumns([
        {
          property: 'firstNameChild',
          header: <Text>First Name</Text>
        },
        {
          property: 'lastNameChild',
          header: <Text>Last Name</Text>
        },
        {
          property: 'dobChild',
          header: <Text>Date of Birth</Text>
        },
        {
          property: 'livingWithClientChild',
          header: <Text>Living with Client</Text>
        },
        {
          property: 'actions',
          render: datum => (
            <Box direction='row'>
              <Button label={<Text><FontAwesomeIcon icon={['fal', 'minus-circle']} /></Text>} margin={{ horizontal: 'xsmall' }} style={{ background: '#333' }} onClick={() => removeChild(datum)} target='_self' />
              <Button label={<Text><FontAwesomeIcon icon={['fal', 'edit']} /></Text>} margin={{ horizontal: 'xsmall' }} style={{ background: '#333' }} onClick={() => editChild(datum)} target='_self' />
            </Box>
          )
        }
      ])

      const mappedChildren = children.map((child, index) => ({
        childKey: index,
        firstNameChild: child.firstNameChild,
        lastNameChild: child.lastNameChild,
        dobChild: moment(child.dobChild).format('DD-MM-YYYY'),
        livingWithClientChild: child.livingWithClientChild
      }))

      setMappedChildren(mappedChildren)

      // Stringify children and store them
      const childrenToSave = JSON.stringify(children)

      setLocalUserDetails(prevState => ({
        ...prevState,
        children: childrenToSave
      }))
    } else {
      setMappedChildren([])

      setLocalUserDetails(prevState => ({
        ...prevState,
        children: []
      }))
    }
  }, [children])

  useEffect(() => {
    if (renovations && renovations.length) {
      setRenovationDataColumns([
        {
          property: 'renovationDate',
          header: <Text>Date</Text>
        },
        {
          property: 'renovationDescription',
          header: <Text>Description</Text>
        },
        {
          property: 'actions',
          render: datum => (
            <Box direction='row'>
              <Button label={<Text><FontAwesomeIcon icon={['fal', 'minus-circle']} /></Text>} margin={{ horizontal: 'xsmall' }} style={{ background: '#333' }} onClick={() => removeRenovation(datum)} target='_self' />
              <Button label={<Text><FontAwesomeIcon icon={['fal', 'edit']} /></Text>} margin={{ horizontal: 'xsmall' }} style={{ background: '#333' }} onClick={() => editRenovation(datum)} target='_self' />
            </Box>
          )
        }
      ])

      const mappedRenovations = renovations.map((renovation, index) => ({
        renovationKey: index,
        renovationDate: moment(renovation.renovationDate).format('DD-MM-YYYY'),
        renovationDescription: renovation.renovationDescription
      }))

      setMappedRenovations(mappedRenovations)

      // Stringify renovations and store them
      const renovationsToSave = JSON.stringify(renovations)

      setLocalUserDetails(prevState => ({
        ...prevState,
        renovations: renovationsToSave
      }))
    } else {
      setMappedRenovations([])

      setLocalUserDetails(prevState => ({
        ...prevState,
        renovations: []
      }))
    }
  }, [renovations])

  // Need to include this as it takes time for
  // props.userDetails to get populated
  useEffect(() => {
    setLocalUserDetails(props.userDetails)
    setChildren(props.userDetails.children)
    setRenovations(props.userDetails.renovations)
  }, [props.userDetails])

  return (
    <Box gridArea='main' background='white' direction='column' gap='small' round='small'>
      <Divider color='primary' />
      <Box
        gap='small'
        margin={{ horizontal: 'small' }}
        pad='small'
        round='small'
      >

        <Box fill direction='row' gap='small'>
          <H2 margin={{ vertical: 'none' }}>Personal</H2>
          <Button label={<FontAwesomeIcon icon={['fal', 'question-circle']} />} plain tip='Personal Client Details' />
        </Box>
        {localUserDetails && questions.length
          ? (
            <Form
              onChange={nextValue => {
                // Enable Child Add button if all fields have data
                setChildrenAddButton(nextValue.firstNameChild !== '' && nextValue.lastNameChild !== '' && nextValue.dobChild !== '' && nextValue.livingWithClientChild !== '')

                // Enable Renovations Add button if all fields have data
                setRenovationsAddButton(nextValue.renovationDate !== '' && nextValue.renovationDescription !== '')
                setLocalUserDetails(nextValue)
              }}
              onSubmit={({ value: nextValue }) => {
                submitUserDetails(nextValue)
              }}
              value={localUserDetails}
            >
              <Responsive
                rows={['auto']}
                columns={{
                  small: ['auto'],
                  medium: ['2/3', '1/3'],
                  large: ['2/3', '1/3'],
                  xlarge: ['2/3', '1/3']
                }}
                gap='small'
              >
                <Box>
                  <Box>
                    <Text weight='bold'>Contact Information</Text>
                  </Box>

                  <Responsive
                    columns={{
                      small: ['auto'],
                      medium: ['auto', 'auto', 'auto'],
                      large: ['auto', 'auto', 'auto'],
                      xlarge: ['auto', 'auto', 'auto']
                    }}
                    gap='small'
                  >
                    <Box>
                      {/* Address */}
                      <FormField
                        label={questions[0].questionText}
                        name={questions[0].key}
                      >
                        <TextInput
                          name={questions[0].key}
                          type='text'
                        />
                      </FormField>
                    </Box>

                    <Box>
                      {/* Postcode */}
                      <FormField
                        label={questions[1].questionText}
                        name={questions[1].key}
                      >
                        <TextInput
                          name={questions[1].key}
                          type='text'
                        />
                      </FormField>
                    </Box>

                    <Box>
                      {/* City */}
                      <FormField
                        label={questions[2].questionText}
                        name={questions[2].key}
                      >
                        <TextInput
                          name={questions[2].key}
                          type='text'
                        />
                      </FormField>
                    </Box>
                  </Responsive>

                  <Responsive
                    columns={{
                      small: ['auto'],
                      medium: ['auto', 'auto', 'auto'],
                      large: ['auto', 'auto', 'auto'],
                      xlarge: ['auto', 'auto', 'auto']
                    }}
                    gap='small'
                  >
                    <Box>
                      {/* Mobile */}
                      <FormField
                        label={questions[3].questionText}
                        name={questions[3].key}
                      >
                        <TextInput
                          name={questions[3].key}
                          type='text'
                        />
                      </FormField>
                    </Box>

                    <Box>
                      {/* Email */}
                      <FormField
                        label={questions[4].questionText}
                        name={questions[4].key}
                      >
                        <TextInput
                          name={questions[4].key}
                          type='email'
                        />
                      </FormField>
                    </Box>

                    <Box>
                      {/* Preferred Contact Method */}
                      <FormField
                        label={questions[5].questionText}
                        name={questions[5].key}
                      >
                        <Select
                          name={questions[5].key}
                          emptySearchMessage='No contact methods found'
                          // onChange={
                          //   (event, option) => { setAcademicYear(event.value) }
                          // }
                          labelKey='label'
                          options={constants.contactMethods}
                          valueKey={{ key: 'value', reduce: true }}
                        />
                      </FormField>
                    </Box>
                  </Responsive>

                  <Divider color='lightgrey' margin={{ top: 'small', bottom: 'small' }} />


                  <Responsive
                    columns={{
                      small: ['auto'],
                      medium: ['auto', 'auto'],
                      large: ['auto', 'auto'],
                      xlarge: ['auto', 'auto']
                    }}
                    gap='small'
                  >
                    <Box>
                      {/* Client DOB */}
                      <FormField
                        label={questions[6].questionText}
                        name={questions[6].key}
                      >
                        <DateInput
                          name={questions[6].key}
                        />
                      </FormField>
                    </Box>

                    <Box>
                      {/* Disability Diagnosis */}
                      <FormField
                        label={questions[7].questionText}
                        name={questions[7].key}
                      >
                        <TextInput
                          name={questions[7].key}
                          type='text'
                        />
                      </FormField>
                    </Box>
                  </Responsive>

                  <Divider color='lightgrey' margin={{ top: 'small', bottom: 'small' }} />


                  <Box>
                    <Text weight='bold'>Partner</Text>
                  </Box>

                  <Responsive
                    columns={{
                      small: ['auto'],
                      medium: ['auto', 'auto', 'auto'],
                      large: ['auto', 'auto', 'auto'],
                      xlarge: ['auto', 'auto', 'auto']
                    }}
                    gap='small'
                  >
                    <Box>
                      {/* Partner Name */}
                      <FormField
                        label={questions[8].questionText}
                        name={questions[8].key}
                      >
                        <TextInput
                          name={questions[8].key}
                          type='text'
                        />
                      </FormField>
                    </Box>

                    <Box>
                      {/* Partner DOB */}
                      <FormField
                        label={questions[9].questionText}
                        name={questions[9].key}
                      >
                        <DateInput
                          name={questions[9].key}
                        />
                      </FormField>
                    </Box>

                    <Box>
                      {/* Partner Living with Client */}
                      <FormField
                        label={questions[10].questionText}
                        name={questions[10].key}
                      >
                        <OptionButtons
                          id={questions[10].key}
                          name={questions[10].key}
                        />
                      </FormField>
                    </Box>
                  </Responsive>

                  <Divider color='lightgrey' margin={{ top: 'small', bottom: 'small' }} />


                  <Box>
                    <Text weight='bold'>Children</Text>
                  </Box>

                  <Responsive
                    columns={{
                      small: ['auto'],
                      medium: ['auto'],
                      large: ['auto'],
                      xlarge: ['auto']
                    }}
                    gap='small'
                  >
                    {(mappedChildren.length > 0) &&
                      <Box direction='row-responsive' gap='medium'>
                        <DataTable
                          columns={childrenColumns}
                          data={mappedChildren}
                          fill='horizontal'
                          primaryKey={false}
                          size='large'
                        />
                      </Box>}

                    <Box direction='row-responsive' gap='medium'>
                      {/* First Name */}
                      <FormField
                        label={(<Box height='40px'>{questions[11].questionText}</Box>)}
                        name={questions[11].key}
                      >
                        <TextInput
                          name={questions[11].key}
                          type='text'
                        />
                      </FormField>

                      {/* Last Name */}
                      <FormField
                        label={(<Box height='40px'>{questions[12].questionText}</Box>)}
                        name={questions[12].key}
                      >
                        <TextInput
                          name={questions[12].key}
                          type='text'
                        />
                      </FormField>

                      {/* DoB */}
                      <FormField
                        label={(<Box height='40px'>{questions[13].questionText}</Box>)}
                        name={questions[13].key}
                      >
                        <DateInput
                          name={questions[13].key}
                        />
                      </FormField>

                      {/* Living with Client */}
                      <FormField
                        label={(<Box height='40px'>{questions[14].questionText}</Box>)}
                        name={questions[14].key}
                      >
                        <OptionButtons
                          id={questions[14].key}
                          name={questions[14].key}
                        />
                      </FormField>

                      <Button disabled={!childrenAddButton} label={<Text><FontAwesomeIcon icon={['fal', 'plus-circle']} /></Text>} color='primary' onClick={() => addChild()} style={{ height: '35px' }} alignSelf='end' margin={{ bottom: 'small' }} />
                    </Box>

                  </Responsive>

                  <Divider color='lightgrey' margin={{ top: 'small', bottom: 'small' }} />


                  <Box>
                    <Text weight='bold'>Immigration Status</Text>
                  </Box>

                  <Responsive
                    columns={{
                      small: ['auto'],
                      medium: ['auto', 'auto', 'auto'],
                      large: ['auto', 'auto', 'auto'],
                      xlarge: ['auto', 'auto', 'auto']
                    }}
                    gap='small'
                  >
                    {/* HO Reference Number */}
                    <FormField
                      label={questions[15].questionText}
                      name={questions[15].key}
                    >
                      <TextInput
                        name={questions[15].key}
                        type='text'
                      />
                    </FormField>

                    {/* Immigration Status */}
                    <FormField
                      label={questions[16].questionText}
                      name={questions[16].key}
                    >
                      <Select
                        name={questions[16].key}
                        emptySearchMessage='No values found'
                        // onChange={
                        //   (event, option) => { setAcademicYear(event.value) }
                        // }
                        labelKey='label'
                        options={constants.immigrationStatus}
                        valueKey={{ key: 'value', reduce: true }}
                      />
                    </FormField>

                    {/* NINO */}
                    <FormField
                      label={questions[17].questionText}
                      name={questions[17].key}
                    >
                      <TextInput
                        name={questions[17].key}
                        type='text'
                      />
                    </FormField>

                    {/* NRM Status */}
                    <FormField
                      label={questions[18].questionText}
                      name={questions[18].key}
                    >
                      <Select
                        name={questions[18].key}
                        emptySearchMessage='No values found'
                        // onChange={
                        //   (event, option) => { setAcademicYear(event.value) }
                        // }
                        labelKey='label'
                        options={constants.nrmStatus}
                        valueKey={{ key: 'value', reduce: true }}
                      />
                    </FormField>

                    {/* Country of Origin */}
                    <FormField
                      label={questions[19].questionText}
                      name={questions[19].key}
                    >
                      <Select
                        name={questions[19].key}
                        emptySearchMessage='No values found'
                        // onChange={
                        //   (event, option) => { setAcademicYear(event.value) }
                        // }
                        labelKey='label'
                        options={constants.countries}
                        valueKey={{ key: 'value', reduce: true }}
                      />
                    </FormField>
                  </Responsive>

                  <Divider color='lightgrey' margin={{ top: 'small', bottom: 'small' }} />


                  <Box>
                    <Text weight='bold'>Renovations</Text>
                  </Box>

                  <Responsive
                    columns={{
                      small: ['auto'],
                      medium: ['auto'],
                      large: ['auto'],
                      xlarge: ['auto']
                    }}
                    gap='small'
                  >
                    {(mappedRenovations.length > 0) &&
                      <Box direction='row-responsive' gap='medium'>
                        <DataTable
                          columns={renovationColumns}
                          data={mappedRenovations}
                          fill='horizontal'
                          primaryKey={false}
                          size='large'
                        />
                      </Box>}

                    <Box direction='row-responsive' gap='medium'>
                      {/* Date */}
                      <FormField
                        label={questions[20].questionText}
                        name={questions[20].key}
                      >
                        <DateInput
                          name={questions[20].key}
                        />
                      </FormField>

                      {/* Description */}
                      <FormField
                        label={questions[21].questionText}
                        name={questions[21].key}
                      >
                        <TextInput
                          name={questions[21].key}
                          type='text'
                        />
                      </FormField>

                      <Button disabled={!renovationsAddButton} label={<Text><FontAwesomeIcon icon={['fal', 'plus-circle']} /></Text>} color='primary' onClick={() => addRenovation()} style={{ height: '35px' }} alignSelf='end' margin={{ bottom: 'small' }} />
                    </Box>

                  </Responsive>


                  <Box>
                    <Text weight='bold'>Counselling</Text>
                  </Box>

                  <Responsive
                    columns={{
                      small: ['auto'],
                      medium: ['auto', 'auto'],
                      large: ['auto', 'auto'],
                      xlarge: ['auto', 'auto']
                    }}
                    gap='small'
                  >
                    {/* Assigned Assessor */}
                    <FormField
                      label={questions[22].questionText}
                      name={questions[22].key}
                    >
                      <TextInput
                        name={questions[22].key}
                        type='text'
                      />
                    </FormField>

                    {/* Assessment Outcome */}
                    <FormField
                      label={questions[23].questionText}
                      name={questions[23].key}
                    >
                      <TextInput
                        name={questions[23].key}
                        type='text'
                      />
                    </FormField>

                    {/* Referring Agency */}
                    <FormField
                      label={questions[24].questionText}
                      name={questions[24].key}
                    >
                      <TextInput
                        name={questions[24].key}
                        type='text'
                      />
                    </FormField>

                    {/* Suuiatble for Placement */}
                    <FormField
                      label={questions[25].questionText}
                      name={questions[25].key}
                    >
                      <OptionButtons
                        id={questions[25].key}
                        name={questions[25].key}
                      />
                    </FormField>

                  </Responsive>
                </Box>

                <Box
                  margin={{ horizontal: 'medium' }}
                  direction='column'
                >
                  <Text weight='bold'>Key Dates</Text>

                  <Box direction='row'>
                    <Box>
                      {/* Referred */}
                      <FormField
                        label={questions[26].questionText}
                        name={questions[26].key}
                      >
                        <DateInput
                          name={questions[26].key}
                        />
                      </FormField>
                    </Box>

                    <Box>
                      {/* Support Ended */}
                      <FormField
                        label={questions[27].questionText}
                        name={questions[27].key}
                      >
                        <DateInput
                          name={questions[27].key}
                        />
                      </FormField>
                    </Box>
                  </Box>

                  <Box direction='row'>
                    <Box>
                      {/* Assessment Completed */}
                      <FormField
                        label={questions[28].questionText}
                        name={questions[28].key}
                      >
                        <DateInput
                          name={questions[28].key}
                        />
                      </FormField>
                    </Box>

                    <Box>
                      {/* Counselling Started */}
                      <FormField
                        label={questions[29].questionText}
                        name={questions[29].key}
                      >
                        <DateInput
                          name={questions[29].key}
                        />
                      </FormField>
                    </Box>
                  </Box>

                  <Box direction='row'>
                    <Box>
                      {/* Counselling Completed */}
                      <FormField
                        label={questions[30].questionText}
                        name={questions[30].key}
                      >
                        <DateInput
                          name={questions[30].key}
                        />
                      </FormField>
                    </Box>

                    <Box>
                      {/* Attendance form completed */}
                      <FormField
                        label={questions[31].questionText}
                        name={questions[31].key}
                      >
                        <DateInput
                          name={questions[31].key}
                        />
                      </FormField>
                    </Box>
                  </Box>

                  <Box direction='row'>
                    <Box>
                      {/* NRM Exit Date */}
                      <FormField
                        label={questions[32].questionText}
                        name={questions[32].key}
                      >
                        <DateInput
                          name={questions[32].key}
                        />
                      </FormField>
                    </Box>

                    <Box>
                      {/* CG Date */}
                      <FormField
                        label={questions[33].questionText}
                        name={questions[33].key}
                      >
                        <DateInput
                          name={questions[33].key}
                        />
                      </FormField>
                    </Box>
                  </Box>

                  <Box height='medium'>
                    <Box>
                      {/* Personal Notes */}
                      <FormField
                        contentProps={{ height: 'medium' }}
                        label={questions[34].questionText}
                        name={questions[34].key}
                      >
                        <TextArea
                          fill
                          name={questions[34].key}
                        />
                      </FormField>
                    </Box>
                  </Box>
                </Box>
              </Responsive>

              <Divider color='lightgrey' margin={{ top: 'small', bottom: 'small' }} />


              <Box direction='row' justify='between' margin={{ top: 'medium' }}>
                <Button label='< Back' onClick={() => props.previousPage()} secondary />
                <Button type='submit' label='Save' primary />
              </Box>
            </Form>)
          : (
            <Box align='center'>
              <PulseLoader
                size={15}
                color='orange'
                loading={loading}
              />
            </Box>)}
      </Box>
    </Box>
  )
}

export default Personal
