import React, { useState, useEffect } from 'react'

import moment from 'moment'

// Spinner
import PulseLoader from 'react-spinners/PulseLoader'

// Appt Components
import Box from '../../../components/simple/box/Box'
import Button from '../../../components/simple/button/Button'
import DateInput from '../../../components/simple/input/DateInput'
import DataTable from '../../../components/simple/dataTable/DataTable'
import Divider from '../../../components/simple/divider/Divider'
import FontAwesome from '../../../components/compound/fontAwesome/FontAwesome'
import Form from '../../../components/simple/form/Form.js'
import FormField from '../../../components/simple/formField/FormField.js'
import H2 from '../../../components/simple/heading/H2.js'
import OptionButtons from '../../../components/compound/optionButtons/OptionButtons'
import Text from '../../../components/simple/text/Text'
import TextArea from '../../../components/simple/input/TextArea'
import TextInput from '../../../components/simple/input/TextInput'

function Safety (props) {
  const [loading, setLoading] = useState(false)

  const [questions, setQuestions] = useState(props.questions)
  const [localFormDetails, setLocalFormDetails] = useState(props.formDetails)

  // Determine if safety goal Add button is enabled or not
  const [safetyGoalAddButton, setSafetyGoalAddButton] = useState(true)
  // Array of safety goal
  const [safetyGoals, setSafetyGoals] = useState([])
  // Array of safety goals mapped for DataTable
  const [mappedSafetyGoals, setMappedSafetyGoals] = useState([])
  // DataTable columns for safety goals
  const [safetyGoalsColumns, setSafetyGoalsDataColumns] = useState()

  const previousPage = () => {
    props.previousPage()
  }

  const submitForm = () => {
    props.submitForm(localFormDetails, 'Draft')
  }

  // Safety Goals management functions
  const addSafetyGoal = () => {
    // Don't save if all fields empty
    if (localFormDetails.safetyGoal !== '' || localFormDetails.safetyGoalAction !== '' || localFormDetails.safetyGoalActionee !== '' || localFormDetails.safetyGoalDate !== '') {
      const event = {
        safetyGoal: localFormDetails.safetyGoal,
        safetyGoalAction: localFormDetails.safetyGoalAction,
        safetyGoalActionee: localFormDetails.safetyGoalActionee,
        safetyGoalDate: localFormDetails.safetyGoalDate
      }

      setSafetyGoals(safetyGoals => [...safetyGoals, event])

      // Clear input fields
      setLocalFormDetails(localFormDetails => ({
        ...localFormDetails,
        safetyGoal: '',
        safetyGoalAction: '',
        safetyGoalActionee: '',
        safetyGoalDate: ''
      }))

      setSafetyGoalAddButton(true)
    }
  }

  const removeSafetyGoal = (data) => {
    const newEvent = safetyGoals.filter((item) => item.safetyGoal !== data.safetyGoal)

    setSafetyGoals(newEvent)
  }

  const editSafetyGoal = (data) => {
    setLocalFormDetails(localFormDetails => ({
      ...localFormDetails,
      safetyGoal: data.safetyGoal,
      safetyGoalAction: data.safetyGoalAction,
      safetyGoalActionee: data.safetyGoalActionee,
      safetyGoalDate: data.safetyGoalDate
    }))

    removeSafetyGoal(data)
    setSafetyGoalAddButton(false)
  }

  // Safety Goals have been altered
  // so rebuild the display list
  useEffect(() => {
    setSafetyGoalsDataColumns([
      {
        property: 'safetyGoal',
        header: <Text pad='none' margin='none'>Goal</Text>
      },
      {
        property: 'safetyGoalAction',
        header: <Text>What action is needed?</Text>
      },
      {
        property: 'safetyGoalActionee',
        header: <Text pad='none' margin='none'>Who is going to do this?</Text>
      },
      {
        property: 'safetyGoalDate',
        header: <Text pad='none' margin='none'>By when?</Text>
      },
      {
        property: 'actions',
        render: datum => (
          <Box direction='row'>
            <Button label={<Text><FontAwesome icon={['fal', 'minus-circle']} /></Text>} margin={{ horizontal: 'xsmall' }} style={{ background: '#333' }} onClick={() => removeSafetyGoal(datum)} target='_self' />
            <Button label={<Text><FontAwesome icon={['fal', 'edit']} /></Text>} margin={{ horizontal: 'xsmall' }} style={{ background: '#333' }} onClick={() => editSafetyGoal(datum)} target='_self' />
          </Box>
        )
      }
    ])

    const mappedSafetyGoals = safetyGoals.map((event, index) => ({
      safetyGoal: event.safetyGoal,
      safetyGoalAction: event.safetyGoalAction,
      safetyGoalActionee: event.safetyGoalActionee,
      safetyGoalDate: moment(event.safetyGoalDate).format('YYYY-MM-DD')
    }))

    setMappedSafetyGoals(mappedSafetyGoals)

    // Stringify safety goals and store them
    const safetyGoalsToSave = JSON.stringify(safetyGoals)

    setLocalFormDetails(localFormDetails => ({
      ...localFormDetails,
      safetyGoals: safetyGoalsToSave
    }))
  }, [safetyGoals])

  return (
    <Box gridArea='main' background='white' direction='column' gap='small' round='small'>
      <Divider color='primary' />
      <Box
        gap='small'
        margin={{ horizontal: 'small' }}
        pad='small'
        round='small'
      >

        <Box fill direction='row' gap='small'>
          <H2 margin={{ vertical: 'none' }}>Safety and Security</H2>
        </Box>

        <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
        {localFormDetails && questions.length
          ? (
            <Form
              onChange={nextValue => {
                setLocalFormDetails(nextValue)
                setSafetyGoalAddButton(nextValue?.safetyGoal === '' || nextValue?.safetyGoalAction === '' || nextValue?.safetyGoalActionee === '' || nextValue?.safetyGoalDate === '')
              }}
              onSubmit={({ value: nextValue }) => {
                submitForm(nextValue)
              }}
              value={localFormDetails}
            >
              <Box direction='row-responsive' gap='small'>
                {/* safety */}
                <FormField
                  label={questions[0].questionText}
                  name={questions[0].key}
                  width='100%'
                >
                  <TextArea
                    name={questions[0].key}
                  />
                </FormField>
              </Box>

              <Box direction='row-responsive' gap='small'>
                {/* safetyRisk */}
                <FormField
                  label={questions[1].questionText}
                  name={questions[1].key}
                >
                  <OptionButtons
                    id={questions[1].key}
                    name={questions[1].key}
                  />
                </FormField>
              </Box>

              {localFormDetails.safetyRisk === 'Yes' &&
                <Box direction='row-responsive' gap='small'>
                  {/* safetyRiskReason */}
                  <FormField
                    label={questions[2].questionText}
                    name={questions[2].key}
                    width='100%'
                  >
                    <TextArea
                      name={questions[2].key}
                    />
                  </FormField>
                </Box>}

              <Divider color='primary' margin={{ top: 'medium', bottom: 'small' }} />

              <Text margin={{ top: 'xsmall' }} size='large'>Goals</Text>

              {mappedSafetyGoals.length > 0 &&
                <Box direction='row-responsive' gap='medium'>
                  <DataTable
                    columns={safetyGoalsColumns}
                    data={mappedSafetyGoals}
                    fill='horizontal'
                    size='large'
                  />
                </Box>}

              <Box direction='row-responsive' gap='medium'>
                {/* safetyGoal */}
                <FormField
                  label={questions[3].questionText}
                  name={questions[3].key}
                >
                  <TextInput
                    name={questions[3].key}
                    type='text'
                  />
                </FormField>

                {/* safetyGoalAction */}
                <FormField
                  label={questions[4].questionText}
                  name={questions[4].key}
                >
                  <TextInput
                    name={questions[4].key}
                    type='text'
                  />
                </FormField>

                {/* safetyGoalActionee */}
                <FormField
                  label={questions[5].questionText}
                  name={questions[5].key}
                >
                  <TextInput
                    name={questions[5].key}
                    type='text'
                  />
                </FormField>

                {/* safetyGoalDate */}
                <FormField
                  label={questions[6].questionText}
                  name={questions[6].key}
                >
                  <DateInput
                    name={questions[6].key}
                  />
                </FormField>

                <Button disabled={safetyGoalAddButton} label={<Text>Add</Text>} color='primary' onClick={() => addSafetyGoal()} style={{ height: '35px' }} alignSelf='end' margin={{ bottom: 'small' }} />
              </Box>

              <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />

              <Box direction='row' justify='between' margin={{ top: 'medium' }}>
                <Button label='< Back' onClick={() => previousPage()} secondary />
                <Button type='submit' label='Next Page' primary />
              </Box>
            </Form>)
          : (
            <Box align='center'>
              <PulseLoader
                size={15}
                color='orange'
                loading={loading}
              />
            </Box>)}
      </Box>
    </Box>
  )
}
export default Safety
