import React, { useState } from 'react'

import { useHistory } from 'react-router-dom'

import FormService from '../../../services/FormService'
import LocalStorageService from '../../../services/LocalStorageService'
import NotificationService from '../../../services/NotificationService'

// Appt Components
import Anchor from '../../../components/simple/anchor/Anchor'
import Box from '../../../components/simple/box/Box'
import Meter from '../../../components/simple/meter/Meter'
import Tabs from '../../../components/simple/tabs/Tabs'
import Text from '../../../components/simple/text/Text'

// Shared Components
import BreadcrumbBar from '../../shared/BreadcrumbBar'

// Child Forms
import CaseDetails from '../shared/CaseDetails'
import ScoringSheet from './ScoringSheet'
import SubmitPage from '../shared/SubmitPage'

function MonitoringForm (props) {
  const history = useHistory()

  const [meterValue, setMeterValue] = useState()

  const [formDetails, setFormDetails] = useState({
    // Case Details
    clientName: '',
    caseworker: '',
    supervisor: '',
    carePlanPoint: '',
    dateCompleted: '',
    // Independent Living
    independentLivingManageBudget: '',
    independentLivingHousingSituation: '',
    independentLivingLocalArea: '',
    independentLivingMakeAppointments: '',
    independentLivingAccessTreatment: '',
    independentLivingGetHelp: '',
    independentLivingPayBills: '',
    // Social Isolation
    socialIsolationUnderstood: '',
    socialIsolationFriends: '',
    socialIsolationAttendGroup: '',
    socialIsolationFormFriendships: '',
    socialIsolationCommunicate: '',
    socialIsolationRelationships: '',
    // Mental Wellbeing
    mentalWellbeingMemories: '',
    mentalWellbeingWorry: '',
    mentalWellbeingHardRelax: '',
    mentalWellbeingLowEmotions: '',
    mentalWellbeingAnger: '',
    mentalWellbeingLowConcentration: '',
    // Risk of Exploitation/Re-trafficking
    exploitationSafe: '',
    exploitationStableIncome: '',
    exploitationEmployed: '',
    exploitationNoDebt: '',
    exploitationSafeContact: '',
    exploitationGoodRelationships: '',
    exploitationLegalAccess: '',
    // Hope/Self Confidence
    hopeKnowledge: '',
    hopePositiveDecisions: '',
    hopeOffers: '',
    hopeFuture: '',
    hopePurpose: '',
    hopeHopeful: '',
    // Submit Page
    staffNotifications: '',
    additionalNotification: '',
    clientConsent: ''
  })

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrganisation = LocalStorageService.get('activeOrg')
  const activeUser = LocalStorageService.get('userDetails')

  const submitForm = async (formDetailsToSave, status) => {
    setFormDetails(formDetailsToSave)

    var submitArray = []
    var item = {
      key: '',
      questionText: '',
      answer: ''
    }

    // Loop through each form value
    for (var key in formDetailsToSave) {
      // And questions on each page of the form
      questions.forEach(function (pageOfQuestions, index) {
        // To match them up
        var found = pageOfQuestions.questions.find(question => question.key === key)

        if (found && checkInputHasContent(formDetailsToSave[key])) {
          let response = null

          response = formDetailsToSave[key]

          item = {
            questionKey: key,
            questionText: found.questionText,
            response: response,
            responseType: found?.responseType || 'string'
          }
          submitArray.push(item)
        }
      })
    }

    // Build output data
    const data = {
      user: activeUser.id,
      organisation: activeOrganisation.id,
      status: status,
      type: 'monitoringform',
      reference: '',
      responses: submitArray
    }

    // Only submit if there is data
    if (submitArray.length !== 0) {
      // Stringify the answers and add that also
      const stringifiedAnswers = JSON.stringify(submitArray)

      submitArray.push(
        {
          questionKey: 'monitoringFormAnswers',
          questionText: 'Monitoring Form Answers',
          response: stringifiedAnswers,
          responseType: 'string'
        }
      )

      var submissionAdded = await FormService.makeSubmissions(apiToken, data)
      if (submissionAdded.error) {
        NotificationService.error(submissionAdded.error)
      } else {
        if (status === 'Published') {
          NotificationService.success('Form Submitted')
          history.push('/')
        } else {
          nextPage()
        }
      }
    } else {
      // If we're on the last page stay there
      if (tabIndex !== tabContent.length - 1) {
        nextPage()
      }
    }
  }

  // Check if the passed in input field has any value
  const checkInputHasContent = (input) => {
    let inputHasContent = false

    switch (typeof (input)) {
      case 'string':
        inputHasContent = input.length > 0
        break
      case 'number':
        inputHasContent = input > 0
        break
      case 'boolean':
        break
      case 'object':
        inputHasContent = true
        // inputHasContent = input.length > 0
        break
    }

    return inputHasContent
  }

  // Tab controls
  const [tabIndex, setTabIndex] = useState(0)
  const onActiveTab = (nextIndex) => {
    setMeterValue((nextIndex + 1) * 100 / tabContent.length)
    setTabIndex(nextIndex)
  }

  // Go Back
  const goBack = () => {
    history.goBack()
  }

  const previousPage = () => {
    if (tabIndex === 0) {
      goBack()
    } else {
      const prevPageIndex = tabIndex - 1
      onActiveTab(prevPageIndex)
    }
  }

  const nextPage = () => {
    if (tabIndex === tabContent.length - 1) {
      // Don't think we ever get here
      submitForm()
    } else {
      const nextPageIndex = tabIndex + 1
      onActiveTab(nextPageIndex)
    }
  }

  const [questions, setQuestions] = useState([
    // Personal Details
    {
      tabId: 0,
      questions: [
        {
          questionText: 'Name of Client *',
          key: 'clientName'
        },
        {
          questionText: 'Snowdrop Caseworker',
          key: 'caseworker'
        },
        {
          questionText: 'Snowdrop Supervisor',
          key: 'supervisor'
        },
        {
          questionText: 'Point of Care Plan',
          key: 'carePlanPoint'
        },
        {
          questionText: 'Date completed',
          key: 'dateCompleted'
        }
      ]
    },
    // Independent Living
    {
      tabId: 1,
      questions: [
        {
          questionText: 'I have been able to manage my finances',
          key: 'independentLivingManageBudget'
        },
        {
          questionText: 'My housing situation has been stable',
          key: 'independentLivingHousingSituation'
        },
        {
          questionText: 'I found my way around the local area',
          key: 'independentLivingLocalArea'
        },
        {
          questionText: 'I arranged and attended my own appointments',
          key: 'independentLivingAppointments'
        },
        {
          questionText: 'I’ve been able to make up my own mind about things',
          key: 'independentLivingMakeUpMind'
        },
        {
          questionText: 'I feel able to manage my money',
          key: 'independentLivingManageMoney'
        },
        // {
        //   questionText: 'I know where to go if I need help',
        //   key: 'independentLivingGetHelp'
        // },
        // {
        //   questionText: 'I feel able to pay my bills on my own (if applicable)',
        //   key: 'independentLivingPayBills'
        // }
      ]
    },
    // Community
    {
      tabId: 2,
      questions: [
        {
          questionText: 'I have been able to make myself understood in English',
          key: 'communityUnderstood'
        },
        {
          questionText: 'I have been feeling close to other people',
          key: 'communityFriends'
        },
        {
          questionText: 'I have attended community groups',
          key: 'communityAttendGroup'
        },
        // {
        //   questionText: 'I am confident about forming friendships',
        //   key: 'communityFormFriendships'
        // },
        {
          questionText: 'I have communicated easily in different forms (writing/phone/text/email)',
          key: 'communityCommunicate'
        },
        {
          questionText: 'I feel free to make choices about my relationships with others',
          key: 'communityRelationships'
        }
      ]
    },
    // Mental Wellbeing
    {
      tabId: 3,
      questions: [
        {
          questionText: 'I have felt I could cope with unpleasant memories',
          key: 'mentalWellbeingMemories'
        },
        {
          questionText: 'I have been feeling good about myself',
          key: 'mentalWellbeingFeelingGood'
        },
        {
          questionText: 'I have been feeling relaxed ',
          key: 'mentalWellbeingRelaxed'
        },
        {
          questionText: 'I have felt calm',
          key: 'mentalWellbeingCalm'
        },
        {
          questionText: 'I have been thinking clearly',
          key: 'mentalWellbeingClearThinking'
        },
        {
          questionText: 'I’ve been dealing with problems well',
          key: 'mentalWellbeingLowConcentration'
        }
      ]
    },
    // Trafficking Precarities
    {
      tabId: 4,
      questions: [
        {
          questionText: 'I no longer fear being trafficked',
          key: 'exploitationSafe'
        },
        {
          questionText: 'I have had enough money to live on',
          key: 'exploitationStableIncome'
        },
        // {
        //   questionText: 'I have a job or feel I have a good chance of getting employment',
        //   key: 'exploitationEmployed'
        // },
        {
          questionText: 'I have not worried about debt',
          key: 'exploitationNoDebt'
        },
        {
          questionText: 'I have safe and positive contact with my family/close friends',
          key: 'exploitationSafeContact'
        },
        {
          questionText: 'I know where I can go to get support if I need it',
          key: 'exploitationGoodRelationships'
        },
        // {
        //   questionText: 'I have access to good legal advice',
        //   key: 'exploitationLegalAccess'
        // }
      ]
    },
    // Hope/Self Confidence
    {
      tabId: 5,
      questions: [
        {
          questionText: 'I have felt useful',
          key: 'hopeUseful'
        },
        // {
        //   questionText: 'I am able to make positive decisions on my own',
        //   key: 'hopePositiveDecisions'
        // },
        {
          questionText: 'I feel I have something to offer other people',
          key: 'hopeOffers'
        },
        {
          questionText: 'I have felt like I know what I want',
          key: 'hopeNeeds'
        },
        {
          questionText: 'I have felt that my life has purpose',
          key: 'hopePurpose'
        },
        {
          questionText: 'I have been feeling optimistic about the future',
          key: 'hopeHopeful'
        }
      ]
    },
    // Submit Form
    {
      tabId: 6,
      questions: [
        {
          questionText: 'If you would like to notify an additional staff member, please select them below.',
          key: 'additionalNotification'
        },
        {
          questionText: 'I confirm that I have the consent of the client to submit this application',
          key: 'clientConsent'
        },
        // Hidden field to hold the automatic notifications
        {
          questionText: 'Staff Notifications',
          key: 'staffNotifications'
        }
      ]
    }
  ])

  const tabContent = [
    {
      // name: 'Case Details',
      name: 'Page 1',
      hideTitle: true,
      content: <CaseDetails previousPage={previousPage} submitForm={submitForm} formDetails={formDetails} setFormDetails={setFormDetails} questions={questions[0].questions} />
    },
    {
      // name: 'Independent Living',
      name: 'Page 2',
      hideTitle: true,
      content: <ScoringSheet title='Independent Living' previousPage={previousPage} submitForm={submitForm} formDetails={formDetails} setFormDetails={setFormDetails} questions={questions[1].questions} />
    },
    {
      // name: 'Community',
      name: 'Page 3',
      hideTitle: true,
      content: <ScoringSheet title='Community' previousPage={previousPage} submitForm={submitForm} formDetails={formDetails} setFormDetails={setFormDetails} questions={questions[2].questions} />
    },
    {
      // name: 'Mental Wellbeing',
      name: 'Page 4',
      hideTitle: true,
      content: <ScoringSheet title='Mental Wellbeing' previousPage={previousPage} submitForm={submitForm} formDetails={formDetails} setFormDetails={setFormDetails} questions={questions[3].questions} />
    },
    {
      // name: 'Risk of Exploitation/Re-trafficking',
      name: 'Page 5',
      hideTitle: true,
      content: <ScoringSheet title='Trafficking Precarities' previousPage={previousPage} submitForm={submitForm} formDetails={formDetails} setFormDetails={setFormDetails} questions={questions[4].questions} />
    },
    {
      // name: 'Hope/Self Confidence',
      name: 'Page 6',
      hideTitle: true,
      content: <ScoringSheet title='Hope/Self Confidence' previousPage={previousPage} submitForm={submitForm} formDetails={formDetails} setFormDetails={setFormDetails} questions={questions[5].questions} />
    },
    {
      // name: 'Submit Form',
      name: 'Page 7',
      hideTitle: true,
      content: <SubmitPage previousPage={previousPage} submitForm={submitForm} formDetails={formDetails} setFormDetails={setFormDetails} questions={questions[6].questions} />
    }
  ]

  return (
    <Box width='xlarge'>
      <BreadcrumbBar
        path={<><Anchor href='/'>Snowdrop CRM</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></>}
      >
        MonitoringForm
      </BreadcrumbBar>

      <Box flex='grow' gridArea='main' background='white' direction='column' gap='small' round='small'>
        <Box
          gap='small'
          margin={{ horizontal: 'small' }}
          pad='small'
          round='small'
        >

          <Meter background='light-2' size='full' thickness='xsmall' type='bar' values={[{ color: 'brand', value: meterValue }]} />

          <Tabs alignControls='start' activeIndex={tabIndex} content={tabContent} onActive={onActiveTab} />
        </Box>
      </Box>
    </Box>
  )
}

export default MonitoringForm
