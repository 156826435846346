import React, { useState, useEffect } from 'react'

import { useHistory } from 'react-router-dom'
import { useStore } from 'react-context-hook'

// Font Awesome
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import LocalStorageService from '../services/LocalStorageService'

// Appt Components
import Anchor from '../components/simple/anchor/Anchor'
import Box from '../components/simple/box/Box'
import Button from '../components/simple/button/Button'
import H2 from '../components/simple/heading/H2'
import Text from '../components/simple/text/Text'

function Privacy () {
  const history = useHistory()

  const [apiToken, setApiToken, deleteApiToken] = useStore(null)
  const [currentUser, setCurrentUser, deleteCurrentUser] = useStore({})
  const [currentOrgs, setCurrentOrgs, deleteCurrentOrgs] = useStore([])

  // NOTE: getUser and clearSession currently need to be called on every screen
  // this is definitely a violation of DRY and needs to be refactor

  // check if the user is logged in, if so restored the session
  const getUser = () => {
    // check local storage
    var apiToken = LocalStorageService.get('apiToken')
    if (apiToken) {
      setApiToken(apiToken)
    } else {
      clearSession()
    }
  }

  // clear the user's session if they log out
  const clearSession = () => {
    // remove all localstorage
    LocalStorageService.clear()

    // remove the session data
    deleteApiToken()
    deleteCurrentUser()
    deleteCurrentOrgs()
    history.push('/')
  }

  const goBack = () => {
    history.goBack()
  }

  // Basically componentDidMount
  useEffect(() => {
    getUser()
  }, [])

  return (
    <Box background='white' elevation='small' direction='column' gap='small' round='small'>

      <Box direction='row-responsive' pad={{ horizontal: 'medium' }}>
        <H2>Privacy Information</H2>
      </Box>

      <Box background={{ color: 'light-5' }} direction='row-responsive' margin={{ horizontal: 'medium' }} pad={{ horizontal: 'medium', vertical: 'small' }} round='xsmall'>
        <Text><Anchor href='/'>Snowdrop CRM</Anchor>{history.location.pathname}</Text>
      </Box>

      <Box direction='row-responsive' justify='between' pad={{ horizontal: 'medium' }}>
        <Text>This is the Privacy screen</Text>
        <Box direction='row' justify='between' margin={{ top: 'medium' }}>
          <Button label='< Back' onClick={() => goBack()} primary />
        </Box>
      </Box>
    </Box>
  )
}

export default Privacy
