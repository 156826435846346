import React, { useState, useEffect } from 'react'

import { useHistory, useParams } from 'react-router-dom'

import { capitalize } from '../../utils/text'
import { hasPermission, hasAnyPermissionIn } from '../../utils/permissions'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

import LocalStorageService from '../../services/LocalStorageService'
import MediaService from '../../services/MediaService'
import NotificationService from '../../services/NotificationService'
import UploadService from '../../services/UploadService'
import UserService from '../../services/UserService'
import UserTagService from '../../services/UserTagService'

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor'
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button.js'
import Tabs from '../../components/simple/tabs/Tabs.js'
import Text from '../../components/simple/text/Text'

// ServiceUserEdit screens
import Access from './ServiceUserEdit-Access'
import Basic from './ServiceUserEdit-Basic'
import Personal from './ServiceUserEdit-Personal'
import Sensitive from './ServiceUserEdit-Sensitive'
// import Documents from './ServiceUserEdit-Documents'
// import Notes from './ServiceUserEdit-Notes'

import Documents from '../shared/User-Documents'
import Notes from '../shared/User-Notes'

function ServiceUserEdit (props) {
  const history = useHistory()

  const [userDetails, setUserDetails] = useState({
    // Basic
    firstName: '',
    lastName: '',
    // email: '',
    reference: '',
    dob: '',
    ethnicity: '',
    gender: '',
    knownAs: '',
    preferredPronoun: '',
    startedSupportDate: '',
    primaryLanguage: '',
    interpreterNeeded: '',
    childcareNeeded: '',
    emergencyContactName: '',
    emergencyContactPhone: '',
    otherLanguage: '',
    otherInterpreterNeeded: '',
    otherChildcareNeeded: '',
    supportOrganisation: '',
    caseworkerAssigned: '',
    volunteersAssigned: '',
    meetingFrequency: '',
    weeklyActivitiesRegistered: '',
    counsellingReferralDate: '',
    lastUpdated: '',
    stickyNote: '',
    // Personal
    address: '',
    postcode: '',
    city: '',
    mobile: '',
    email: '',
    preferredContactMethod: '',
    clientDob: '',
    healthDiagnosis: '',
    partnerName: '',
    partnerDob: '',
    partnerLivingWithClient: '',
    firstNameChild: '',
    lastNameChild: '',
    dobChild: '',
    livingWithClientChild: '',
    children: '',
    hoReferenceNumber: '',
    immigrationStatus: '',
    nino: '',
    nrmStatus: '',
    countryOfOrigin: '',
    renovationDate: '',
    renovationDescription: '',
    renovations: '',
    assignedAssessor: '',
    assessmentOutcome: '',
    referringAgency: '',
    suitableForPlacement: '',
    referredDate: '',
    supportEndedDate: '',
    assessmentCompletedDate: '',
    counsellingStartedDate: '',
    counsellingCompletedDate: '',
    attendanceFormCompletedDate: '',
    NRMExitDate: '',
    CGDate: '',
    personalNotes: ''
  })

  const [meterValue, setMeterValue] = useState()

  // Determine if initial user details/tags have been loaded
  // otherwise fields don't get populated
  const [detailsLoaded, setDetailsLoaded] = useState(true)

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')

  const [children, setChildren] = useState([])
  const [renovations, setRenovations] = useState([])

  // This array indicates the sensitivity setting that needs
  // to be set for the User Tag settings
  // notag indicates keys that should not be saved as Tags as
  // they are saved as standard User settings (or are Children or Renovations fields)

  var tabs = []
  if (hasAnyPermissionIn(['clientViewAllBasic', 'clientViewMyBasic'])) {
    tabs.push({
      tabId: 0,
      sensitivity: 'basic',
      notag: [
        'firstName',
        'lastName',
        'reference',
        'dob',
        'gender'
      ]
    })
  }

  if (hasAnyPermissionIn(['clientViewAllPersonal', 'clientViewMyPersonal'])) {
    tabs.push({
      tabId: 1,
      sensitivity: 'personal',
      notag: [
        'email',
        'firstNameChild',
        'lastNameChild',
        'dobChild',
        'livingWithClientChild',
        'renovationDate',
        'renovationDescription'
      ]
    })
  }

  if (hasAnyPermissionIn(['clientViewAllSensitive', 'clientViewMySensitive'])) {
    tabs.push({
      tabId: 2,
      sensitivity: 'sensitive'
    })
  }

  const [tabSensitivity] = useState(tabs)

  // A list of questions for each tab
  const [questions, setQuestions] = useState([
    {
      tabId: 0,
      questions: [
        {
          questionText: 'Reference',
          key: 'reference'
        },
        {
          questionText: 'First Name',
          key: 'firstName'
        },
        {
          questionText: 'Last Name',
          key: 'lastName'
        },
        {
          questionText: 'Known As',
          key: 'knownAs'
        },
        {
          questionText: 'Date of Birth',
          key: 'dob'
        },
        {
          questionText: 'Gender',
          key: 'gender'
        },
        {
          questionText: 'Preferred Pronoun',
          key: 'preferredPronoun'
        },
        {
          questionText: 'Support Started',
          key: 'startedSupportDate'
        },
        {
          questionText: 'Primary Language',
          key: 'primaryLanguage'
        },
        {
          questionText: 'Interpreter Needed',
          key: 'interpreterNeeded'
        },
        {
          questionText: 'Childcare Needed',
          key: 'childcareNeeded'
        },
        {
          questionText: 'Other Language',
          key: 'otherLanguage'
        },
        {
          questionText: 'Name of emergency contact/next of kin',
          key: 'emergencyContactName'
        },
        {
          questionText: 'Phone/contact of emergency contact/next of kin',
          key: 'emergencyContactPhone'
        },
        {
          questionText: 'Interpreter Needed',
          key: 'otherInterpreterNeeded'
        },
        {
          questionText: 'Childcare Needed',
          key: 'otherChildcareNeeded'
        },
        {
          questionText: 'Support Organisation',
          key: 'supportOrganisation'
        },
        {
          questionText: 'Assign Caseworker',
          key: 'caseworkerAssigned'
        },
        {
          questionText: 'Assign Volunteers',
          key: 'volunteersAssigned'
        },
        {
          questionText: 'Freq. Volunteer Meetings',
          key: 'meetingFrequency'
        },
        {
          questionText: 'Registered Weekly Activities',
          key: 'weeklyActivitiesRegistered'
        },
        {
          questionText: 'Date Counselling Referral',
          key: 'counsellingReferralDate'
        },
        {
          questionText: 'Last Updated',
          key: 'lastUpdated'
        },
        {
          questionText: 'Sticky Note',
          key: 'stickyNote'
        }
      ]
    },
    {
      tabId: 1,
      questions: [
        {
          questionText: 'Address',
          key: 'address'
        },
        {
          questionText: 'Postcode',
          key: 'postcode'
        },
        {
          questionText: 'City',
          key: 'city'
        },
        {
          questionText: 'Mobile',
          key: 'mobile'
        },
        {
          questionText: 'Email',
          key: 'email'
        },
        {
          questionText: 'Preferred Contact Method',
          key: 'preferredContactMethod'
        },
        {
          questionText: 'Client Date of Birth',
          key: 'clientDob'
        },
        {
          questionText: 'Disability or Mental/Physical Health Diagnosis',
          key: 'healthDiagnosis'
        },
        {
          questionText: 'Partner Name',
          key: 'partnerName'
        },
        {
          questionText: 'Partner Date of Birth',
          key: 'partnerDob'
        },
        {
          questionText: 'Partner Living with Client',
          key: 'partnerLivingWithClient'
        },
        {
          questionText: 'First Name',
          key: 'firstNameChild'
        },
        {
          questionText: 'Last Name',
          key: 'lastNameChild'
        },
        {
          questionText: 'Date of Birth',
          key: 'dobChild'
        },
        {
          questionText: 'Living with Client',
          key: 'livingWithClientChild'
        },
        {
          questionText: 'HO Reference Number',
          key: 'hoReferenceNumber'
        },
        {
          questionText: 'Immigration Status',
          key: 'immigrationStatus'
        },
        {
          questionText: 'NINO',
          key: 'nino'
        },
        {
          questionText: 'NRM Status',
          key: 'nrmStatus'
        },
        {
          questionText: 'Country of Origin',
          key: 'countryOfOrigin'
        },
        {
          questionText: 'Date',
          key: 'renovationDate'
        },
        {
          questionText: 'Description',
          key: 'renovationDescription'
        },
        {
          questionText: 'Assigned Assessor',
          key: 'assignedAssessor'
        },
        {
          questionText: 'Assessment Outcome (Which pathway)',
          key: 'assessmentOutcome'
        },
        {
          questionText: 'Referring Agency (include Reach-in)',
          key: 'referringAgency'
        },
        {
          questionText: 'Suitable for Placement Student',
          key: 'suitableForPlacement'
        },
        {
          questionText: 'Referred',
          key: 'referredDate'
        },
        {
          questionText: 'Support Ended',
          key: 'supportEndedDate'
        },
        {
          questionText: 'Assessment Completed',
          key: 'assessmentCompletedDate'
        },
        {
          questionText: 'Counselling Started',
          key: 'counsellingStartedDate'
        },
        {
          questionText: 'Counselling Completed',
          key: 'counsellingCompletedDate'
        },
        {
          questionText: 'Attendance Form Completed',
          key: 'attendanceFormCompletedDate'
        },
        {
          questionText: 'NRM Exit Date',
          key: 'NRMExitDate'
        },
        {
          questionText: 'CG Date',
          key: 'CGDate'
        },
        {
          questionText: 'Personal Notes',
          key: 'personalNotes'
        },
        // Hidden question to store the array of children
        {
          questionText: 'Children',
          key: 'children'
        },
        // Hidden question to store the array of renovations
        {
          questionText: 'Renovations',
          key: 'renovations'
        }
      ]
    },
    {
      tabId: 2,
      questions: [
        {
          questionText: 'Type of Trafficking',
          key: 'traffickingType'
        },
        {
          questionText: 'Literacy Level',
          key: 'literacyLevel'
        },
        {
          questionText: 'Education',
          key: 'education1'
        },
        {
          questionText: 'Employment',
          key: 'employment1'
        },
        {
          questionText: 'Housing Type/Situation',
          key: 'housingType1'
        },
        {
          questionText: 'Income',
          key: 'income2'
        },
        {
          questionText: 'Employment',
          key: 'employment2'
        },
        {
          questionText: 'Housing Type/Situation',
          key: 'housingType2'
        },
        {
          questionText: 'Income',
          key: 'income'
        },
        {
          questionText: 'Legal Interventions',
          key: 'legalInterventions'
        },
        {
          questionText: 'Previous Pathways',
          key: 'previousPathways'
        },
        {
          questionText: 'Counselling Reports Completed (Pathway 4)',
          key: 'counsellingReportsCompleted'
        },
        {
          questionText: 'Last Completed',
          key: 'carePlanLastCompleted'
        },
        {
          questionText: 'Next Due',
          key: 'carePlanNextDue'
        },
        {
          questionText: 'Last Completed',
          key: 'monitoringFormLastCompleted'
        },
        {
          questionText: 'Next Due',
          key: 'monitoringFormNextDue'
        },
        {
          questionText: 'Sensitive Notes (Only visible on this tab)',
          key: 'sensitiveNotes'
        }
      ]
    },
    // Documents - dummy questions
    {
      tabId: 3,
      questions: [
        {
          questionText: 'XXX',
          key: 'xxx'
        }
      ]
    },
    // Notes - dummy questions
    {
      tabId: 4,
      questions: [
        {
          questionText: 'XXX',
          key: 'xxx'
        }
      ]
      // questions: [
      //   {
      //     questionText: 'Type',
      //     key: 'noteType'
      //   },
      //   {
      //     questionText: 'Description',
      //     key: 'noteDescription'
      //   },
      //   {
      //     questionText: 'Assign To',
      //     key: 'noteAssignTo'
      //   }
      // ]
    },
    // Access - dummy questions
    {
      tabId: 5,
      questions: [
        {
          questionText: 'XXX',
          key: 'xxx'
        }
      ]
    }
  ])

  // Submit User Details
  const submitUserDetails = async (formDetailsToSave) => {
    // Update user details with 'local' data
    setUserDetails(formDetailsToSave)

    let parsedChildren = formDetailsToSave.children
    let parsedRenovations = formDetailsToSave.renovations

    // Re-parse children and renovations if stringified
    if (!Array.isArray(formDetailsToSave.children) && (formDetailsToSave.children !== undefined && formDetailsToSave.children !== '')) {
      parsedChildren = JSON.parse(formDetailsToSave.children)
    }

    if (!Array.isArray(formDetailsToSave.renovations) && formDetailsToSave.renovations !== undefined && formDetailsToSave.renovations !== '') {
      parsedRenovations = JSON.parse(formDetailsToSave.renovations)
    }

    setUserDetails(prevState => ({
      ...prevState,
      children: parsedChildren,
      renovations: parsedRenovations
    }))

    switch (tabIndex) {
      // Documents
      case 3:
        // if (formDetailsToSave.length === 0) {
        //   // nextPage()
        // } else {
        //   formDetailsToSave.forEach(async (document) => {
        //     const params = {
        //       orgId: activeOrg.id
        //     }

        //     let data = {
        //       fileName: document.path,
        //       contentType: document.type,
        //       file: document.preview
        //     }

        //     let response = await UploadService.file(apiToken, params, data)
        //     if (response?.error) {
        //       NotificationService.error(response.error)
        //     } else {
        //       data = {
        //         organisation: activeOrg.id,
        //         title: document.name,
        //         type: 'document',
        //         documentUrl: response.data.baseUrl
        //       }

        //       response = await MediaService.create(apiToken, params, data)

        //       if (response?.error) {
        //         NotificationService.error(response.error)
        //       } else {
        //         NotificationService.success('Data successfully saved')
        //         // nextPage()
        //       }
        //     }
        //   })
        // }
        break
      // // Notes
      // case 4:
      //   const data = {
      //     organisation: activeOrg.id,
      //     title: formDetailsToSave.name,
      //     type: 'note',
      //     documentUrl: response.data.baseUrl
      //   }

      //   response = await MediaService.create(apiToken, params, data)

      //   if (response?.error) {
      //     NotificationService.error(response.error)
      //   }
      //   break
      default:
        var data = {
          reference: formDetailsToSave?.reference,
          firstName: formDetailsToSave?.firstName,
          lastName: formDetailsToSave?.lastName,
          email: formDetailsToSave?.email,
          gender: formDetailsToSave?.gender,
          ethnicity: formDetailsToSave?.ethnicity,
          type: 'client'
        }

        if (formDetailsToSave.dob) {
          data.dob = moment(formDetailsToSave.dob).format('YYYY-MM-DD')
        }

        if (userId === 'new') {
          const dataToSubmit = {
            data: data,
            organisation: activeOrg.id
          }

          // Add User
          const params = {
            orgId: activeOrg.id
          }

          var userAdded = await UserService.createUser(apiToken, dataToSubmit, params)
          // const userAdded = {
          // error: '*** TEMP: NOT WRITING TO DATABASE ***'
          // }
          if (userAdded.error) {
            NotificationService.error(userAdded.error)
          } else {
            // And then User Tags
            var submitArray = []
            var item = {
              key: '',
              questionText: '',
              answer: ''
            }

            // Loop through each form value
            for (var key in formDetailsToSave) {
              // And questions on each page of the form
              questions.forEach(function (pageOfQuestions, index) {
                // To match them up
                var found = pageOfQuestions.questions.find(question => question.key === key)

                if (found && checkInputHasContent(formDetailsToSave[key])) {
                  // console.log(found.questionText)
                  item = {
                    questionKey: key,
                    questionText: found.questionText,
                    response: formDetailsToSave[key]
                  }
                  submitArray.push(item)
                }
              })
            }

            NotificationService.success('Data successfully saved')
            history.push('/clients')
          }
        } else {
          // Save the standard User information
          data.id = userId

          const userData = {
            data: data,
            organisation: activeOrg.id
          }
          var userUpdated = await UserService.updateUser(apiToken, userId, userData)
          if (userUpdated.error) {
            NotificationService.error(userUpdated.error)
          } else {
            // Now save the User Tags
            const userTags = buildUserTags(formDetailsToSave)
            // // And check for any children
            // // and build the save string
            // if (children.length > 0) {
            //   const childrenToSave = []

            //   children.forEach(child => {
            //     childrenToSave.push(child)
            //   })

            //   userTags.push({
            //     key: 'children',
            //     user: userId,
            //     organisation: activeOrg.id,
            //     type: 'general',
            //     value: JSON.stringify(childrenToSave)
            //   })
            // }

            // // And check for any renovations
            // // and build the save string
            // if (renovations.length > 0) {
            //   const renovationsToSave = []

            //   renovations.forEach(renovation => {
            //     renovationsToSave.push(renovation)
            //   })

            //   userTags.push({
            //     key: 'renovations',
            //     user: userId,
            //     organisation: activeOrg.id,
            //     type: 'general',
            //     value: JSON.stringify(renovationsToSave)
            //   })
            // }

            const response = UserTagService.set(apiToken, userTags, 'client')
            if (response?.error) {
              NotificationService.error(response.error)
            } else {
              NotificationService.success('Data successfully saved')
              // nextPage()
            }
          }
        }
    }
  }

  const params = useParams()

  const userId = params.userid

  // Tab controls
  const [tabIndex, setTabIndex] = useState(0)
  const onActiveTab = (nextIndex) => {
    setMeterValue((nextIndex + 1) * 100 / questions.length)
    setTabIndex(nextIndex)
  }

  const previousPage = () => {
    // go back to the client screen
    history.push('/clients')
  }

  const tabContent = []

  tabContent.push({
    name: 'Basic',
    hideTitle: false,
    content: <Basic previousPage={previousPage} submitForm={submitUserDetails} userDetails={userDetails} setUserDetails={setUserDetails} questions={questions[0].questions} />
  })

  // only show the extra tabs when editing a user
  if (userId !== 'new') {
    if (hasAnyPermissionIn(['clientViewAllPersonal','clientViewMyPersonal'])) {
      tabContent.push({
        name: 'Personal',
        hideTitle: false,
        content: <Personal previousPage={previousPage} submitForm={submitUserDetails} userDetails={userDetails} setUserDetails={setUserDetails} questions={questions[1].questions} children={children} setChildren={setChildren} renovations={renovations} setRenovations={setRenovations} />
      })
    }

    if (hasAnyPermissionIn(['clientViewAllSensitive', 'clientViewMySensitive'])) {
      tabContent.push({
        name: 'Sensitive',
        hideTitle: false,
        content: <Sensitive previousPage={previousPage} submitForm={submitUserDetails} userDetails={userDetails} setUserDetails={setUserDetails} questions={questions[2].questions} />
      })
    }

    if (hasPermission('clientDocsView')) {
      tabContent.push({
        name: 'Documents',
        hideTitle: false,
        content: <Documents previousPage={previousPage} submitForm={submitUserDetails} userDetails={userDetails} setUserDetails={setUserDetails} questions={questions[3].questions} />
      })
    }

    if (hasPermission('clientNotesView')) {
      tabContent.push({
        name: 'Notes',
        hideTitle: false,
        content: <Notes previousPage={previousPage} userDetails={userDetails} setUserDetails={setUserDetails} questions={questions[4].questions} />
      })
    }

    if (hasPermission('clientGrantDirectAccess')) {
      tabContent.push({
        name: 'Access',
        hideTitle: false,
        content: <Access previousPage={previousPage} userDetails={userDetails} setUserDetails={setUserDetails} questions={questions[5].questions} />
      })
    }
  }

  // Build array ready for UserTag submission
  const buildUserTags = (formDetailsToSave) => {
    var submitArray = []
    var item = {
      key: '',
      questionText: '',
      answer: ''
    }

    // Loop through question on current tab
    // Skip any that are marked as notag
    // Building array for User Tag saving
    for (var value of questions[tabIndex].questions) {
      // if (checkInputHasContent(formDetailsToSave[value.key]) && !tabSensitivity?.[tabIndex]?.notag?.includes(value.key)) {
      if (!tabSensitivity?.[tabIndex]?.notag?.includes(value.key)) {
        var sensitivity = tabSensitivity[tabIndex].sensitivity ? tabSensitivity[tabIndex].sensitivity : 'default'

        item = {
          user: userId,
          organisation: activeOrg.id,
          key: value.key,
          value: formDetailsToSave[value.key],
          type: 'general',
          sensitivity: sensitivity
        }
        submitArray.push(item)
      }
    }

    return submitArray
  }

  // Check if the passed in input field has any value
  const checkInputHasContent = (input) => {
    let inputHasContent = false

    if (!input) {
      return inputHasContent
    }

    switch (typeof (input)) {
      case 'string':
        inputHasContent = input.length > 0
        break
      case 'number':
        inputHasContent = input > 0
        break
      case 'boolean':
        break
      case 'object':
        inputHasContent = input.length > 0
        break
    }

    return inputHasContent
  }

  // const goBack = () => {
  //   history.goBack()
  // }

  // const nextPage = () => {
  //   if (tabIndex === tabContent.length - 1) {
  //     submitUserDetails()
  //   } else {
  //     const nextPageIndex = tabIndex + 1
  //     onActiveTab(nextPageIndex)
  //   }
  // }

  // const savePage = () => {
  //   // Save on each next page
  //   submitUserDetails()
  // }

  // useEffect(() => {
  //   console.log(userDetails)
  // }, [userDetails])
  // Basically componentDidMount
  useEffect(() => {
    let unmounted = false

    // Check if adding a new User or editing an existing one
    const getUserDetails = async (userId) => {
      const userDetails = await UserService.getUser(apiToken, userId, {
        type: 'client',
        fields: 'reference,firstName,lastName,email,dob,ethnicity,gender'
      })
      if (!unmounted) {
        setUserDetails(prevState => ({
          ...prevState,
          reference: userDetails?.data?.[0]?.reference,
          firstName: userDetails?.data?.[0]?.firstName,
          lastName: userDetails?.data?.[0]?.lastName,
          email: userDetails?.data?.[0]?.email,
          dob: userDetails?.data?.[0]?.dob ? moment(userDetails?.data?.[0]?.dob).format('YYYY-MM-DD') : null,
          ethnicity: userDetails?.data?.[0]?.ethnicity || '',
          gender: userDetails?.data?.[0]?.gender
        // console.log(userDetails.data)
        }))
      }
    }

    const getUserTags = async (userId) => {
      const where = {
        user: userId,
        type: 'general',
        organisation: activeOrg.id
      }

      const params = {
        fields: 'key,value',
        type: 'client'
      }

      // Get User Tags and format for saving as form values
      const userTags = await UserTagService.get(apiToken, params, where)
      const tagsToSave = {}
      if (userTags?.length) {
        for (const tag of userTags) {
          let tagOK = true

          // Don't save any fields that should not be tags
          // This is probably not needed once the system
          // is up and running - just items such as firstName
          // may be saved as a tag
          tabSensitivity.forEach((values) => {
            if (values.notag) {
              if (values.notag.includes(tag.key) && tagOK) {
                tagOK = false
              }
            }
          })

          if (tagOK) {
            // If key is children save to children state
            if (tag.key === 'children') {
              const childrenToSave = []
              const parsedChildren = JSON.parse(tag.value)
              let childKey = 0

              parsedChildren.forEach((child) => {
                childrenToSave.push({
                  childKey: childKey++,
                  firstNameChild: child.firstNameChild,
                  lastNameChild: child.lastNameChild,
                  dobChild: child.dobChild,
                  livingWithClientChild: child.livingWithClientChild
                })
              })

              setUserDetails(prevState => ({
                ...prevState,
                children: childrenToSave
              }))
            } else if (tag.key === 'renovations') {
              // If key is renovations save to renovations state
              const renovationsToSave = []
              const parsedRenovations = JSON.parse(tag.value)
              let renovationKey = 0

              parsedRenovations.forEach((renovation) => {
                renovationsToSave.push({
                  renovationKey: renovationKey++,
                  renovationDate: renovation.renovationDate,
                  renovationDescription: renovation.renovationDescription
                })
              })

              setUserDetails(prevState => ({
                ...prevState,
                renovations: renovationsToSave
              }))
            } else {
              tagsToSave[tag.key] = tag.value
            }
          }
        }
        setDetailsLoaded(true)
      }

      // Convert to integer
      tagsToSave.caseworkerAssigned = parseInt(tagsToSave.caseworkerAssigned)
      tagsToSave.volunteersAssigned = parseInt(tagsToSave.volunteersAssigned)

      // Add saved tags to form data
      setUserDetails(prevState => ({
        ...prevState,
        ...tagsToSave
      }))
    }

    if (userId !== 'new') {
      getUserDetails(userId)
      getUserTags(userId)
    } else {
      setDetailsLoaded(true)
    }

    // Set initial Meter setting
    setMeterValue(100 / questions.length)

    return () => { unmounted = true }
  }, [])

  return (
    <Box width='xlarge'>
      <Box background='white' elevation='small' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>
        <Box direction='column' pad={{ horizontal: 'medium' }}>
          <Text margin={{ top: 'small' }} size='xlarge'>{capitalize(props?.terms?.serviceUser || 'Client')}: {userDetails.firstName ? userDetails.firstName + ' ' + userDetails.lastName : 'New User'}</Text>
          <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'><Anchor href='/'>Snowdrop CRM</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></Text>
        </Box>
      </Box>

      {userId !== 'new' && hasAnyPermissionIn(['formAccessRiskMatrix', 'formAccessAll']) &&
        <Box background='white' elevation='small' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>
          <Box direction='row-responsive' justify='between' pad={{ horizontal: 'medium', vertical: 'small' }}>
            <Box>
              <Text size='xlarge' weight='400' margin={{ top: 'none' }}>Quick Actions</Text>
            </Box>
            <Box alignSelf='center' direction='row'>
              {hasAnyPermissionIn(['formAccessRiskMatrix', 'formAccessAll']) &&
                <Button href={`/riskmatrix/${userId}/edit`} label={<Text><FontAwesomeIcon icon={['fal', 'calculator']} /> Risk Matrix (Alpha)</Text>} margin={{ horizontal: 'xsmall' }} target='_self' />
              }
            </Box>
          </Box>
        </Box>}
      {userDetails?.stickyNote &&
        <Box background='stickyNote' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small' elevation='small'>
          <Box direction='row-responsive' justify='between' pad={{ horizontal: 'medium', vertical: 'small' }}>
            <Box>
              <Text weight='bold'>Sticky Note:</Text>
              <Text>{userDetails?.stickyNote}</Text>
            </Box>
          </Box>
        </Box>
      }

      <Box background='white' elevation='small' direction='column' gap='small' round='small' flex='grow'>
        <Box
          gap='small'
          margin={{ horizontal: 'small' }}
          pad='small'
        >
          <Tabs alignControls='start' activeIndex={tabIndex} content={tabContent} onActive={onActiveTab} />
        </Box>
      </Box>
    </Box>
  )
}

export default ServiceUserEdit
