import React, { useState, useEffect } from 'react'

import { useParams } from 'react-router-dom'

import moment from 'moment'
import useInterval from 'react-useinterval'

import { constants } from '../../utils/constants'
import Responsive from '../../utils/Responsive'

// Grommet
import { ResponsiveContext } from 'grommet'

import { getObjectByValue } from '../../utils/objects'

import LocalStorageService from '../../services/LocalStorageService'
import MediaService from '../../services/MediaService'
import NotificationService from '../../services/NotificationService'
import UserService from '../../services/UserService'

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor.js'
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import Divider from '../../components/simple/divider/Divider'
import FontAwesome from '../../components/compound/fontAwesome/FontAwesome'
import Form from '../../components/simple/form/Form.js'
import FormField from '../../components/simple/formField/FormField.js'
import H2 from '../../components/simple/heading/H2.js'
import Layer from '../../components/simple/layer/Layer'
import NotificationLayer from '../../components/compound/notification/NotificationLayer'
import Select from '../../components/simple/input/Select'
import Text from '../../components/simple/text/Text'
import TextArea from '../../components/simple/input/TextArea'
import TextInput from '../../components/simple/input/TextInput'

function Notes (props) {
  // Notes list on display ie. could be filtered
  const [notes, setNotes] = useState([])

  // All Notes
  const [notesAll, setNotesAll] = useState([])

  const [noteToArchive, setNoteToArchive] = useState(null)
  const [showArchiveOptions, showArchive] = useState(false)

  // New note detail being added
  const [newNote, setNewNote] = useState({
    noteType: '',
    noteDescription: '',
    noteAssignTo: '',
    duration: ''
  })

  // Display Note full screen?
  const [displayNoteOpen, setDisplayNoteOpen] = useState(false)
  const [noteFull, setNoteFull] = useState()

  // Has an interval been started to save a draft Note?
  const [intervalState, setIntervalState] = useState(false)

  // Has a draft version of the Note been saved ?
  // This will contain the id of the draft version if so
  const [draftSaved, setDraftSaved] = useState(null)

  const [users, setUsers] = useState([])

  // Search bar input
  const [filter, setFilter] = useState('')
  // note autoexpand
  const [scrollHeight, setScrollHeight] = useState(200)

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrganisation = LocalStorageService.get('activeOrg')
  const activeSite = LocalStorageService.get('activeSite')

  const urlParams = useParams()
  let userId = null

  // Start a 5s interval if not already started to save draft Note
  const checkInterval = (values) => {
    setNewNote(values)

    if (!intervalState) {
      setIntervalState(true)

      // const intervalId = setInterval(() => submitNote(false), 5000)
    }
  }

  useInterval(() => {
    submitNote(false)
  }, intervalState ? 5000 : null)

  const onOpenDisplayNote = (noteId) => {
    if (noteId) {
      const selectedNote = getObjectByValue(notes, 'id', noteId)

      setNoteFull(selectedNote)
      setDisplayNoteOpen(true)
    }
  }

  const onCloseDisplayNote = () => {
    setDisplayNoteOpen(undefined)
  }

  // Check for Service User Note
  if (urlParams.userid !== undefined) {
    userId = urlParams.userid
  }

  // Check for Staff Note
  if (urlParams.staffid !== undefined) {
    userId = urlParams.staffid
  }

  // Filter has been changed
  const filterDocuments = (input) => {
    const filtered = notesAll.filter(note => {
      return note.description.toLowerCase().includes(input.toLowerCase())
    })

    setFilter(input)
    setNotes(filtered)
  }

  // Prompt user to delete note
  const showArchiveNoteOptions = (note) => {
    setNoteToArchive(note)
    showArchive(true)
  }

  // Archive note
  const archiveNote = async () => {
    // Delete from server
    console.log(noteToArchive)
    const response = await MediaService.delete(apiToken, noteToArchive)
    if (response.data.success === 'true') {
      // And from state to force a refresh
      const newNotes = notes.filter((item) => item.id !== noteToArchive.id)
      setNotes(newNotes)
      NotificationService.success(`${noteToArchive.description} deleted`)
    } else {
      NotificationService.error(response.error)
    }
    showArchive(false)
  }

  // Submit Note Details
  const submitNote = async (submitStatus) => {
    const params = {
      orgId: activeOrganisation.id
    }

    const usersToNotify = []
    usersToNotify.push(newNote.assignTo)

    const data = {
      description: newNote.noteDescription,
      organisation: activeOrganisation.id,
      published: submitStatus,
      subType: newNote.noteType,
      title: 'Note',
      type: 'note'
    }

    // Need to do it this way or create takes ages to return a response
    if (newNote.assignTo) {
      data.usersToNotify = JSON.stringify(usersToNotify)
    }

    // Check if Service User Note
    if (urlParams.userid !== undefined) {
      data.owner = urlParams.userid
    }

    // Check if Staff Note
    if (urlParams.staffid !== undefined) {
      data.owner = urlParams.staffid
    }

    let response = null

    // If draftSaved=null then create a new media
    if (draftSaved === null) {
      response = await MediaService.create(apiToken, params, data)
    } else {
      response = await MediaService.update(apiToken, draftSaved, data)
    }

    if (response?.error) {
      NotificationService.error(response.error)
    } else {
      // If submitStatus=false we have saved a draft copy
      // so save the media id in draftSaved to update later on
      if (!submitStatus) {
        setDraftSaved(response.data[0].id)
      }

      // Refresh document list
      const notes = await getUsersAndNotes()

      if (submitStatus) {
        // Stop interval saving of Draft Note
        setIntervalState(false)

        // Clear input
        setNewNote({ noteType: '', noteDescription: '', noteAssignTo: '', duration: '' })

        // And reset no draft saved
        setDraftSaved(null)

        NotificationService.success('Note successfully saved')
      }
    }
  }

  const getUsersAndNotes = async () => {
    let params = {
      fields: 'id,firstName,lastName,email,userName,reference,ethnicity,gender,createdAt',
      limit: 1000,
      orgId: activeOrganisation.id,
      siteId: activeSite?.id || null,
      type: urlParams.staffid !== undefined ? 'staff' : 'client'
    }

    const users = await UserService.getUsers(apiToken, params)
    if (users?.error) {
      NotificationService.error(users.error)
    } else {
      const mappedUsers = users.data.map((data, index) => ({
        id: data.id,
        name: data.firstName + ' ' + data.lastName
      }))

      setUsers(mappedUsers)

      // Now get the Notes
      params = {
        fields: 'id,title,createdAt,subType,description,owner',
        limit: 10,
        orgId: activeOrganisation.id,
        sort: 'id DESC',
        where: {
          organisation: activeOrganisation.id,
          published: true,
          owner: userId,
          type: 'note',
          deleted: false
        }
      }

      const notes = await MediaService.get(apiToken, params)
      console.log(notes)
      if (notes?.error) {
        // NotificationService.error(notes.error)
      } else {
        // users?.data.find(user => user.id === item.owner)
        let owner = ''
        notes.data.forEach(note => {
          owner = users?.data.find(user => user.id === note.owner)

          if (urlParams.staffid !== undefined) {
            note.ownerName = owner.firstName + ' ' + owner.lastName
          }
        })
        setNotes(notes.data)
        setNotesAll(notes.data)
      }
    }
  }


  useEffect(() => {
    let unmounted = false

    getUsersAndNotes()

    return () => { unmounted = true }
  }, [])

  return (
    <Box gridArea='main' background='white' direction='column' gap='small' round='small'>
      <Divider color='primary' />
      <Box
        gap='small'
        margin={{ horizontal: 'small' }}
        pad='small'
        round='small'
      >

        <Box fill direction='row' gap='small'>
          <H2 margin={{ vertical: 'none' }}>Notes</H2>
          {showArchiveOptions &&
            <NotificationLayer button1Text='Yes' button1Click={archiveNote} button2Text='No' button2Click={() => { showArchive(false) }}>Are you sure you want to archive the note {noteToArchive.description}?</NotificationLayer>}
          <Button label={<FontAwesome icon={['fal', 'question-circle']} />} plain tip='Notes' />
        </Box>

        <>
          <Form
            onChange={nextValue => {
              checkInterval(nextValue)
              // setNewNote(nextValue)
            }}
            onSubmit={event => {
              console.log('SUBMIT ', event.value)
              // submitNote(event.value, true)
              submitNote(true)
            }}
            // onSubmit={({ value: nextValue }) => {
            //   submitNote(nextValue)
            // }}
            value={newNote}
          >
            <Responsive
              rows={['auto']}
              columns={{
                small: ['auto'],
                medium: ['1/2', '1/2'],
                large: ['1/2', '1/2'],
                xlarge: ['1/2', '1/2']
              }}
              gap='medium'
            >
              <Box>
                <Text weight='bold'>New Note</Text>
                {/* Type */}
                <FormField
                  label='Type'
                  name='noteType'
                >
                  <Select
                    name='noteType'
                    emptySearchMessage='No types found'
                    labelKey='label'
                    options={constants.noteTypes}
                    valueKey={{ key: 'value', reduce: true }}
                  />
                </FormField>

                {/* Description */}
                <FormField
                  label='Message'
                  name='noteDescription'
                >
                  <TextArea
                    style={{ height: scrollHeight }}
                    multiline
                    onChange={(e) => {
                      const scrollHeight = e.target.scrollHeight > 200 ? e.target.scrollHeight : 200
                      return setScrollHeight(scrollHeight)
                    }}
                    name='noteDescription'
                  />
                </FormField>

                {/* Assign To */}
                <FormField
                  label='Notify'
                  name='assignTo'
                >
                  <Select
                    name='assignTo'
                    emptySearchMessage='No assignees found'
                    // onChange={
                    //   (event, option) => { setAcademicYear(event.value) }
                    // }
                    labelKey='name'
                    options={users}
                    valueKey={{ key: 'id', reduce: true }}
                  />
                </FormField>

                {/* Duration */}
                <FormField
                  label='Duration (minutes)'
                  name='duration'
                >
                  <TextInput
                    name='duration'
                    type='number'
                  />
                </FormField>
                <Button label='Save' primary type='submit' />
              </Box>

              <Box pad={{ left: 'small' }} border={[{ size: 'small', color: '#eee', style: 'solid', side: 'left' }]}>
                <Text weight='bold'>History</Text>

                <Box direction='row-responsive' gap='small' margin={{ top: 'medium' }}>
                  <TextInput
                    icon={<FontAwesome icon={['fal', 'search']} />}
                    onChange={event => filterDocuments(event.target.value)}
                    reverse
                    value={filter}
                  />
                </Box>
                {notes?.length > 0
                  ? notes?.map((item) => (
                    <Box direction='column' key={item.id} margin={{ left: '0px', top: '20px' }}>
                      <Box direction='row' justify='between'>
                        <Text size='small'>{moment(item.createdAt).format('MMM Do YYYY - HH:mm')}</Text>
                        <Button icon={<Text><FontAwesome icon={['fal', 'eye']} /></Text>} onClick={() => onOpenDisplayNote(item.id)} plain />
                      </Box>
                      <Text size='medium' truncate weight='bold' margin={{ top: '-5px' }}>{item.subType}: {item.description}</Text>
                      <Text size='xsmall'>
                        <Anchor onClick={() => { showArchiveNoteOptions(item) }} size='xsmall' weight={100} color='darkGrey'>
                          Archive
                        </Anchor>
                      </Text>

                      {/* {urlParams.staffid !== undefined &&
                        <Text size='small'>By: {item.ownerName}</Text>} */}
                    </Box>)
                  )
                  : <Text>No Notes available</Text>}
              </Box>
            </Responsive>

            {displayNoteOpen &&
              <ResponsiveContext.Consumer>
                {responsive => (
                  <Layer
                    margin={responsive === 'small' ? 'medium' : 'large'}
                    onClickOutside={onCloseDisplayNote}
                    onEsc={onCloseDisplayNote}
                    position='center'
                    responsive={false}
                  >
                    <Box
                      flex
                      overflow='auto'
                      pad='medium'
                      width={responsive === 'small' ? 'medium' : 'large'}
                    >
                      <Text>{noteFull.description}</Text>
                    </Box>
                  </Layer>)}
              </ResponsiveContext.Consumer>}
            <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />

            <Box direction='row' justify='between' margin={{ top: 'medium' }}>
              <Button label='< Back' onClick={() => props.previousPage()} secondary />
            </Box>
          </Form>
        </>
      </Box>
    </Box>
  )
}

export default Notes
