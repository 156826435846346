import React, { useState, useEffect } from 'react'

import { useHistory, useParams } from 'react-router-dom'

import ApiService from '../../services/ApiService'
import LocalStorageService from '../../services/LocalStorageService'
import NotificationService from '../../services/NotificationService'
import OrganisationService from '../../services/OrganisationService'
import UserService from '../../services/UserService'

import { hasPermission, hasAnyPermissionIn } from '../../utils/permissions'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor'
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import DateInput from '../../components/simple/input/DateInput'
import Divider from '../../components/simple/divider/Divider'
import Form from '../../components/simple/form/Form.js'
import FormField from '../../components/simple/formField/FormField.js'
import FormFieldRequired from '../../components/simple/formField/FormFieldRequired'

import Select from '../../components/simple/input/Select'
import Tabs from '../../components/simple/tabs/Tabs.js'
import Text from '../../components/simple/text/Text'
import TextArea from '../../components/simple/input/TextArea'
import TextInput from '../../components/simple/input/TextInput'

import StaffEditAccess from './StaffEdit-Access'
import StaffEditAuditTrail from './StaffEdit-AuditTrail'
// import StaffEditDocuments from './StaffEdit-Documents'
// import StaffEditNotes from './StaffEdit-Notes'
import StaffEditRoles from './StaffEdit-Roles'

import Documents from '../shared/User-Documents'
import Notes from '../shared/User-Notes'

function StaffEdit () {
  const history = useHistory()

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')
  const activeSite = LocalStorageService.get('activeSite')

  // Initialize userDetails otherwise we get error
  // A component is changing an uncontrolled input of type text to be controlled
  const [userDetails, setUserDetails] = useState({
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    address: '',
    postcode: '',
    city: '',
    mobile: '',
    notes: ''
  })

  // Contains array of org objects for populating the School dropdown
  const [orgValues, setOrgValues] = useState([])

  // Contains an Organisation object that has been selected from the School dropdown
  const [selectedOrg, setSelectedOrg] = useState(null)

  // Contains array of role objects for populating the Role dropdown
  const [roleValues, setRoleValues] = useState([])

  const params = useParams()

  const userId = params.staffid

  // Tab controls
  const [tabIndex, setTabIndex] = useState(0)
  const onActiveTab = (nextIndex) => {
    // if (nextIndex === 1) {
    //   getGroups()
    // }
    setTabIndex(nextIndex)
  }

  // Go Back
  const goBack = () => {
    history.push('/staff')
  }

  var tabContent = []
  tabContent.push({
    name: 'Staff Details',
    content: (
      <Form
        //   validate='blur'
        onChange={nextValue => {
          // console.log(nextValue)
          setUserDetails(nextValue)
        }}
        onSubmit={({ value: nextValue }) => {
          // console.log(nextValue)
          submitForm(nextValue)
        }}
        value={userDetails}
      >
        <Divider color='primary' />
        <Box direction='row-responsive' gap='medium' margin={{ top: 'medium' }}>
          <Box direction='column'>

            <Text margin={{ top: 'xsmall' }} size='large'>Contact Details</Text>
            <Box direction='row-responsive' gap='medium'>
              <FormFieldRequired
                label='Firstname'
                name='firstName'
                required
              >
                <TextInput
                  name='firstName'
                  type='text'
                />
              </FormFieldRequired>

              <FormFieldRequired
                label='Lastname'
                name='lastName'
              >
                <TextInput
                  name='lastName'
                  type='text'
                />
              </FormFieldRequired>
            </Box>

            <Box direction='row-responsive' gap='medium'>
              <FormField
                direction='column'
                label='Address'
                name='addressLineOne'
              >
                <TextInput
                  name='addressLineOne'
                  type='text'
                />
              </FormField>

              <FormField
                direction='column'
                label='Postcode'
                name='postcode'
              >
                <TextInput
                  name='postcode'
                  type='text'
                />
              </FormField>

              <FormField
                direction='column'
                label='City'
                name='city'
              >
                <TextInput
                  name='city'
                  type='text'
                />
              </FormField>
            </Box>

            <Box direction='row-responsive' gap='medium'>
              <FormField
                label='Mobile'
                name='mobile'
              >
                <TextInput
                  name='mobile'
                  type='text'
                />
              </FormField>

              <FormFieldRequired
                label='Email'
                name='email'
              >
                <TextInput
                  name='email'
                  type='email'
                />
              </FormFieldRequired>

              <FormField
                label='Start Date'
                name='startDate'
              >
                <DateInput
                  name='startDate'
                />
              </FormField>
            </Box>
          </Box>

          <Box direction='column'>
            <FormField
              label='Other Notes'
              name='notes'
            >
              <TextArea
                name='notes'
              />
            </FormField>
          </Box>
        </Box>
        {userId !== 'new' &&
          <>
          </>}
        <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />

        <Box direction='row' justify='between' margin={{ top: 'medium' }}>
          <Button label='< Back' onClick={() => goBack()} secondary />
          <Button type='submit' label='Save' primary />
        </Box>
      </Form>)
  })
  if (userId !== 'new') {

    if (hasPermission('roleManage')) {
      tabContent.push({
        name: 'Roles',
        content: (
          <StaffEditRoles
            userId={userId}
            goBack={goBack}
          />
        )
      })
    }

    if (hasAnyPermissionIn(['staffDocsView', 'staffDocsAll'])) {
      tabContent.push({
        name: 'Documents',
        hideTitle: false,
        content: (
          <Documents
            previousPage={goBack}
          />
        )
      })
    }

    if (hasAnyPermissionIn(['staffNotesView', 'staffNotesAll'])) {
      tabContent.push({
        name: 'Notes',
        content: (
          <Notes
            previousPage={goBack}
          />
        )
      })
    }

    if (hasPermission('staffGrantDirectAccess')) {
      tabContent.push({
        name: 'Access',
        content: (
          <StaffEditAccess
            userId={userId}
            goBack={goBack}
          />
        )
      })
    }
  }

  // {
  //   name: 'Audit Trail',
  //   content: (
  //     <StaffEditAuditTrail
  //       userId={userId}
  //     />
  //   )
  // }

  // Submit form values
  const submitForm = async (values) => {
    // console.log('submitForm ', values)

    values.type = 'staff'

    // To change to inviteUser
    // remove data:{} leaving firstName etc.
    const toSend = {
      data: {
        firstName: values.firstName,
        lastName: values.lastName,
        addressLineOne: values.addressLineOne,
        postcode: values.postcode,
        city: values.city,
        mobile: values.mobile,
        email: values.email,
        username: values.email,
        type: 'staff'
      },
      organisation: activeOrg.id,
      sendInvite: true
    }

    if (userId === 'new') {
      var userAdded = await UserService.createUser(apiToken, toSend)
      if (userAdded?.error) {
        NotificationService.error('Error saving the user, please try again - ' + userAdded.error)
      } else {
        NotificationService.success('User created')
        history.push(`/staff/${userAdded.data.id[0]}/edit`)
      }
    } else {
      var userUpdated = await UserService.updateUser(apiToken, userId, toSend)
      if (userUpdated.error) {
        NotificationService.error('Error saving the user, please try again - ' + userUpdated.error)
      } else {
        NotificationService.success('User updated successfully')
        history.push(`/staff/${userId}/edit`)
      }
    }
  }

  // A different school has been selected
  const changeOrganisation = (org) => {
    setSelectedOrg(org)
  }

  // Reset User password
  const resetPassword = async (email) => {
    try {
      var passwordResetStatus = await ApiService.resetPassword(null, email)
    } catch (err) {
      NotificationService.error('Password reset error - ', err)
      console.log(err)
      return
    }

    NotificationService.error('Password reset email has been sent', passwordResetStatus)
  }

  // Basically componentDidMount
  useEffect(() => {
    let unmounted = false

    // const getOrganisations = async () => {
    //   // const apiToken = LocalStorageService.get('apiToken')

    //   const organisations = LocalStorageService.get('userOrganisations')
    //   if (organisations.error) {
    //     NotificationService.error(organisations.error)
    //   } else {
    //     if (!unmounted) {
    //       // const mappedOrganisations = organisations.data.map((data, index) => ({
    //       //   id: data.id,
    //       //   name: data.name,
    //       //   createdAt: data.createdAt
    //       // }))
    //       setOrgValues(organisations)
    //     }
    //   }
    // }

    const getUserDetails = async (userId) => {
      const params = {
        fields: 'id,firstName,dob,lastName,email,userName,addressLineOne,postcode,city,mobile,createdAt',
        limit: 1,
        type: 'staff',
        orgId: activeOrg.id,
        siteId: activeSite?.id || null
      }

      const userDetails = await UserService.getUser(apiToken, userId, params)
      if (userDetails.error) {
        NotificationService.error(userDetails.error)
      } else {
        if (!unmounted) {
          setUserDetails(userDetails.data[0])
        }
      }
    }

    // getOrganisations()

    if (userId !== 'new') {
      getUserDetails(userId)
    }

    return () => { unmounted = true }
  }, [])

  return (
    <Box width='xlarge'>
      <Box background='white' elevation='small' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>
        <Box direction='column' pad={{ horizontal: 'medium' }}>
          <Text margin={{ top: 'small' }} size='xlarge'>Staff Contact: {userDetails.id ? userDetails.firstName + ' ' + userDetails.lastName : 'New User'}</Text>
          <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'><Anchor href='/'>Snowdrop CRM</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></Text>
        </Box>
      </Box>

      {userDetails.id &&
        <Box background='white' elevation='small' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small' flex='grow'>
          <Box direction='row-responsive' justify='between' pad={{ horizontal: 'medium', vertical: 'small' }}>
            <Box>
              <Text size='xxlarge' weight={400} margin={{ top: 'none' }}>Quick Actions</Text>
            </Box>
            <Box alignSelf='center' direction='row'>
              <Button label={<Text><FontAwesomeIcon icon={['fal', 'key']} /> Reset Password</Text>} margin={{ horizontal: 'xsmall' }} onClick={() => { resetPassword(userDetails.email) }} target='_self' />
              <Button href='userdelete' label={<Text><FontAwesomeIcon icon={['fal', 'trash']} /> Archive User</Text>} margin={{ horizontal: 'xsmall' }} target='_self' />
            </Box>
          </Box>
        </Box>}

      <Box background='white' elevation='small' direction='column' gap='small' round='small' flex='grow'>
        <Box
          gap='small'
          margin={{ horizontal: 'small' }}
          pad='small'
          round='small'
        >
          <Box direction='column' gap='medium'>
            <Tabs alignControls='start' activeIndex={tabIndex} content={tabContent} onActive={onActiveTab} />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default StaffEdit
