import React, { useState } from 'react'

// Appt Components
import Box from '../../components/simple/box/Box'
import Form from '../../components/simple/form/Form.js'
import FormField from '../../components/simple/formField/FormField.js'
import Divider from '../../components/simple/divider/Divider'
import Grid from '../../components/simple/grid/Grid.js'
import H2 from '../../components/simple/heading/H2.js'
// import Text from '../../components/simple/text/Text'
// import TextArea from '../../components/simple/input/TextArea'
import TextInput from '../../components/simple/input/TextInput'

function PostIncidentLearning (props) {
  const [questions, setQuestions] = useState(props.questions)

  // Submit form values
  const submitForm = async (values) => {
    props.submitForm(values)
  }

  return (
    <Box gridArea='main' background='white' direction='column' gap='small' round='small'>
      <Box
        gap='small'
        margin={{ horizontal: 'small' }}
        pad='small'
        round='small'
      >
        <H2 margin={{ vertical: 'none' }}>Post Incident Learning</H2>
        <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
        <Form
          //   validate='blur'
          onChange={nextValue => {
            props.setIncidentValues(nextValue)
          }}
          onSubmit={({ value: nextValue }) => {
            submitForm(nextValue)
          }}
          value={props.incidentValues}
        >
          <Grid
            rows={['auto', 'auto', 'auto']}
            columns={['auto', 'auto', 'auto']}
            gap='small'
            areas={[
              { name: 'main', start: [0, 0], end: [1, 1] }
            ]}
          >
            <FormField
              label={questions[0].questionText}
              name={questions[0].key}
              required
            >
              <TextInput
                name={questions[0].key}
                type='text'
              />
            </FormField>

            <FormField
              label={questions[1].questionText}
              name={questions[1].key}
              required
            >
              <TextInput
                name={questions[1].key}
                type='text'
              />
            </FormField>

            <FormField
              label={questions[2].questionText}
              name={questions[2].key}
              required
            >
              <TextInput
                name={questions[2].key}
                type='text'
              />
            </FormField>

            <FormField
              label={questions[3].questionText}
              name={questions[3].key}
              required
            >
              <TextInput
                name={questions[3].key}
                type='text'
              />
            </FormField>

            <FormField
              label={questions[4].questionText}
              name={questions[4].key}
              required
            >
              <TextInput
                name={questions[4].key}
                type='text'
              />
            </FormField>

            <FormField
              label={questions[5].questionText}
              name={questions[5].key}
              required
            >
              <TextInput
                name={questions[5].key}
                type='text'
              />
            </FormField>

            <FormField
              label={questions[6].questionText}
              name={questions[6].key}
              required
            >
              <TextInput
                name={questions[6].key}
                type='text'
              />
            </FormField>

            <FormField
              label={questions[7].questionText}
              name={questions[7].key}
            >
              <TextInput
                name={questions[7].key}
              />
            </FormField>

          </Grid>
        </Form>
      </Box>
    </Box>
  )
}

export default PostIncidentLearning
