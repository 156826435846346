import React, { useState } from 'react'

// Spinner
import PulseLoader from 'react-spinners/PulseLoader'

// Appt Components
import Box from '../../../components/simple/box/Box'
import Button from '../../../components/simple/button/Button'
import Form from '../../../components/simple/form/Form.js'
import FormField from '../../../components/simple/formField/FormField.js'
import Divider from '../../../components/simple/divider/Divider'
import H2 from '../../../components/simple/heading/H2.js'
import OptionButtons from '../../../components/compound/optionButtons/OptionButtons'
import TextArea from '../../../components/simple/input/TextArea'

function Family (props) {
  const [loading, setLoading] = useState(false)

  const [questions, setQuestions] = useState(props.questions)
  const [localFormDetails, setLocalFormDetails] = useState(props.formDetails)

  const previousPage = () => {
    props.previousPage()
  }

  const submitForm = () => {
    props.submitForm(localFormDetails, 'Draft')
  }

  return (
    <Box gridArea='main' background='white' direction='column' gap='small' round='small'>
      <Divider color='primary' />
      <Box
        gap='small'
        margin={{ horizontal: 'small' }}
        pad='small'
        round='small'
      >

        <Box fill direction='row' gap='small'>
          <H2 margin={{ vertical: 'none' }}>Family</H2>
        </Box>

        <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
        {localFormDetails && questions.length
          ? (
            <Form
              onChange={nextValue => {
                setLocalFormDetails(nextValue)
              }}
              onSubmit={({ value: nextValue }) => {
                submitForm(nextValue)
              }}
              value={localFormDetails}
            >
              <Box direction='row-responsive' gap='small'>
                {/* Family here */}
                <FormField
                  label={questions[0].questionText}
                  name={questions[0].key}
                >
                  <OptionButtons
                    id={questions[0].key}
                    name={questions[0].key}
                  />
                </FormField>
              </Box>

              <Box direction='row-responsive' gap='small'>
                {/* Family abroad */}
                <FormField
                  label={questions[1].questionText}
                  name={questions[1].key}
                >
                  <OptionButtons
                    id={questions[1].key}
                    name={questions[1].key}
                  />
                </FormField>
              </Box>

              <Box direction='row-responsive' gap='small'>
                {/* Family contact */}
                <FormField
                  label={questions[2].questionText}
                  name={questions[2].key}
                >
                  <OptionButtons
                    id={questions[2].key}
                    name={questions[2].key}
                  />
                </FormField>
              </Box>

              <Box direction='row-responsive' gap='small'>
                {/* Want family contact? */}
                <FormField
                  label={questions[3].questionText}
                  name={questions[3].key}
                >
                  <OptionButtons
                    id={questions[3].key}
                    name={questions[3].key}
                  />
                </FormField>
              </Box>

              <Box direction='row-responsive' gap='small'>
                {/* Family details */}
                <FormField
                  label={questions[4].questionText}
                  name={questions[4].key}
                >
                  <TextArea
                    name={questions[4].key}
                  />
                </FormField>
              </Box>

              <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />

              <Box direction='row' justify='between' margin={{ top: 'medium' }}>
                <Button label='< Back' onClick={() => previousPage()} secondary />
                <Button type='submit' label='Next Page' primary />
              </Box>
            </Form>)
          : (
            <Box align='center'>
              <PulseLoader
                size={15}
                color='orange'
                loading={loading}
              />
            </Box>)}
      </Box>
    </Box>
  )
}
export default Family
