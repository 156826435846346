
// const hideValues = false

import ApiService from './ApiService'
import LocalStorageService from './LocalStorageService'

const apiToken = LocalStorageService.get('apiToken')


class RoleService {
  getRole = async (apiToken, roleId, customParams = {}) => {
    var url = `/api/role/${roleId}`

    var params = {
      fields: 'id,title,site,permissions,createdAt',
      limit: 1,
      ...customParams
    }

    try {
      var role = await ApiService.get(apiToken, url, params)
    } catch (err) {
      console.log(err)
    }
    console.log('RoleService:getRole ', role)
    return role
  }

  getRoles = async (apiToken, params, where) => {
    var url = '/api/role'

    if (where) {
      params.where = where
    }

    try {
      var roles = await ApiService.get(apiToken, url, params)
    } catch (err) {
      console.log(err)
    }
    console.log('RoleService:getRoles ', roles)
    return roles
  }

  getRolesForUser = async (userId, customParams) => {
    var url = `/api/user/${userId}/role`

    var params = {
      fields: 'id,title,site,permissions,createdAt',
      limit: 20,
      ...customParams
    }

    try {
      var user = await ApiService.get(apiToken, url, params)
    } catch (err) {
      console.log(err)
    }
    console.log('UserService:getRolesForUser ', user)
    return user
  }

  addRole = async (apiToken, data) => {
    var url = '/api/role'

    // var params = {
    //   fields: 'id,firstName,lastName,email,userName',
    //   limit: 1000
    // }

    var params = {}

    var options = {
      data: data
    }

    try {
      var result = await ApiService.post(apiToken, url, params, options)
    } catch (err) {
      console.log(err)
    }
    console.log('RoleService:addRole ', result)
    return result
  }

  updateRole = async (apiToken, data) => {
    const roleId = data.id
    var url = `/api/role/${roleId}`

    // var params = {
    //   fields: 'id,firstName,lastName,email,userName',
    //   limit: 1000
    // }

    var params = {}

    var options = {
      data: data
    }

    try {
      var result = await ApiService.put(apiToken, url, params, options)
    } catch (err) {
      console.log(err)
    }
    console.log('RoleService:updateRole ', result)
    return result
  }

  getRoleUsers = async (apiToken, roleId, params) => {
    var url = `/api/role/${roleId}/user`

    try {
      var roleUsers = await ApiService.get(apiToken, url, params)
    } catch (err) {
      console.log(err)
    }
    console.log('RoleService:getRoleUsers ', roleUsers)
    return roleUsers
  }

  addRoleUser = async (apiToken, siteId, roleId, userId) => {
    var url = `/api/role/${roleId}/user/${userId}`

    var params = {
      siteId: siteId
    }

    var options = {}

    try {
      var result = await ApiService.post(apiToken, url, params, options)
    } catch (err) {
      console.log(err)
    }
    console.log('RoleService:addRoleUser ', result)
    return result
  }

  removeRoleUser = async (apiToken, roleId, userId) => {
    // const userId = data.id
    var url = `/api/role/${roleId}/user/${userId}`

    var params = {}

    var options = {}
    try {
      var result = await ApiService.sendDelete(apiToken, url, params, options)
    } catch (err) {
      console.log(err)
    }
    console.log('RoleService:deleteRoleUser ', result)
    return result
  }
}
export default new RoleService()
