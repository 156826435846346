import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Box, Form, ResponsiveContext } from 'grommet'
import { StepHeader } from './StepHeader'
import { RiskContext } from './RiskContext'

export const StepContent = ({ onSubmit }) => {
  const size = useContext(ResponsiveContext)
  const {
    activeIndex,
    setActiveIndex,
    formValues,
    id,
    ref,
    setFormValues,
    setValid,
    steps,
    width
  } = useContext(RiskContext)

  const handleSubmit = (event) => {
    // TEMP
    // setValid(true)
    // if (activeIndex < 3) {
    //   console.log(formValues)

    //   setActiveIndex(activeIndex + 1)
    // } else if (onSubmit) {
    //   onSubmit(event)
    // }

    setValid(true)
    if (activeIndex < steps.length - 1) {
      console.log(formValues)
      onSubmit(event)
      setActiveIndex(activeIndex + 1)
    } else if (onSubmit) {
      onSubmit(event)
    }
  }

  const handleFormChange = (values) => {
    // NB. Check nextValue as the set state
    // will not have completed in time
    switch (activeIndex) {
      case 0:
        if (values.firstName !== '' && values.lastName !== '' && values.gender !== '' && values.nationality !== '' && values.age !== '') {
          steps[activeIndex].riskLevel = 'Complete'
        } else {
          steps[activeIndex].riskLevel = 'Missing Answers'
        }
        break
      case 1:
        steps[activeIndex].selected = values.homelessness
        break
    }
  }

  // On long forms, we want to focus the first of any fields that
  // return an error or info message. This removes the need for the
  // user to scroll to find which field blocked form submission.
  const onValidate = (validationResults) => {
    const names = [
      ...Object.keys(validationResults.errors),
      ...Object.keys(validationResults.infos)
    ]
    if (names.length > 0) {
      const selector = names.map((name) => `[name=${name}]`).join(',')
      const firstInvalid = document.querySelectorAll(selector)[0]
      if (firstInvalid) {
        setTimeout(() => firstInvalid.focus(), 0)
      }
    }
    setTimeout(() => setValid(names.length === 0), 0)
  }

  return (
    <Box
      align='start'
      pad={size !== 'small' ? { vertical: 'large' } : { vertical: 'medium' }}
      overflow='auto'
      ref={ref}
      flex={size === 'small' ? true : undefined}
      margin={size !== 'small' ? { horizontal: 'medium' } : undefined}
    >
      <Box
        width='100%'
        gap='medium'
        pad={size === 'small' ? { horizontal: 'medium' } : 'xxsmall'}
      >
        <StepHeader />
        <Box margin={{ top: 'small' }}>
          <Form
            // needed to associate form submit button with form
            // since submit button lives outside form tag
            id={`${id}-form`}
            value={formValues}
            onChange={(nextValue) => {
              setFormValues(nextValue)
              handleFormChange(nextValue)
            }}
            onSubmit={handleSubmit}
            onValidate={onValidate}
            method='post'
            messages={{
              required: 'This is a required field.'
            }}
          >
            {steps[activeIndex].inputs}
          </Form>
        </Box>
      </Box>
    </Box>
  )
}

StepContent.propTypes = {
  onSubmit: PropTypes.func.isRequired
}
