import React, { useState, useEffect } from 'react'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Appt Components
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import DataTable from '../../components/simple/dataTable/DataTable.js'
import Form from '../../components/simple/form/Form.js'
import FormField from '../../components/simple/formField/FormField.js'
import Divider from '../../components/simple/divider/Divider'
import H2 from '../../components/simple/heading/H2.js'
import RadioButtonGroup from '../../components/simple/input/RadioButtonGroup'
import Text from '../../components/simple/text/Text'
import TextArea from '../../components/simple/input/TextArea'
import TextInput from '../../components/simple/input/TextInput'

function MedicalIntervention (props) {
  const [questions, setQuestions] = useState(props.questions)
  const [interventionAddButton, setInterventionAddButton] = useState(false)

  // Submit form values
  const submitForm = async (values) => {
    props.submitForm(values)
  }

  // Interventions have been altered
  // so rebuild the display list
  const [mappedInterventions, setMappedInterventions] = useState([])
  const [interventionColumns, setInterventionDataColumns] = useState()

  const addIntervention = () => {
    const intervention = {
      interventionKey: props.interventions.length,
      injurySufferedBy: props.incidentValues.injurySufferedBy,
      injury: props.incidentValues.injury,
      treatment: props.incidentValues.treatment
    }
    props.setIntervention(interventions => [...interventions, intervention])

    props.setIncidentValues(incidentValues => ({
      ...incidentValues,
      injurySufferedBy: '',
      injury: '',
      treatment: ''
    }))
    setInterventionAddButton(false)
  }

  const removeIntervention = (data) => {
    const newInterventions = props.interventions.filter((item) => item.interventionKey !== data.interventionKey)

    props.setIntervention(newInterventions)
  }

  useEffect(() => {
    setInterventionDataColumns([
      {
        property: 'injurySufferedBy',
        header: <Text pad='none' margin='none'>Injury Suffered by</Text>
      },
      {
        property: 'injury',
        header: <Text>Injury</Text>
      },
      {
        property: 'treatment',
        header: <Text>Treatment</Text>
      },
      {
        property: 'actions',
        render: datum => (
          <Box direction='row'>
            <Button label={<Text><FontAwesomeIcon icon={['fal', 'minus-circle']} /></Text>} margin={{ horizontal: 'xsmall' }} style={{ background: '#333' }} onClick={() => removeIntervention(datum)} target='_self' />
          </Box>
        )
      }
    ])

    const mappedInterventions = props.interventions.map((intervention, index) => ({
      interventionKey: index,
      injurySufferedBy: intervention.injurySufferedBy,
      injury: intervention.injury,
      treatment: intervention.treatment
    }))

    setMappedInterventions(mappedInterventions)
  }, [props.interventions])

  return (
    <Box gridArea='main' background='white' direction='column' gap='small' round='small'>
      <Box
        gap='small'
        margin={{ horizontal: 'small' }}
        pad='small'
        round='small'
      >
        <Box fill direction='row' gap='small'>
          <H2 margin={{ vertical: 'none' }}>Medical Intervention</H2>
          <Button label={<FontAwesomeIcon icon={['fal', 'question-circle']} />} plain tip='This is the first page of the Incident Report' />
        </Box>
        <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
        <Form
          //   validate='blur'
          onChange={nextValue => {
            // Enable Intervention Add button if all fields have data
            setInterventionAddButton(nextValue.injurySufferedBy !== '' && nextValue.injury !== '' && nextValue.treatment !== '')

            props.setIncidentValues(nextValue)
          }}
          onSubmit={({ value: nextValue }) => {
            submitForm(nextValue)
          }}
          value={props.incidentValues}
        >
          <Box>
            <Box direction='row-responsive' gap='small'>
              <FormField
                label={questions[0].questionText}
                name={questions[0].key}
                required
              >
                <RadioButtonGroup
                  direction='row'
                  name={questions[0].key}
                  // onChange={event =>
                  //   setActionButton(event.target.value)}
                  options={['Yes', 'No']}
                >
                  {(option, { checked, hover }) => {
                    let background
                    if (checked) background = 'brand'
                    else if (hover) background = 'light-4'
                    else background = 'light-2'
                    return (
                      <Box background={background} pad='small'>
                        {option}
                      </Box>
                    )
                  }}
                </RadioButtonGroup>
              </FormField>

              <FormField
                label={questions[1].questionText}
                name={questions[1].key}
                required
              >
                <TextInput
                  name={questions[1].key}
                  type='text'
                />
              </FormField>

              <FormField
                label={questions[2].questionText}
                name={questions[2].key}
                required
              >
                <TextInput
                  name={questions[2].key}
                  type='text'
                />
              </FormField>
            </Box>
            <Box direction='row-responsive' gap='medium'>
              <DataTable
                columns={interventionColumns}
                data={mappedInterventions}
                fill='horizontal'
                primaryKey={false}
                size='large'
              />
            </Box>
            <Box direction='row-responsive' gap='medium'>
              <FormField
                label={questions[3].questionText}
                name={questions[3].key}
              >
                <TextInput
                  name={questions[3].key}
                  type='text'
                />
              </FormField>

              <FormField
                label={questions[4].questionText}
                name={questions[4].key}
              >
                <TextInput
                  name={questions[4].key}
                  type='text'
                />
              </FormField>

              <FormField
                label={questions[5].questionText}
                name={questions[5].key}
              >
                <TextInput
                  name={questions[5].key}
                  type='text'
                />
              </FormField>

              <Button disabled={!interventionAddButton} label={<Text><FontAwesomeIcon icon={['fal', 'plus-circle']} /></Text>} color='primary' onClick={() => addIntervention()} style={{ height: '35px' }} alignSelf='end' margin={{ bottom: 'small' }} />
            </Box>
          </Box>
          <FormField
            label={questions[6].questionText}
            name={questions[6].key}
          >
            <TextArea
              name={questions[6].key}
            />
          </FormField>
        </Form>
      </Box>
    </Box>
  )
}

export default MedicalIntervention
