import React, { useState, useEffect } from 'react'

import moment from 'moment'

// Spinner
import PulseLoader from 'react-spinners/PulseLoader'

// Appt Components
import Box from '../../../components/simple/box/Box'
import Button from '../../../components/simple/button/Button'
import DateInput from '../../../components/simple/input/DateInput'
import DataTable from '../../../components/simple/dataTable/DataTable'
import Divider from '../../../components/simple/divider/Divider'
import FontAwesome from '../../../components/compound/fontAwesome/FontAwesome'
import Form from '../../../components/simple/form/Form.js'
import FormField from '../../../components/simple/formField/FormField.js'
import H2 from '../../../components/simple/heading/H2.js'
import OptionButtons from '../../../components/compound/optionButtons/OptionButtons'
import Text from '../../../components/simple/text/Text'
import TextArea from '../../../components/simple/input/TextArea'
import TextInput from '../../../components/simple/input/TextInput'

function IndependentLiving (props) {
  const [loading, setLoading] = useState(false)

  const [questions, setQuestions] = useState(props.questions)
  const [localFormDetails, setLocalFormDetails] = useState(props.formDetails)

  // Determine if independent living goal Add button is enabled or not
  const [independentLivingGoalAddButton, setIndependentLivingGoalAddButton] = useState(true)
  // Array of independent living goal
  const [independentLivingGoals, setIndependentLivingGoals] = useState([])
  // Array of independent living goals mapped for DataTable
  const [mappedIndependentLivingGoals, setMappedIndependentLivingGoals] = useState([])
  // DataTable columns for independent living goals
  const [independentLivingGoalsColumns, setIndependentLivingGoalsDataColumns] = useState()

  const previousPage = () => {
    props.previousPage()
  }

  const submitForm = () => {
    props.submitForm(localFormDetails, 'Draft')
  }

  // Independant Living Goals management functions
  const addIndependentLivingGoal = () => {
    // Don't save if all fields empty
    if (localFormDetails.independentLivingGoal !== '' || localFormDetails.independentLivingGoalAction !== '' || localFormDetails.independentLivingGoalActionee !== '' || localFormDetails.independentLivingGoalDate !== '') {
      const event = {
        independentLivingGoal: localFormDetails.independentLivingGoal,
        independentLivingGoalAction: localFormDetails.independentLivingGoalAction,
        independentLivingGoalActionee: localFormDetails.independentLivingGoalActionee,
        independentLivingGoalDate: localFormDetails.independentLivingGoalDate
      }

      setIndependentLivingGoals(independentLivingGoals => [...independentLivingGoals, event])

      // Clear input fields
      setLocalFormDetails(localFormDetails => ({
        ...localFormDetails,
        independentLivingGoal: '',
        independentLivingGoalAction: '',
        independentLivingGoalActionee: '',
        independentLivingGoalDate: ''
      }))

      setIndependentLivingGoalAddButton(true)
    }
  }

  const removeIndependentLivingGoal = (data) => {
    const newEvent = independentLivingGoals.filter((item) => item.independentLivingGoal !== data.independentLivingGoal)

    setIndependentLivingGoals(newEvent)
  }

  const editIndependentLivingGoal = (data) => {
    setLocalFormDetails(localFormDetails => ({
      ...localFormDetails,
      independentLivingGoal: data.independentLivingGoal,
      independentLivingGoalAction: data.independentLivingGoalAction,
      independentLivingGoalActionee: data.independentLivingGoalActionee,
      independentLivingGoalDate: data.independentLivingGoalDate
    }))

    removeIndependentLivingGoal(data)
    setIndependentLivingGoalAddButton(false)
  }

  // Independent Living Goals have been altered
  // so rebuild the display list
  useEffect(() => {
    setIndependentLivingGoalsDataColumns([
      {
        property: 'independentLivingGoal',
        header: <Text pad='none' margin='none'>Goal</Text>
      },
      {
        property: 'independentLivingGoalAction',
        header: <Text>What action is needed?</Text>
      },
      {
        property: 'independentLivingGoalActionee',
        header: <Text pad='none' margin='none'>Who is going to do this?</Text>
      },
      {
        property: 'independentLivingGoalDate',
        header: <Text pad='none' margin='none'>By when?</Text>
      },
      {
        property: 'actions',
        render: datum => (
          <Box direction='row'>
            <Button label={<Text><FontAwesome icon={['fal', 'minus-circle']} /></Text>} margin={{ horizontal: 'xsmall' }} style={{ background: '#333' }} onClick={() => removeIndependentLivingGoal(datum)} target='_self' />
            <Button label={<Text><FontAwesome icon={['fal', 'edit']} /></Text>} margin={{ horizontal: 'xsmall' }} style={{ background: '#333' }} onClick={() => editIndependentLivingGoal(datum)} target='_self' />
          </Box>
        )
      }
    ])

    const mappedIndependentLivingGoals = independentLivingGoals.map((event, index) => ({
      independentLivingGoal: event.independentLivingGoal,
      independentLivingGoalAction: event.independentLivingGoalAction,
      independentLivingGoalActionee: event.independentLivingGoalActionee,
      independentLivingGoalDate: moment(event.independentLivingGoalDate).format('YYYY-MM-DD')
    }))

    setMappedIndependentLivingGoals(mappedIndependentLivingGoals)

    // Stringify independent living goals and store them
    const independentLivingGoalsToSave = JSON.stringify(independentLivingGoals)

    setLocalFormDetails(localFormDetails => ({
      ...localFormDetails,
      independentLivingGoals: independentLivingGoalsToSave
    }))
  }, [independentLivingGoals])

  return (
    <Box gridArea='main' background='white' direction='column' gap='small' round='small'>
      <Divider color='primary' />
      <Box
        gap='small'
        margin={{ horizontal: 'small' }}
        pad='small'
        round='small'
      >

        <Box fill direction='row' gap='small'>
          <H2 margin={{ vertical: 'none' }}>Independent Living</H2>
        </Box>

        <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
        {localFormDetails && questions.length
          ? (
            <Form
              onChange={nextValue => {
                setLocalFormDetails(nextValue)
                setIndependentLivingGoalAddButton(nextValue?.independentLivingGoal === '' || nextValue?.independentLivingGoalAction === '' || nextValue?.independentLivingGoalActionee === '' || nextValue?.independentLivingGoalDate === '')
              }}
              onSubmit={({ value: nextValue }) => {
                submitForm(nextValue)
              }}
              value={localFormDetails}
            >
              <Box direction='row-responsive' gap='small'>
                {/* independentLiving */}
                <FormField
                  label={questions[0].questionText}
                  name={questions[0].key}
                  width='100%'
                >
                  <TextArea
                    name={questions[0].key}
                  />
                </FormField>
              </Box>

              <Box direction='row-responsive' gap='small'>
                {/* independentLivingRisk */}
                <FormField
                  label={questions[1].questionText}
                  name={questions[1].key}
                >
                  <OptionButtons
                    id={questions[1].key}
                    name={questions[1].key}
                  />
                </FormField>
              </Box>

              {localFormDetails.independentLivingRisk === 'Yes' &&
                <Box direction='row-responsive' gap='small'>
                  {/* independentLivingRiskReason */}
                  <FormField
                    label={questions[2].questionText}
                    name={questions[2].key}
                    width='100%'
                  >
                    <TextArea
                      name={questions[2].key}
                    />
                  </FormField>
                </Box>}

              <Divider color='primary' margin={{ top: 'medium', bottom: 'small' }} />

              <Text margin={{ top: 'xsmall' }} size='large'>Goals</Text>

              {mappedIndependentLivingGoals.length > 0 &&
                <Box direction='row-responsive' gap='medium'>
                  <DataTable
                    columns={independentLivingGoalsColumns}
                    data={mappedIndependentLivingGoals}
                    fill='horizontal'
                    size='large'
                  />
                </Box>}

              <Box direction='row-responsive' gap='medium'>
                {/* independentLivingGoal */}
                <FormField
                  label={questions[3].questionText}
                  name={questions[3].key}
                >
                  <TextInput
                    name={questions[3].key}
                    type='text'
                  />
                </FormField>

                {/* independentLivingGoalAction */}
                <FormField
                  label={questions[4].questionText}
                  name={questions[4].key}
                >
                  <TextInput
                    name={questions[4].key}
                    type='text'
                  />
                </FormField>

                {/* independentLivingGoalActionee */}
                <FormField
                  label={questions[5].questionText}
                  name={questions[5].key}
                >
                  <TextInput
                    name={questions[5].key}
                    type='text'
                  />
                </FormField>

                {/* independentLivingGoalDate */}
                <FormField
                  label={questions[6].questionText}
                  name={questions[6].key}
                >
                  <DateInput
                    name={questions[6].key}
                  />
                </FormField>

                <Button disabled={independentLivingGoalAddButton} label={<Text>Add</Text>} color='primary' onClick={() => addIndependentLivingGoal()} style={{ height: '35px' }} alignSelf='end' margin={{ bottom: 'small' }} />
              </Box>

              <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />

              <Box direction='row' justify='between' margin={{ top: 'medium' }}>
                <Button label='< Back' onClick={() => previousPage()} secondary />
                <Button type='submit' label='Next Page' primary />
              </Box>
            </Form>)
          : (
            <Box align='center'>
              <PulseLoader
                size={15}
                color='orange'
                loading={loading}
              />
            </Box>)}
      </Box>
    </Box>
  )
}
export default IndependentLiving
