import React, { useContext } from 'react'
import { Box, Button, Heading, Paragraph, Text, ResponsiveContext } from 'grommet'
import { RiskContext } from './RiskContext'

import styled from 'styled-components'

const UpperCaseText = styled(Text)`
  text-transform: uppercase;
`

export const StepHeader = () => {
  const size = useContext(ResponsiveContext)
  const { activeIndex, activeStep, id, setActiveIndex, steps } = useContext(RiskContext)

  const previousStep = () => {
    if (activeIndex !== 0) {
      setActiveIndex(activeIndex - 1)
    }
  }

  return (
    <Box id={id} gap='xsmall' flex={false}>
      {activeStep !== 1 &&
        <>
          <Box direction='row' justify='between'>
            <Box direction='column'>
              <UpperCaseText size='xsmall'>Previous - {steps[activeIndex - 1].section}</UpperCaseText>
              <Text>
                {steps[activeIndex - 1].title}
              </Text>
            </Box>

            <Button
              fill={size === 'small' ? 'horizontal' : undefined}
              // icon={<FormNextLink />}
              secondary
              label='Previous'
              form={`${id}-form`}
              onClick={() => previousStep()}
            />
          </Box>
        </>}

      {activeIndex !== steps.length - 1 &&
        <Box margin={{ top: 'medium' }}>
          <UpperCaseText size='xsmall'>{steps[activeIndex].question} - {steps[activeIndex].section}</UpperCaseText>
          <Heading color='text-strong' margin='none' size='small'>
            {steps[activeIndex].title}
          </Heading>
          <UpperCaseText size='xsmall' weight='bold'>{steps[activeIndex].description}</UpperCaseText>
        </Box>}
    </Box>
  )
}
