import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { useStore } from 'react-context-hook'

import moment from 'moment'

// Spinner
import PulseLoader from 'react-spinners/PulseLoader'

// Services
import LocalStorageService from '../../services/LocalStorageService'
import NotificationService from '../../services/NotificationService'
import OrganisationService from '../../services/OrganisationService'
import UserService from '../../services/UserService'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor'
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import DataTable from '../../components/simple/dataTable/DataTable.js'
import Text from '../../components/simple/text/Text'
import H1 from '../../components/simple/heading/H1'

import NotificationLayer from '../../components/compound/notification/NotificationLayer'

function GlobalAdminList () {
  const [showDeleteOptions, showDelete] = useState(false)
  const [orgToDelete, setOrgToDelete] = useState(null)
  const [userValues, setUserValues] = useState([])
  const [loading, setLoading] = useState(false)

  const history = useHistory()

  // DataTable sort state
  const [sort, setSort] = useState({
    property: 'name',
    direction: 'asc'
  })

  const [apiToken, setApiToken, deleteApiToken] = useStore(null)

//   // Prompt user to delete
//   const showDeleteOrgOptions = (org) => {
//     setOrgToDelete(org)
//     showDelete(true)
//   }

//   // Delete an Organisation
//   const deleteOrg = async () => {
//     // Delete from server
//     const response = await OrganisationService.deleteOrg(apiToken, orgToDelete)
//     if (response.data.success === 'true') {
//       // And from state to force a refresh
//       const newOrgValues = orgValues.filter((item) => item.id !== orgToDelete.id)
//       setOrgValues(newOrgValues)
//       NotificationService.info('Organisation deleted')
//     } else {
//       NotificationService.error(response.error)
//     }
//     showDelete(false)
//   }

  useEffect(() => {
    let unmounted = false

    // Get global admins
    const getGlobalAdmins = async () => {
      const apiToken = LocalStorageService.get('apiToken')

      const params = {
        fields: 'id,firstName,lastName,email,createdAt',
        limit: 100
      }

      setLoading(true)
      const users = await UserService.getGlobalAdmins(apiToken, params)
      if (users.error) {
        // NotificationService.error(users.error)
      } else {
        if (!unmounted) {
          // if there's no users, do nothing
          if (!users || !users.data) {
            return
          }

          const mappedUsers = users.data.map((data, index) => ({
            id: data.id,
            name: data.firstName + ' ' + data.lastName,
            email: data.email,
            createdAt: moment(data.createdAt).fromNow()
          }))

          setUserValues(mappedUsers)
        }
      }
    }

    getGlobalAdmins()

    setLoading(false)

    return () => { unmounted = true }
  }, [])

  const dataColumns = [
    {
      property: 'name',
      header: <Text>Name</Text>,
      primary: true
    },
    {
      property: 'email',
      header: <Text>Email</Text>
    },
    {
      property: 'createdAt',
      header: <Text>Created</Text>
    },
    {
      property: 'actions',
      header: <Text>Actions</Text>,
      render: datum => (
        <Box direction='row'>
          <Button href={`staff/${datum.id}/edit`} label={<Text><FontAwesomeIcon icon={['fal', 'edit']} /></Text>} margin={{ horizontal: 'xsmall' }} target='_self' />
          {/* <Button label={<Text><FontAwesomeIcon icon={['fal', 'trash']} /></Text>} margin={{ horizontal: 'xsmall' }} onClick={() => showDeleteOrgOptions(datum)} target='_self' /> */}
        </Box>
      )
    }
  ]

  return (
    <>
      <Box background='white' elevation='small' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>
        <Box direction='column' pad={{ horizontal: 'medium' }}>
          <H1 margin={{ top: 'small', bottom: 'none' }}>Global Admins</H1>
          <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'><Anchor href='/'>Snowdrop CRM</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></Text>
        </Box>
      </Box>

      <Box background='white' elevation='small' direction='column' gap='small' round='small'>
        <Box direction='row-responsive' gap='xlarge' justify='between' pad={{ horizontal: 'medium', top: 'medium' }}>
          <Box>
            <Text color='#000' size='large' weight='bold'>Global Admins</Text>
          </Box>
        </Box>

        <Box direction='column' gap='medium' pad={{ horizontal: 'medium', vertical: 'medium' }}>
          <Box align='center'>
            <PulseLoader
              size={15}
              color='orange'
              loading={loading}
            />
          </Box>
          <DataTable
            columns={dataColumns}
            data={userValues}
            onSort={setSort}
            paginate
            sort={sort}
            step={10}
          />
          {!userValues.length && <Text size='small'>- There are no Global Admins to display -</Text>}
        </Box>
      </Box>
    </>
  )
}

export default GlobalAdminList
