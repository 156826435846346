import ApiService from './ApiService'

class RiskMatrixService {
  riskMatrix = async (apiToken, submissionId) => {
    var url = '/snowdrop/riskMatrix'

    var params = {
      formSubmission: submissionId
    }

    try {
      var role = await ApiService.get(apiToken, url, params)
    } catch (err) {
      console.log(err)
    }
    console.log('RiskMatrixService:riskMatrix ', role)
    return role
  }
}
export default new RiskMatrixService()
