import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

// Grommet
import { Grommet } from 'grommet'

// Appt
import Box from '../components/simple/box/Box.js'
import Button from '../components/simple/button/Button.js'
import Login from '../components/compound/login/Login.js'
import PasswordResetRequest from '../components/compound/login/PasswordResetRequest.js'
import Text from '../components/simple/text/Text.js'
import UserVerification from '../components/compound/login/UserVerification.js'

// Services
import ApiService from '../services/ApiService'
import NotificationService from '../services/NotificationService'
import PasswordService from '../services/PasswordService'
import LocalStorageService from '../services/LocalStorageService'

// Stores
import { useStore } from 'react-context-hook'

// Error handler
// import useErrorHandler from '../utils/useErrorHandler'

// Theme
// import { hostTheme } from 'utilities/hostTheme'
import { snowdropTheme } from '../utils/snowdropTheme'

function LoginScreen(props) {
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [createPassword, setCreatePassword] = useState(false)
  const [viewLogin, setLoginView] = useState(true)
  const [verificationNeeded, setVerification] = useState(false)
  const [userValues, setUserValues] = useState()

  const [apiToken, setApiToken, deleteApiToken] = useStore(null)
  const [currentUser, setCurrentUser, deleteCurrentUser] = useStore({})
  const [currentOrgs, setCurrentOrgs, deleteCurrentOrgs] = useStore([])

  // const { error, showError } = useErrorHandler(null)

  const signInClicked = async (values) => {
    setUserValues(values)

    // if the user clicks login clear their current session
    // they're obviously trying to access a different account
    // clear the session to ensure a fresh start
    LocalStorageService.clear()

    var loginStatus = await ApiService.login(values.username, values.password)

    // stop if the login failed
    if (loginStatus.error) {
      NotificationService.error(loginStatus.error)
    } else {
      if (loginStatus.data?.verificationNeeded) {
        setVerification(true)
        // history.push('/user/verification', { username: values.username, password: values.password })
      } else {
        props.handleLogin(loginStatus.data)

        // if you're here, you're logged in
        history.push('/dashboard')
      }
    }
  }

  const signup = () => {
    history.push('/user/signup')
  }

  const resetPasswordClicked = async (values) => {
    // organisationId not needed for a reset password from the login screen
    const organisationId = 50
    try {
      var passwordResetStatus = await PasswordService.resetPasswordRequest(organisationId, values.email)
    } catch (err) {
      NotificationService.error(err)
    }

    if (passwordResetStatus?.error) {
      NotificationService.error(passwordResetStatus.error)
    } else {
      NotificationService.info('A password reset has been requested. Please check your inbox.')

      console.log(passwordResetStatus)
      setLoginView(true)
    }
  }

  const handleVerificationCode = async (code) => {
    const loginStatus = await ApiService.verifyAccount(userValues.username, userValues.password, code)

    // stop if the login failed
    if (loginStatus.error) {
      NotificationService.error(loginStatus.error)
    } else {
      // if you're here, you're logged in
      props.handleLogin(loginStatus.data)
      history.push('/dashboard')
    }
  }

  const viewLoginScreen = () => {
    setLoginView(true)
  }

  const viewPasswordForgotScreen = () => {
    setLoginView(false)
  }

  // Login screen parameters
  const title = {
    label: 'Login',
    position: 'start'
  }

  const username = {
    label: 'Email Address',
    position: 'above'
  }

  const password = {
    label: 'Password',
    position: 'above'
  }

  const submit = {
    label: 'Next',
    size: 'small',
    position: 'end'
  }

  const forgotPassword = {
    label: 'Forgotten your password?',
    position: 'start'
  }

  return (
    <>

      <Box align='center' height='100%' justify='center' alignSelf='start' background='background' pad='medium' round='medium'>
        {viewLogin && !verificationNeeded &&
          <Box>
            <Box pad={{ vertical: 'medium' }} width='medium'>
              <Login forgotPassword={forgotPassword} image='/images/snowdrop-logo.png?r=001' labelColor='primary' onForgotPasswordClick={viewPasswordForgotScreen} onSignInClick={signInClicked} password={password} submit={submit} title={title} username={username} />
            </Box>
          </Box>}
        {!viewLogin && !verificationNeeded &&
          <Box fill justify='center'>
            <PasswordResetRequest image='/images/snowdrop-logo.png?r=001' onBackClick={viewLoginScreen} onResetPasswordClick={resetPasswordClicked} />
          </Box>}
        {verificationNeeded &&
          <Box fill justify='center'>
            <UserVerification image='/images/snowdrop-logo.png?r=001' onBackClick={viewLoginScreen} handleVerificationCode={handleVerificationCode} />
          </Box>}
      </Box>

    </>
  )
}

export default LoginScreen
