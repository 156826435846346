import React, { useEffect, useState } from 'react'

// Appt Components
import Select from '../../components/simple/input/Select'

// Services
import FormService from '../../services/FormService'
import LocalStorageService from '../../services/LocalStorageService'

const QueryBox = (props) => {
//   const apiToken = LocalStorageService.get('apiToken')
//   const activeOrg = LocalStorageService.get('activeOrg')

  const [queryOptions, setQueryOptions] = useState([])

  const dateQueries = ['equal to', 'not equal to', 'is after', 'is before']
  const numberQueries = ['equal to', 'not equal to', 'greater than', 'less than', 'greater than or equal', 'less than or equal']
  const stringQueries = ['equal to', 'not equal to', 'ends with', 'starts with', 'contains']

  useEffect(() => {
    let unmounted = false

    // Set query options based on field datatype
    const setQuery = async () => {
      switch (props.dataType) {
        case 'string':
          setQueryOptions(stringQueries)
          break
        case 'number':
          setQueryOptions(numberQueries)
          break
        case 'date':
          setQueryOptions(dateQueries)
          break
        default:
          setQueryOptions(stringQueries)
      }
    }

    setQuery()

    return () => { unmounted = true }
  }, [])

  return (
    <>
      <Select
        {...props}
        // id='student'
        //   disabled={props.userId !== 'new'}
        //   name={questions[0].key}
        // labelKey='questionText'
        options={queryOptions}
        // valueKey={{ key: 'id', reduce: true }}
      />
    </>
  )
}

export default QueryBox
