import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { useStore } from 'react-context-hook'

import { capitalize } from '../../utils/text'
import { hasPermission, hasAnyPermissionIn } from '../../utils/permissions'

// Spinner
import PulseLoader from 'react-spinners/PulseLoader'

// Services
import LocalStorageService from '../../services/LocalStorageService'
import NotificationService from '../../services/NotificationService'
import UserService from '../../services/UserService'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Error handler
// import useErrorHandler from '../../utils/useErrorHandler'
// import FlashMessage from '../../utils/FlashMessage'

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor'
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import DataTable from '../../components/simple/dataTable/DataTable.js'
import Text from '../../components/simple/text/Text'
import H1 from '../../components/simple/heading/H1'

import NotificationLayer from '../../components/compound/notification/NotificationLayer'

function ServiceUserList (props) {
  const [userValues, setUserValues] = useState([])
  const history = useHistory()
  const [userToDelete, setUserToDelete] = useState(null)
  const [showDeleteOptions, showDelete] = useState(false)
  // const { error, showError } = useErrorHandler(null)
  const [loading, setLoading] = useState(false)

  // DataTable sort state
  const [sort, setSort] = useState({
    property: 'reference',
    direction: 'asc'
  })

  // const [apiToken, setApiToken, deleteApiToken] = useStore(null)
  const [currentUser, setCurrentUser, deleteCurrentUser] = useStore({})
  const [currentOrgs, setCurrentOrgs, deleteCurrentOrgs] = useStore([])

  const terms = LocalStorageService.get('terms')
  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')
  const activeSite = LocalStorageService.get('activeSite')

  // Prompt user to delete
  const showDeleteUserOptions = (user) => {
    setUserToDelete(user)
    showDelete(true)
  }

  // Delete a User
  const deleteUser = async () => {
    // Delete from server
    const response = await UserService.deleteUser(apiToken, userToDelete)
    if (response.data.success === 'true') {
      // And from state to force a refresh
      const newUserValues = userValues.filter((item) => item.id !== userToDelete.id)
      setUserValues(newUserValues)
      NotificationService.success(`${terms.serviceUser} deleted`)
    } else {
      NotificationService.error(response.error)
    }
    showDelete(false)
  }

  // Basically componentDidMount
  useEffect(() => {
    let unmounted = false

    // Get Users for this organisation
    const getUsers = async () => {
      const params = {
        fields: 'id,firstName,lastName,reference',
        limit: 1000,
        orgId: activeOrg.id,
        siteId: activeSite?.id || null,
        type: 'client'
      }

      setLoading(true)
      const users = await UserService.getUsers(apiToken, params)
      if (users?.error) {
        // NotificationService.error(users.error)
      } else {
        if (!unmounted) {
          if (users?.error) {
            setUserValues(null)
          } else if (users?.data) {
            const mappedUsers = users.data.map((data, index) => ({
              id: data.id,
              reference: data.reference,
              name: data.firstName + ' ' + data.lastName
            }))

            setUserValues(mappedUsers)
          }
        }
      }
    }

    getUsers()
    setLoading(false)

    return () => { unmounted = true }
  }, [])

  const dataColumns = [
    {
      property: 'reference',
      header: <Text weight={600}>Reference</Text>,
      primary: true,
      search: true,
      render: datum => (
        <Text>C000{datum.id}</Text>
      )
    },
    {
      property: 'name',
      header: <Text weight={600}>Name</Text>,
      primary: true,
      search: true
    },
    // {
    //   property: 'school',
    //   header: <Text>School</Text>,
    //   render: datum => (
    //     <Text>Coming soon</Text>
    //   )
    // },
    // {
    //   property: 'email',
    //   header: <Text>Email</Text>
    // },
    // {
    //   property: 'created',
    //   header: <Text>Created</Text>
    // },
    {
      property: 'actions',
      header: <Text weight={600}>Actions</Text>,
      render: datum => (
        <Box direction='row'>
          {/* hasAnyPermissionIn(['clientViewMy', 'clientViewAll']) &&
            <Button
              href={`/client/${datum.id}/profile`}
              target='_self'
              plain
              children={
                <Box pad='5px'>
                  <FontAwesomeIcon icon={['fal', 'eye']} color='#fe8300' />
                </Box>
              }
            />
            */}
          {hasAnyPermissionIn(['clientEditMy', 'clientEditAll']) &&
            <Button
              href={`/client/${datum.id}/edit`}
              target='_self'
              plain
              children={
                <Box pad='5px'>
                  <FontAwesomeIcon icon={['fal', 'edit']} color='#fe8300' />
                </Box>
              }
            />
          }
          {hasAnyPermissionIn(['clientArchiveMy', 'clientArchiveAll']) &&
            <Button
              target='_self'
              plain
              onClick={() => showDeleteUserOptions(datum)}
              children={
                <Box pad='5px'>
                  <FontAwesomeIcon icon={['fal', 'trash']} color='#fe8300' />
                </Box>
              }
            />
          }
        </Box>
      ),
      sortable: false
    }
  ]

  return (
    <>
      <Box background='white' elevation='small' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>
        <Box direction='column' pad={{ horizontal: 'medium' }}>
          <H1 margin={{ top: 'small', bottom: 'none' }}>{capitalize(props?.terms?.serviceUser || 'Client')} Directory </H1>
          {showDeleteOptions &&
            <NotificationLayer button1Text='Yes' button1Click={deleteUser} button2Text='No' button2Click={() => { showDelete(false) }}>Are you sure you want to delete user {userToDelete.name}?</NotificationLayer>}
          <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'><Anchor href='/'>Snowdrop CRM</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></Text>
        </Box>
      </Box>

      <Box background='white' elevation='small' direction='column' gap='small' pad='medium' round='small'>
        <Box direction='column'>
          <Box alignSelf='end' direction='row-responsive' pad={{ horizontal: 'medium' }}>
            <Box direction='row-responsive' gap='xsmall'>
              <Button href='client/new/upload' label={<Text><FontAwesomeIcon icon={['fal', 'cloud-upload']} /> Bulk Upload</Text>} secondary />
              <Button href='client/new/edit' label={<Text><FontAwesomeIcon icon={['fal', 'plus-circle']} /> Add Client</Text>} target='_self' />
            </Box>
          </Box>

          <Box direction='column' gap='medium' pad={{ horizontal: 'medium' }}>
            <Box align='center'>
              <PulseLoader
                size={15}
                color='orange'
                loading={loading}
              />
            </Box>
            {userValues?.length
              ? <DataTable columns={dataColumns} data={userValues} onSort={setSort} sort={sort} />
              : <Text margin={{ vertical: 'small' }}>There are no records to display.</Text>}
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default ServiceUserList
