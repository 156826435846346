import React, { useState } from 'react'

// Spinner
// import PulseLoader from 'react-spinners/PulseLoader'

// Appt Components
import Box from '../../components/simple/box/Box'
import Form from '../../components/simple/form/Form.js'
import FormField from '../../components/simple/formField/FormField.js'
import Divider from '../../components/simple/divider/Divider'
import Grid from '../../components/simple/grid/Grid.js'
import H2 from '../../components/simple/heading/H2.js'
import RatingButtonGroup from '../../components/compound/ratingButtonGroup/RatingButtonGroup'
// import Select from '../components/simple/input/Select'
import Text from '../../components/simple/text/Text'
import TextArea from '../../components/simple/input/TextArea'
// import TextInput from '../components/simple/input/TextInput'

function PhysicalTechniques (props) {
  const [loading, setLoading] = useState(false)

  const [questions, setQuestions] = useState(props.questions)

  const submitForm = () => {
    props.submitForm()
  }

  return (
    <Box gridArea='main' background='white' direction='column' gap='small' round='small'>
      <Box
        gap='small'
        margin={{ horizontal: 'small' }}
        pad='small'
        round='small'
      >

        <H2 margin={{ vertical: 'none' }}>Physical Techniques used & effectiveness</H2>
        <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
        <Form
          //   validate='blur'
          onChange={nextValue => {
            props.setIncidentValues(nextValue)
          }}
          onSubmit={({ value: nextValue }) => {
            submitForm(nextValue)
          }}
          value={props.incidentValues}
        >
          <Grid
            rows={['auto', 'auto']}
            columns={['auto', 'auto']}
            gap='small'
            areas={[
              { name: 'main', start: [0, 0], end: [1, 1] }
            ]}
          >
            <FormField
              label={questions[0].questionText}
              name={questions[0].key}
            >
              <RatingButtonGroup
                id={questions[0].key}
                name={questions[0].key}
                minRange={1}
                maxRange={10}
                step={1}
              />
            </FormField>

            <FormField
              label={questions[1].questionText}
              name={questions[1].key}
            >
              <RatingButtonGroup
                id={questions[1].key}
                name={questions[1].key}
                minRange={1}
                maxRange={10}
                step={1}
              />
            </FormField>

            <FormField
              label={questions[2].questionText}
              name={questions[2].key}
            >
              <RatingButtonGroup
                id={questions[2].key}
                name={questions[2].key}
                minRange={1}
                maxRange={10}
                step={1}
              />
            </FormField>

            <FormField
              label={questions[3].questionText}
              name={questions[3].key}
            >
              <RatingButtonGroup
                id={questions[3].key}
                name={questions[3].key}
                minRange={1}
                maxRange={10}
                step={1}
              />
            </FormField>

            <FormField
              label={questions[4].questionText}
              name={questions[4].key}
            >
              <RatingButtonGroup
                id={questions[4].key}
                name={questions[4].key}
                minRange={1}
                maxRange={10}
                step={1}
              />
            </FormField>

            <FormField
              label={questions[5].questionText}
              name={questions[5].key}
            >
              <RatingButtonGroup
                id={questions[5].key}
                name={questions[5].key}
                minRange={1}
                maxRange={10}
                step={1}
              />
            </FormField>

            <FormField
              label={questions[6].questionText}
              name={questions[6].key}
            >
              <RatingButtonGroup
                id={questions[6].key}
                name={questions[6].key}
                minRange={1}
                maxRange={10}
                step={1}
              />
            </FormField>

            <FormField
              label={questions[7].questionText}
              name={questions[7].key}
            >
              <RatingButtonGroup
                id={questions[7].key}
                name={questions[7].key}
                minRange={1}
                maxRange={10}
                step={1}
              />

            </FormField>

            <FormField
              label={questions[8].questionText}
              name={questions[8].key}
            >
              <RatingButtonGroup
                id={questions[8].key}
                name={questions[8].key}
                minRange={1}
                maxRange={10}
                step={1}
              />
            </FormField>

            <FormField
              label={questions[9].questionText}
              name={questions[9].key}
            >
              <RatingButtonGroup
                id={questions[9].key}
                name={questions[9].key}
                minRange={1}
                maxRange={10}
                step={1}
              />

            </FormField>

            {props?.incidentValues?.otherPhysicalTechniques &&
              <FormField
                label={questions[10].questionText}
                name={questions[10].key}
              >
                <TextArea
                  name={questions[10].key}
                />
              </FormField>}
          </Grid>
        </Form>
      </Box>
    </Box>
  )
}
export default PhysicalTechniques
