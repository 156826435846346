import React, { useContext, useEffect, useRef, useState } from 'react'

import { useHistory, useParams } from 'react-router-dom'

import { constants } from '../../utils/constants'

import FormService from '../../services/FormService'
import LocalStorageService from '../../services/LocalStorageService'
import NotificationService from '../../services/NotificationService'
import RiskMatrixService from '../../services/RiskMatrixService'
import UserService from '../../services/UserService'

import { getObjectByValue } from '../../utils/objects'

import Anchor from '../../components/simple/anchor/Anchor'
import Box from '../../components/simple/box/Box'
import Divider from '../../components/simple/divider/Divider'
import FormField from '../../components/simple/formField/FormField.js'
import Grid from '../../components/simple/grid/Grid.js'
import RadioButtonGroup from '../../components/simple/input/RadioButtonGroup'
import Select from '../../components/simple/input/Select'
import Text from '../../components/simple/text/Text'
import TextArea from '../../components/simple/input/TextArea'
import TextInput from '../../components/simple/input/TextInput'

import { ResponsiveContext } from 'grommet'
import { RiskContext } from './RiskContext'
import { StepContent } from './StepContent'
import { StepFooter } from './StepFooter'

// import { getWidth } from './utils'

export const defaultFormValues = {
  firstName: '',
  lastName: '',
  gender: '',
  nationality: '',
  age: '',
  accommodation1OtherNotes: '',
  accommodation2OtherNotes: '',
  accommodation3OtherNotes: '',
  addiction1OtherNotes: '',
  addiction2OtherNotes: '',
  disability1OtherNotes: '',
  disability2OtherNotes: '',
  disability3OtherNotes: '',
  disability4OtherNotes: '',
  finances1OtherNotes: '',
  finances2OtherNotes: '',
  finances3OtherNotes: '',
  finances4OtherNotes: '',
  finances5OtherNotes: '',
  finances6OtherNotes: '',
  language1OtherNotes: '',
  language2OtherNotes: '',
  language3OtherNotes: '',
  legal1OtherNotes: '',
  legal2OtherNotes: '',
  legal3OtherNotes: '',
  legal4OtherNotes: '',
  legal5OtherNotes: '',
  legal6OtherNotes: '',
  legal7OtherNotes: '',
  mental1OtherNotes: '',
  mental2OtherNotes: '',
  mental3OtherNotes: '',
  mental4OtherNotes: '',
  physical1OtherNotes: '',
  physical2OtherNotes: '',
  physical3OtherNotes: '',
  relationship1OtherNotes: '',
  relationship2OtherNotes: '',
  relationship3OtherNotes: '',
  relationship4OtherNotes: '',
  relationship5OtherNotes: ''
}

const apiToken = LocalStorageService.get('apiToken')
const activeOrg = LocalStorageService.get('activeOrg')

const riskColorLookup = {
  Safeguarding: '#ff0012',
  'High Complexity': '#f16f33',
  'Medium Complexity': '#bc831c',
  'Low Complexity': '#63a345',
  Independent: '#00a9ea',
  'Missing Answers': '#e6a43b',
  Complete: '#dadada'
}

const riskScoreLookup = {
  Safeguarding: 0,
  'High Complexity': 1,
  'Medium Complexity': 2,
  'Low Complexity': 3,
  Independent: 4
}

const riskLabels = ['Safeguarding', 'High Complexity', 'Medium Complexity', 'Low Complexity', 'Independent']

const overallRisk = {
  score: -1
}

const stepAnswers = {
  accommodation: {
    accommodation1: {},
    accommodation2: {},
    accommodation3: {},
    riskScore: -1 // don't think these are used now - moved to state
  },
  addiction: {
    addiction1: {},
    addiction2: {},
    riskScore: -1
  },
  disability: {
    disability1: {},
    disability2: {},
    disability3: {},
    disability4: {},
    riskScore: -1
  },
  finances: {
    finances1: {},
    finances2: {},
    finances3: {},
    finances4: {},
    finances5: {},
    finances6: {},
    riskScore: -1
  },
  language: {
    language1: {},
    language2: {},
    language3: {},
    riskScore: -1
  },
  legal: {
    legal1: {},
    legal2: {},
    legal3: {},
    legal4: {},
    legal5: {},
    legal6: {},
    legal7: {},
    riskScore: -1
  },
  mental: {
    mental1: {},
    mental2: {},
    mental3: {},
    mental4: {},
    riskScore: -1
  },
  physical: {
    physical1: {},
    physical2: {},
    physical3: {},
    riskScore: -1
  },
  relationship: {
    relationship1: {},
    relationship2: {},
    relationship3: {},
    relationship4: {},
    relationship5: {},
    riskScore: -1
  }
}

const stepOneInputs = [
  (setValid) => (
    <>
      <Box direction='row-responsive' gap='small'>
        <FormField
          label='First Name'
          htmlFor='firstName'
          name='firstName'
          required
        >
          <TextInput
            id='firstname'
            name='firstName'
            onChange={() => setValid(true)}
          />
        </FormField>

        <FormField
          label='Last Name'
          htmlFor='lastName'
          name='lastName'
          required
        >
          <TextInput
            id='lastname'
            name='lastName'
            onChange={() => setValid(true)}
          />
        </FormField>
      </Box>

      <Box direction='row-responsive' gap='small'>
        <FormField
          label='Gender'
          htmlFor='gender'
          name='gender'
          required
        >
          <Select
            name='gender'
            labelKey='label'
            options={constants.gender}
            valueKey={{ key: 'value', reduce: true }}
          />
        </FormField>

        <FormField
          label='Nationality'
          htmlFor='nationality'
          name='nationality'
          required
        >
          <Select
            name='nationality'
            labelKey='label'
            options={constants.countries}
            valueKey={{ key: 'value', reduce: true }}
          />
        </FormField>
      </Box>

      <Box direction='row-responsive' gap='small'>
        <FormField
          label='Age'
          htmlFor='age'
          name='age'
          required
        >
          <TextInput
            id='age'
            name='age'
            onChange={() => setValid(true)}
            type='number'
          />
        </FormField>
      </Box>
    </>)
]

// Accommodation 1 of 3
const stepTwoOptions = [
  {
    value: 'accommodation1_Homelessness_with_a_child',
    label: 'Homelessness (with a child)',
    risk: 'Safeguarding'
  },
  {
    value: 'accommodation1_Homelessness',
    label: 'Homelessness',
    risk: 'High Complexity'
  },
  {
    value: 'accommodation1_Living_with_risky_people',
    label: 'Living with risky people',
    risk: 'Medium Complexity'
  },
  {
    value: 'accommodation1_Temporary_accommodation_with_council',
    label: 'Temporary Accommodation with Council',
    risk: 'Low Complexity'
  },
  {
    value: 'accommodation1_Permanent_accommodation_council_or_otherwise',
    label: 'Permanent accommodation (council or otherwise)',
    risk: 'Independent'
  }
]

// Calculate the Overall Risk for each section and overall
// const updateOverallRisk = () => {
//   let overallRiskScore = 0
//   let totalSubSectionCount = 0

//   for (var section in stepAnswers) {
//     let riskScore = 0
//     let subSectionCount = 0

//     for (const subSection in stepAnswers[section]) {
//       if (stepAnswers[section][subSection]?.risk?.risk) {
//         subSectionCount++
//         riskScore += riskScoreLookup[stepAnswers[section][subSection].risk.risk]
//       }
//     }

//     if (subSectionCount !== 0) {
//       stepAnswers[section].riskScore = Math.round(riskScore / subSectionCount)

//       totalSubSectionCount += subSectionCount

//       overallRiskScore += stepAnswers[section].riskScore
//     }
//   }

//   overallRisk.score = Math.round(overallRiskScore / totalSubSectionCount)
// }

// Set the answers for the section
const updateAnswer = (value, options, section, subsection) => {
  const riskLevel = getObjectByValue(options, 'value', value.value)
  stepAnswers[section][subsection].risk = riskLevel

  // updateOverallRisk()
}

const stepTwoInputs = [
  (setValid) => (
    <>
      <RadioButtonGroup
        name='accommodation1'
        options={stepTwoOptions}
        onChange={(event) => updateAnswer(event.target, stepTwoOptions, 'accommodation', 'accommodation1')}
      >
        {(option, { checked, hover }) => {
          let background
          if (checked) background = riskColorLookup[option.risk]
          else if (hover) background = riskColorLookup[option.risk]
          else background = 'light-4'
          return (
            <Box direction='row' width={{ max: 'medium' }}>
              <Box
                alignSelf='center'
                background={background}
                height='20px'
                round
                width='20px'
              />
              <Box direction='column' margin={{ left: 'small' }}>
                <Text>{option.label}</Text>
                <Text color={riskColorLookup[option.risk]} hidden={!checked} size='xsmall'>{option.risk}</Text>
              </Box>
            </Box>
          )
        }}
      </RadioButtonGroup>
    </>),
  () => (
    <FormField
      label='Other Notes'
      htmlFor='accommodation1OtherNotes'
      name='accommodation1OtherNotes'
    >
      <TextArea
        id='accommodation1OtherNotes'
        name='accommodation1OtherNotes'
      />
    </FormField>
  )
]

// Accommodation 2 of 3
const stepThreeOptions = [
  {
    value: 'accommodation2_Imminent_Risk_of_eviction_destitution',
    label: 'Imminent Risk of eviction/destitution',
    risk: 'High Complexity'
  },
  {
    value: 'accommodation2_Temporary_accommodation_at_B&B',
    label: 'Temporary accommodation at B&B',
    risk: 'Medium Complexity'
  },
  {
    value: 'accommodation2_Secure_NASS_accommodation',
    label: 'Secure NASS accommodation',
    risk: 'Low Complexity'
  }
]

const stepThreeInputs = [
  (setValid) => (
    <>
      <RadioButtonGroup
        name='accommodation2'
        options={stepThreeOptions}
        // onChange={(event) => doAccommodation(event.target)}
        onChange={(event) => updateAnswer(event.target, stepThreeOptions, 'accommodation', 'accommodation2')}
      >
        {(option, { checked, hover }) => {
          let background
          if (checked) background = riskColorLookup[option.risk]
          else if (hover) background = riskColorLookup[option.risk]
          else background = 'light-4'
          return (
            <Box direction='row'>
              <Box
                alignSelf='center'
                background={background}
                round
                width='20px'
                height='20px'
              />
              <Box direction='column' margin={{ left: 'small' }}>
                <Text>{option.label}</Text>
                <Text color={riskColorLookup[option.risk]} hidden={!checked} size='xsmall'>{option.risk}</Text>
              </Box>
            </Box>
          )
        }}
      </RadioButtonGroup>
    </>),
  () => (
    <FormField
      label='Other Notes'
      htmlFor='accommodation2OtherNotes'
      name='accommodation2OtherNotes'
    >
      <TextArea
        id='accommodation2OtherNotes'
        name='accommodation2OtherNotes'
      />
    </FormField>
  )
]

// Accommodation 3 of 3
const stepFourOptions = [
  {
    value: 'accommodation3_Section_17_paid_for_accommodation_time_limited',
    label: 'Section 17 paid for accommodation (time limited)',
    risk: 'High Complexity'
  },
  {
    value: 'accommodation3_recent_move',
    label: 'Recent move',
    risk: 'Medium Complexity'
  }
]

const stepFourInputs = [
  (setValid) => (
    <>
      <RadioButtonGroup
        name='accommodation3'
        options={stepFourOptions}
        onChange={(event) => updateAnswer(event.target, stepFourOptions, 'accommodation', 'accommodation3')}
      >
        {(option, { checked, hover }) => {
          let background
          if (checked) background = riskColorLookup[option.risk]
          else if (hover) background = riskColorLookup[option.risk]
          else background = 'light-4'
          return (
            <Box direction='row'>
              <Box
                alignSelf='center'
                background={background}
                round
                width='20px'
                height='20px'
              />
              <Box direction='column' margin={{ left: 'small' }}>
                <Text>{option.label}</Text>
                <Text color={riskColorLookup[option.risk]} hidden={!checked} size='xsmall'>{option.risk}</Text>
              </Box>
            </Box>
          )
        }}
      </RadioButtonGroup>
    </>),
  () => (
    <FormField
      label='Other Notes'
      htmlFor='accommodation3OtherNotes'
      name='accommodation3OtherNotes'
    >
      <TextArea
        id='accommodation3OtherNotes'
        name='accommodation3OtherNotes'
      />
    </FormField>
  )
]

// Addiction 1 of 2
const stepFiveOptions = [
  {
    value: 'addiction1_Active_addiction_to_alcohol_or_drugs_with_a_child',
    label: 'Active addiction to alcohol or drugs (with a child)',
    risk: 'Safeguarding'
  },
  {
    value: 'addiction1_Active_addiction_to_alcohol_drugs',
    label: 'Active addiction to alcohol/drugs',
    risk: 'High Complexity'
  },
  {
    value: 'addiction1_Consistent_engagement_with_substance_misuse_services',
    label: 'Consistent engagement with substance misuse services',
    risk: 'Medium Complexity'
  },
  {
    value: 'addiction1_Recovery_no_current_relapse',
    label: 'Recovery- no current relapse',
    risk: 'Low Complexity'
  },
  {
    value: 'addiction1_No_addiction_or_history_of_substance_misuse',
    label: 'No addiction or history of substance misuse',
    risk: 'Independent'
  }
]

const stepFiveInputs = [
  (setValid) => (
    <>
      <RadioButtonGroup
        name='addiction1'
        options={stepFiveOptions}
        onChange={(event) => updateAnswer(event.target, stepFiveOptions, 'addiction', 'addiction1')}
      >
        {(option, { checked, hover }) => {
          let background
          if (checked) background = riskColorLookup[option.risk]
          else if (hover) background = riskColorLookup[option.risk]
          else background = 'light-4'
          return (
            <Box direction='row'>
              <Box
                alignSelf='center'
                background={background}
                round
                width='20px'
                height='20px'
              />
              <Box direction='column' margin={{ left: 'small' }}>
                <Text>{option.label}</Text>
                <Text color={riskColorLookup[option.risk]} hidden={!checked} size='xsmall'>{option.risk}</Text>
              </Box>
            </Box>
          )
        }}
      </RadioButtonGroup>
    </>),
  () => (
    <FormField
      label='Other Notes'
      htmlFor='addiction1OtherNotes'
      name='addiction1OtherNotes'
    >
      <TextArea
        id='addiction1OtherNotes'
        name='addiction1OtherNotes'
      />
    </FormField>
  )
]

// Addiction 2 of 2
const stepSixOptions = [
  {
    value: 'addiction2_Sporadic_engagement_with_substance_misuse_services',
    label: 'Sporadic engagement with substance misuse services',
    risk: 'High Complexity'
  },
  {
    value: 'addiction2_Relapse',
    label: 'Relapse',
    risk: 'Medium Complexity'
  },
  {
    value: 'addiction2_In_recovery_for_historic_substance_misuse',
    label: 'In recovery for historic substance misuse',
    risk: 'Independent'
  }
]

const stepSixInputs = [
  (setValid) => (
    <>
      <RadioButtonGroup
        name='addiction2'
        options={stepSixOptions}
        onChange={(event) => updateAnswer(event.target, stepSixOptions, 'addiction', 'addiction2')}
      >
        {(option, { checked, hover }) => {
          let background
          if (checked) background = riskColorLookup[option.risk]
          else if (hover) background = riskColorLookup[option.risk]
          else background = 'light-4'
          return (
            <Box direction='row'>
              <Box
                alignSelf='center'
                background={background}
                round
                width='20px'
                height='20px'
              />
              <Box direction='column' margin={{ left: 'small' }}>
                <Text>{option.label}</Text>
                <Text color={riskColorLookup[option.risk]} hidden={!checked} size='xsmall'>{option.risk}</Text>
              </Box>
            </Box>
          )
        }}
      </RadioButtonGroup>
    </>),
  () => (
    <FormField
      label='Other Notes'
      htmlFor='addiction2OtherNotes'
      name='addiction2OtherNotes'
    >
      <TextArea
        id='addiction2OtherNotes'
        name='addiction2OtherNotes'
      />
    </FormField>
  )
]

// Disability 1 of 4
const stepSevenOptions = [
  {
    value: 'disability1_Unmet_care_needs',
    label: 'Unmet care needs',
    risk: 'Safeguarding'
  },
  {
    value: 'disability1_Significant_physical_disability',
    label: 'Significant physical disability',
    risk: 'High Complexity'
  },
  {
    value: 'disability1_Learning_Disability',
    label: 'Learning Disability',
    risk: 'Medium Complexity'
  },
  {
    value: 'disability1_Unconfirmed_or_suspected_learning_disability',
    label: 'Unconfirmed or suspected learning disability',
    risk: 'Low Complexity'
  },
  {
    value: 'disability1_No_disability',
    label: 'No disability',
    risk: 'Independent'
  }
]

const stepSevenInputs = [
  (setValid) => (
    <>
      <RadioButtonGroup
        name='disability1'
        options={stepSevenOptions}
        onChange={(event) => updateAnswer(event.target, stepSevenOptions, 'disability', 'disability1')}
      >
        {(option, { checked, hover }) => {
          let background
          if (checked) background = riskColorLookup[option.risk]
          else if (hover) background = riskColorLookup[option.risk]
          else background = 'light-4'
          return (
            <Box direction='row'>
              <Box
                alignSelf='center'
                background={background}
                round
                width='20px'
                height='20px'
              />
              <Box direction='column' margin={{ left: 'small' }}>
                <Text>{option.label}</Text>
                <Text color={riskColorLookup[option.risk]} hidden={!checked} size='xsmall'>{option.risk}</Text>
              </Box>
            </Box>
          )
        }}
      </RadioButtonGroup>
    </>),
  () => (
    <FormField
      label='Other Notes'
      htmlFor='disability1OtherNotes'
      name='disability1OtherNotes'
    >
      <TextArea
        id='disability1OtherNotes'
        name='disability1OtherNotes'
      />
    </FormField>
  )
]

// Disability 2 of 4
const stepEightOptions = [
  {
    value: 'disability2_Sensory_disability',
    label: 'Sensory disability',
    risk: 'Medium Complexity'
  }
]

const stepEightInputs = [
  (setValid) => (
    <>
      <RadioButtonGroup
        name='disability2'
        options={stepEightOptions}
        onChange={(event) => updateAnswer(event.target, stepEightOptions, 'disability', 'disability2')}
      >
        {(option, { checked, hover }) => {
          let background
          if (checked) background = riskColorLookup[option.risk]
          else if (hover) background = riskColorLookup[option.risk]
          else background = 'light-4'
          return (
            <Box direction='row'>
              <Box
                alignSelf='center'
                background={background}
                round
                width='20px'
                height='20px'
              />
              <Box direction='column' margin={{ left: 'small' }}>
                <Text>{option.label}</Text>
                <Text color={riskColorLookup[option.risk]} hidden={!checked} size='xsmall'>{option.risk}</Text>
              </Box>
            </Box>
          )
        }}
      </RadioButtonGroup>
    </>),
  () => (
    <FormField
      label='Other Notes'
      htmlFor='disability2OtherNotes'
      name='disability2OtherNotes'
    >
      <TextArea
        id='disability2OtherNotes'
        name='disability2OtherNotes'
      />
    </FormField>
  )
]

// Disability 3 of 4
const stepNineOptions = [
  {
    value: 'disability3_Child_with_a_disability',
    label: 'Child with a disability',
    risk: 'Medium Complexity'
  }
]

const stepNineInputs = [
  (setValid) => (
    <>
      <RadioButtonGroup
        name='disability3'
        options={stepNineOptions}
        onChange={(event) => updateAnswer(event.target, stepNineOptions, 'disability', 'disability3')}
      >
        {(option, { checked, hover }) => {
          let background
          if (checked) background = riskColorLookup[option.risk]
          else if (hover) background = riskColorLookup[option.risk]
          else background = 'light-4'
          return (
            <Box direction='row'>
              <Box
                alignSelf='center'
                background={background}
                round
                width='20px'
                height='20px'
              />
              <Box direction='column' margin={{ left: 'small' }}>
                <Text>{option.label}</Text>
                <Text color={riskColorLookup[option.risk]} hidden={!checked} size='xsmall'>{option.risk}</Text>
              </Box>
            </Box>
          )
        }}
      </RadioButtonGroup>
    </>),
  () => (
    <FormField
      label='Other Notes'
      htmlFor='disability3OtherNotes'
      name='disability3OtherNotes'
    >
      <TextArea
        id='disability3OtherNotes'
        name='disability3OtherNotes'
      />
    </FormField>
  )
]

// Disability 4 of 4
const stepTenOptions = [
  {
    value: 'disability4_Physical_Disability',
    label: 'Physical Disability',
    risk: 'Medium Complexity'
  }
]

const stepTenInputs = [
  (setValid) => (
    <>
      <RadioButtonGroup
        name='disability4'
        options={stepTenOptions}
        onChange={(event) => updateAnswer(event.target, stepTenOptions, 'disability', 'disability4')}
      >
        {(option, { checked, hover }) => {
          let background
          if (checked) background = riskColorLookup[option.risk]
          else if (hover) background = riskColorLookup[option.risk]
          else background = 'light-4'
          return (
            <Box direction='row'>
              <Box
                alignSelf='center'
                background={background}
                round
                width='20px'
                height='20px'
              />
              <Box direction='column' margin={{ left: 'small' }}>
                <Text>{option.label}</Text>
                <Text color={riskColorLookup[option.risk]} hidden={!checked} size='xsmall'>{option.risk}</Text>
              </Box>
            </Box>
          )
        }}
      </RadioButtonGroup>
    </>),
  () => (
    <FormField
      label='Other Notes'
      htmlFor='disability4OtherNotes'
      name='disability4OtherNotes'
    >
      <TextArea
        id='disability4OtherNotes'
        name='disability4OtherNotes'
      />
    </FormField>
  )
]

// Finances 1 of 6
const stepElevenOptions = [
  {
    value: 'finances1_Financial_Exploitation_or_abuse',
    label: 'Financial Exploitation or abuse',
    risk: 'Safeguarding'
  },
  {
    value: 'finances1_Large_arrears_or_debt_to_council',
    label: 'Large arrears or debt to council',
    risk: 'High Complexity'
  },
  {
    value: 'finances1_Section_4_support',
    label: 'Section 4 support',
    risk: 'Medium Complexity'
  },
  {
    value: 'finances1_Section_95_support',
    label: 'Section 95 support',
    risk: 'Low Complexity'
  },
  {
    value: 'finances1_Permanent_job_stable_income',
    label: 'Permanent job/stable income',
    risk: 'Independent'
  }
]

const stepElevenInputs = [
  (setValid) => (
    <>
      <RadioButtonGroup
        name='finances1'
        options={stepElevenOptions}
        onChange={(event) => updateAnswer(event.target, stepElevenOptions, 'finances', 'finances1')}
      >
        {(option, { checked, hover }) => {
          let background
          if (checked) background = riskColorLookup[option.risk]
          else if (hover) background = riskColorLookup[option.risk]
          else background = 'light-4'
          return (
            <Box direction='row'>
              <Box
                alignSelf='center'
                background={background}
                round
                width='20px'
                height='20px'
              />
              <Box direction='column' margin={{ left: 'small' }}>
                <Text>{option.label}</Text>
                <Text color={riskColorLookup[option.risk]} hidden={!checked} size='xsmall'>{option.risk}</Text>
              </Box>
            </Box>
          )
        }}
      </RadioButtonGroup>
    </>),
  () => (
    <FormField
      label='Other Notes'
      htmlFor='finances1OtherNotes'
      name='finances1OtherNotes'
    >
      <TextArea
        id='finances1OtherNotes'
        name='finances1OtherNotes'
      />
    </FormField>
  )
]

// Finances 2 of 6
const stepTwelveOptions = [
  {
    value: 'finances2_Large_fine_repayment',
    label: 'Large fine repayment',
    risk: 'High Complexity'
  },
  {
    value: 'finances2_Unstable_or_inconsistent_income_through_employment',
    label: 'Unstable or inconsistent income through employment',
    risk: 'Medium Complexity'
  },
  {
    value: 'finances2_DWP_Benefits_and_HB_able_to_understand_correspondence_and_rules_around_benefits',
    label: 'DWP Benefits and HB + able to understand correspondence and rules around benefits',
    risk: 'Low Complexity'
  },
  {
    value: 'finances2_Able_to_manage_budget_and_live_within_means',
    label: 'Able to manage budget and live within means',
    risk: 'Independent'
  }
]

const stepTwelveInputs = [
  (setValid) => (
    <>
      <RadioButtonGroup
        name='finances2'
        options={stepTwelveOptions}
        onChange={(event) => updateAnswer(event.target, stepTwelveOptions, 'finances', 'finances2')}
      >
        {(option, { checked, hover }) => {
          let background
          if (checked) background = riskColorLookup[option.risk]
          else if (hover) background = riskColorLookup[option.risk]
          else background = 'light-4'
          return (
            <Box direction='row'>
              <Box
                alignSelf='center'
                background={background}
                round
                width='20px'
                height='20px'
              />
              <Box direction='column' margin={{ left: 'small' }}>
                <Text>{option.label}</Text>
                <Text color={riskColorLookup[option.risk]} hidden={!checked} size='xsmall'>{option.risk}</Text>
              </Box>
            </Box>
          )
        }}
      </RadioButtonGroup>
    </>),
  () => (
    <FormField
      label='Other Notes'
      htmlFor='finances2OtherNotes'
      name='finances2OtherNotes'
    >
      <TextArea
        id='finances2OtherNotes'
        name='finances2OtherNotes'
      />
    </FormField>
  )
]

// Finances 3 of 6
const stepThirteenOptions = [
  {
    value: 'finances3_Consistent_poor_budget_management',
    label: 'Consistent poor budget management',
    risk: 'High Complexity'
  },
  {
    value: 'finances3_Risk_of_unemployment',
    label: 'Risk of unemployment',
    risk: 'Medium Complexity'
  }
]

const stepThirteenInputs = [
  (setValid) => (
    <>
      <RadioButtonGroup
        name='finances3'
        options={stepThirteenOptions}
        onChange={(event) => updateAnswer(event.target, stepThirteenOptions, 'finances', 'finances3')}
      >
        {(option, { checked, hover }) => {
          let background
          if (checked) background = riskColorLookup[option.risk]
          else if (hover) background = riskColorLookup[option.risk]
          else background = 'light-4'
          return (
            <Box direction='row'>
              <Box
                alignSelf='center'
                background={background}
                round
                width='20px'
                height='20px'
              />
              <Box direction='column' margin={{ left: 'small' }}>
                <Text>{option.label}</Text>
                <Text color={riskColorLookup[option.risk]} hidden={!checked} size='xsmall'>{option.risk}</Text>
              </Box>
            </Box>
          )
        }}
      </RadioButtonGroup>
    </>),
  () => (
    <FormField
      label='Other Notes'
      htmlFor='finances3OtherNotes'
      name='finances3OtherNotes'
    >
      <TextArea
        id='finances3OtherNotes'
        name='finances3OtherNotes'
      />
    </FormField>
  )
]

// Finances 4 of 6
const stepFourteenOptions = [
  {
    value: 'finances4_New_Universal_Credit_Claim',
    label: 'New Universal Credit Claim',
    risk: 'High Complexity'
  },
  {
    value: 'finances4_DWP_HB_but_struggles_to_understand_rules_or_correspondence',
    label: 'DWP & HB but struggles to understand rules or correspondence',
    risk: 'Medium Complexity'
  }
]

const stepFourteenInputs = [
  (setValid) => (
    <>
      <RadioButtonGroup
        name='finances4'
        options={stepFourteenOptions}
        onChange={(event) => updateAnswer(event.target, stepFourteenOptions, 'finances', 'finances4')}
      >
        {(option, { checked, hover }) => {
          let background
          if (checked) background = riskColorLookup[option.risk]
          else if (hover) background = riskColorLookup[option.risk]
          else background = 'light-4'
          return (
            <Box direction='row'>
              <Box
                alignSelf='center'
                background={background}
                round
                width='20px'
                height='20px'
              />
              <Box direction='column' margin={{ left: 'small' }}>
                <Text>{option.label}</Text>
                <Text color={riskColorLookup[option.risk]} hidden={!checked} size='xsmall'>{option.risk}</Text>
              </Box>
            </Box>
          )
        }}
      </RadioButtonGroup>
    </>),
  () => (
    <FormField
      label='Other Notes'
      htmlFor='finances4OtherNotes'
      name='finances4OtherNotes'
    >
      <TextArea
        id='finances4OtherNotes'
        name='finances4OtherNotes'
      />
    </FormField>
  )
]

// Finances 5 of 6
const stepFifteenOptions = [
  {
    value: 'finances5_Vulnerable_employment',
    label: 'Vulnerable employment',
    risk: 'High Complexity'
  }
]

const stepFifteenInputs = [
  (setValid) => (
    <>
      <RadioButtonGroup
        name='finances5'
        options={stepFifteenOptions}
        onChange={(event) => updateAnswer(event.target, stepFifteenOptions, 'finances', 'finances5')}
      >
        {(option, { checked, hover }) => {
          let background
          if (checked) background = riskColorLookup[option.risk]
          else if (hover) background = riskColorLookup[option.risk]
          else background = 'light-4'
          return (
            <Box direction='row'>
              <Box
                alignSelf='center'
                background={background}
                round
                width='20px'
                height='20px'
              />
              <Box direction='column' margin={{ left: 'small' }}>
                <Text>{option.label}</Text>
                <Text color={riskColorLookup[option.risk]} hidden={!checked} size='xsmall'>{option.risk}</Text>
              </Box>
            </Box>
          )
        }}
      </RadioButtonGroup>
    </>),
  () => (
    <FormField
      label='Other Notes'
      htmlFor='finances5OtherNotes'
      name='finances5OtherNotes'
    >
      <TextArea
        id='finances5OtherNotes'
        name='finances5OtherNotes'
      />
    </FormField>
  )
]

// Finances 6 of 6
const stepSixteenOptions = [
  {
    value: 'finances6_Section_17_support',
    label: 'Section 17 support.',
    risk: 'High Complexity'
  }
]

const stepSixteenInputs = [
  (setValid) => (
    <>
      <RadioButtonGroup
        name='finances6'
        options={stepSixteenOptions}
        onChange={(event) => updateAnswer(event.target, stepSixteenOptions, 'finances', 'finances6')}
      >
        {(option, { checked, hover }) => {
          let background
          if (checked) background = riskColorLookup[option.risk]
          else if (hover) background = riskColorLookup[option.risk]
          else background = 'light-4'
          return (
            <Box direction='row'>
              <Box
                alignSelf='center'
                background={background}
                round
                width='20px'
                height='20px'
              />
              <Box direction='column' margin={{ left: 'small' }}>
                <Text>{option.label}</Text>
                <Text color={riskColorLookup[option.risk]} hidden={!checked} size='xsmall'>{option.risk}</Text>
              </Box>
            </Box>
          )
        }}
      </RadioButtonGroup>
    </>),
  () => (
    <FormField
      label='Other Notes'
      htmlFor='finances6OtherNotes'
      name='finances6OtherNotes'
    >
      <TextArea
        id='finances6OtherNotes'
        name='finances6OtherNotes'
      />
    </FormField>
  )
]

// Language 1 of 3
const stepSeventeenOptions = [
  {
    value: 'language1_Language_controlled_by_another',
    label: 'Language controlled by another',
    risk: 'Safeguarding'
  },
  {
    value: 'language1_No_spoken_or_written_English_and_not_accessing_classes',
    label: 'No spoken or written English and not accessing classes',
    risk: 'High Complexity'
  },
  {
    value: 'language1_Illiterate_but_with_spoken_English',
    label: 'Illiterate but with spoken English',
    risk: 'Medium Complexity'
  },
  {
    value: 'language1_English_steadily_improving',
    label: 'English steadily improving',
    risk: 'Low Complexity'
  },
  {
    value: 'language1_Fluent',
    label: 'Fluent',
    risk: 'Independent'
  }
]

const stepSeventeenInputs = [
  (setValid) => (
    <>
      <RadioButtonGroup
        name='language1'
        options={stepSeventeenOptions}
        onChange={(event) => updateAnswer(event.target, stepSeventeenOptions, 'language', 'language1')}
      >
        {(option, { checked, hover }) => {
          let background
          if (checked) background = riskColorLookup[option.risk]
          else if (hover) background = riskColorLookup[option.risk]
          else background = 'light-4'
          return (
            <Box direction='row'>
              <Box
                alignSelf='center'
                background={background}
                round
                width='20px'
                height='20px'
              />
              <Box direction='column' margin={{ left: 'small' }}>
                <Text>{option.label}</Text>
                <Text color={riskColorLookup[option.risk]} hidden={!checked} size='xsmall'>{option.risk}</Text>
              </Box>
            </Box>
          )
        }}
      </RadioButtonGroup>
    </>),
  () => (
    <FormField
      label='Other Notes'
      htmlFor='language1OtherNotes'
      name='language1OtherNotes'
    >
      <TextArea
        id='language1OtherNotes'
        name='language1OtherNotes'
      />
    </FormField>
  )
]

// Language 2 of 3
const stepEighteenOptions = [
  {
    value: 'language2_Little_spoken_English',
    label: 'Little spoken English',
    risk: 'Medium Complexity'
  },
  {
    value: 'language2_Attending_regular_English_classes',
    label: 'Attending regular English classes',
    risk: 'Low Complexity'
  },
  {
    value: 'language2_Confident_in_English_language',
    label: 'Confident in English language',
    risk: 'Independent'
  }
]

const stepEighteenInputs = [
  (setValid) => (
    <>
      <RadioButtonGroup
        name='language2'
        options={stepEighteenOptions}
        onChange={(event) => updateAnswer(event.target, stepEighteenOptions, 'language', 'language2')}
      >
        {(option, { checked, hover }) => {
          let background
          if (checked) background = riskColorLookup[option.risk]
          else if (hover) background = riskColorLookup[option.risk]
          else background = 'light-4'
          return (
            <Box direction='row'>
              <Box
                alignSelf='center'
                background={background}
                round
                width='20px'
                height='20px'
              />
              <Box direction='column' margin={{ left: 'small' }}>
                <Text>{option.label}</Text>
                <Text color={riskColorLookup[option.risk]} hidden={!checked} size='xsmall'>{option.risk}</Text>
              </Box>
            </Box>
          )
        }}
      </RadioButtonGroup>
    </>),
  () => (
    <FormField
      label='Other Notes'
      htmlFor='language2OtherNotes'
      name='language2OtherNotes'
    >
      <TextArea
        id='language2OtherNotes'
        name='language2OtherNotes'
      />
    </FormField>
  )
]

// Language 3 of 3
const stepNineteenOptions = [
  {
    value: 'language3_No_classes_but_struggles_to_understand_complex_language',
    label: 'No classes but struggles to understand complex language',
    risk: 'Medium Complexity'
  }
]

const stepNineteenInputs = [
  (setValid) => (
    <>
      <RadioButtonGroup
        name='language3'
        options={stepNineteenOptions}
        onChange={(event) => updateAnswer(event.target, stepNineteenOptions, 'language', 'language3')}
      >
        {(option, { checked, hover }) => {
          let background
          if (checked) background = riskColorLookup[option.risk]
          else if (hover) background = riskColorLookup[option.risk]
          else background = 'light-4'
          return (
            <Box direction='row'>
              <Box
                alignSelf='center'
                background={background}
                round
                width='20px'
                height='20px'
              />
              <Box direction='column' margin={{ left: 'small' }}>
                <Text>{option.label}</Text>
                <Text color={riskColorLookup[option.risk]} hidden={!checked} size='xsmall'>{option.risk}</Text>
              </Box>
            </Box>
          )
        }}
      </RadioButtonGroup>
    </>),
  () => (
    <FormField
      label='Other Notes'
      htmlFor='language3OtherNotes'
      name='language3OtherNotes'
    >
      <TextArea
        id='language3OtherNotes'
        name='language3OtherNotes'
      />
    </FormField>
  )
]

// Legal 1 of 7
const stepTwentyOptions = [
  {
    value: 'legal1_Detained_and_at_risk_of_deportation',
    label: 'Detained and at risk of deportation',
    risk: 'Safeguarding'
  },
  {
    value: 'legal1_Challengeable_negative_CG',
    label: 'Challengeable negative CG',
    risk: 'High Complexity'
  },
  {
    value: 'legal1_Failed_Asylum_seeker_section_4',
    label: 'Failed Asylum seeker (section 4)',
    risk: 'Medium Complexity'
  },
  {
    value: 'legal1_Asylum_Seeker',
    label: 'Asylum Seeker',
    risk: 'Low Complexity'
  },
  {
    value: 'legal1_Refugee_Status_or_British_Citizen',
    label: 'Refugee Status or British Citizen',
    risk: 'Independent'
  }
]

const stepTwentyInputs = [
  (setValid) => (
    <>
      <RadioButtonGroup
        name='legal1'
        options={stepTwentyOptions}
        onChange={(event) => updateAnswer(event.target, stepTwentyOptions, 'legal', 'legal1')}
      >
        {(option, { checked, hover }) => {
          let background
          if (checked) background = riskColorLookup[option.risk]
          else if (hover) background = riskColorLookup[option.risk]
          else background = 'light-4'
          return (
            <Box direction='row'>
              <Box
                alignSelf='center'
                background={background}
                round
                width='20px'
                height='20px'
              />
              <Box direction='column' margin={{ left: 'small' }}>
                <Text>{option.label}</Text>
                <Text color={riskColorLookup[option.risk]} hidden={!checked} size='xsmall'>{option.risk}</Text>
              </Box>
            </Box>
          )
        }}
      </RadioButtonGroup>
    </>),
  () => (
    <FormField
      label='Other Notes'
      htmlFor='legal1OtherNotes'
      name='legal1OtherNotes'
    >
      <TextArea
        id='legal1OtherNotes'
        name='legal1OtherNotes'
      />
    </FormField>
  )
]

// Legal 2 of 7
const stepTwentyOneOptions = [
  {
    value: 'legal2_In_prison_or_under_arrest',
    label: 'In prison or under arrest',
    risk: 'Safeguarding'
  },
  {
    value: 'legal2_Challenging_No_DL_with_CG',
    label: 'Challenging No DL with +CG',
    risk: 'High Complexity'
  },
  {
    value: 'legal2_Open_Police_investigation',
    label: 'Open Police investigation',
    risk: 'Medium Complexity'
  },
  {
    value: 'legal2_Current_Discretionary_Leave',
    label: 'Current Discretionary Leave',
    risk: 'Low Complexity'
  }
]

const stepTwentyOneInputs = [
  (setValid) => (
    <>
      <RadioButtonGroup
        name='legal2'
        options={stepTwentyOneOptions}
        onChange={(event) => updateAnswer(event.target, stepTwentyOneOptions, 'legal', 'legal2')}
      >
        {(option, { checked, hover }) => {
          let background
          if (checked) background = riskColorLookup[option.risk]
          else if (hover) background = riskColorLookup[option.risk]
          else background = 'light-4'
          return (
            <Box direction='row'>
              <Box
                alignSelf='center'
                background={background}
                round
                width='20px'
                height='20px'
              />
              <Box direction='column' margin={{ left: 'small' }}>
                <Text>{option.label}</Text>
                <Text color={riskColorLookup[option.risk]} hidden={!checked} size='xsmall'>{option.risk}</Text>
              </Box>
            </Box>
          )
        }}
      </RadioButtonGroup>
    </>),
  () => (
    <FormField
      label='Other Notes'
      htmlFor='legal2OtherNotes'
      name='legal2OtherNotes'
    >
      <TextArea
        id='legal2OtherNotes'
        name='legal2OtherNotes'
      />
    </FormField>
  )
]

// Legal 3 of 7
const stepTwentyTwoOptions = [
  {
    value: 'legal3_No_recourse_to_public_funds',
    label: 'No recourse to public funds',
    risk: 'High Complexity'
  },
  {
    value: 'legal3_Open_compensation_claim',
    label: 'Open compensation claim',
    risk: 'Medium Complexity'
  }
]

const stepTwentyTwoInputs = [
  (setValid) => (
    <>
      <RadioButtonGroup
        name='legal3'
        options={stepTwentyTwoOptions}
        onChange={(event) => updateAnswer(event.target, stepTwentyTwoOptions, 'legal', 'legal3')}
      >
        {(option, { checked, hover }) => {
          let background
          if (checked) background = riskColorLookup[option.risk]
          else if (hover) background = riskColorLookup[option.risk]
          else background = 'light-4'
          return (
            <Box direction='row'>
              <Box
                alignSelf='center'
                background={background}
                round
                width='20px'
                height='20px'
              />
              <Box direction='column' margin={{ left: 'small' }}>
                <Text>{option.label}</Text>
                <Text color={riskColorLookup[option.risk]} hidden={!checked} size='xsmall'>{option.risk}</Text>
              </Box>
            </Box>
          )
        }}
      </RadioButtonGroup>
    </>),
  () => (
    <FormField
      label='Other Notes'
      htmlFor='legal3OtherNotes'
      name='legal3OtherNotes'
    >
      <TextArea
        id='legal3OtherNotes'
        name='legal3OtherNotes'
      />
    </FormField>
  )
]

// Legal 4 of 7
const stepTwentyThreeOptions = [
  {
    value: 'legal4_Failed_Asylum_seeker_destitute',
    label: 'Failed Asylum seeker (destitute)',
    risk: 'High Complexity'
  },
  {
    value: 'legal4_Criminal_record',
    label: 'Criminal record',
    risk: 'Medium Complexity'
  }
]

const stepTwentyThreeInputs = [
  (setValid) => (
    <>
      <RadioButtonGroup
        name='legal4'
        options={stepTwentyThreeOptions}
        onChange={(event) => updateAnswer(event.target, stepTwentyThreeOptions, 'legal', 'legal4')}
      >
        {(option, { checked, hover }) => {
          let background
          if (checked) background = riskColorLookup[option.risk]
          else if (hover) background = riskColorLookup[option.risk]
          else background = 'light-4'
          return (
            <Box direction='row'>
              <Box
                alignSelf='center'
                background={background}
                round
                width='20px'
                height='20px'
              />
              <Box direction='column' margin={{ left: 'small' }}>
                <Text>{option.label}</Text>
                <Text color={riskColorLookup[option.risk]} hidden={!checked} size='xsmall'>{option.risk}</Text>
              </Box>
            </Box>
          )
        }}
      </RadioButtonGroup>
    </>),
  () => (
    <FormField
      label='Other Notes'
      htmlFor='legal4OtherNotes'
      name='legal4OtherNotes'
    >
      <TextArea
        id='legal4OtherNotes'
        name='legal4OtherNotes'
      />
    </FormField>
  )
]

// Legal 5 of 7
const stepTwentyFourOptions = [
  {
    value: 'legal5_Preparing_to_testify_in_court_case',
    label: 'Preparing to testify in court case',
    risk: 'High Complexity'
  },
  {
    value: 'legal5_Family_reunion_process',
    label: 'Family reunion process',
    risk: 'Medium Complexity'
  }
]

const stepTwentyFourInputs = [
  (setValid) => (
    <>
      <RadioButtonGroup
        name='legal5'
        options={stepTwentyFourOptions}
        onChange={(event) => updateAnswer(event.target, stepTwentyFourOptions, 'legal', 'legal5')}
      >
        {(option, { checked, hover }) => {
          let background
          if (checked) background = riskColorLookup[option.risk]
          else if (hover) background = riskColorLookup[option.risk]
          else background = 'light-4'
          return (
            <Box direction='row'>
              <Box
                alignSelf='center'
                background={background}
                round
                width='20px'
                height='20px'
              />
              <Box direction='column' margin={{ left: 'small' }}>
                <Text>{option.label}</Text>
                <Text color={riskColorLookup[option.risk]} hidden={!checked} size='xsmall'>{option.risk}</Text>
              </Box>
            </Box>
          )
        }}
      </RadioButtonGroup>
    </>),
  () => (
    <FormField
      label='Other Notes'
      htmlFor='legal5OtherNotes'
      name='legal5OtherNotes'
    >
      <TextArea
        id='legal5OtherNotes'
        name='legal5OtherNotes'
      />
    </FormField>
  )
]

// Legal 6 of 7
const stepTwentyFiveOptions = [
  {
    value: 'legal6_No_access_to_solicitor',
    label: 'No access to solicitor',
    risk: 'High Complexity'
  },
  {
    value: 'legal6_Awaiting_DL',
    label: 'Awaiting DL',
    risk: 'Medium Complexity'
  }
]

const stepTwentyFiveInputs = [
  (setValid) => (
    <>
      <RadioButtonGroup
        name='legal6'
        options={stepTwentyFiveOptions}
        onChange={(event) => updateAnswer(event.target, stepTwentyFiveOptions, 'legal', 'legal6')}
      >
        {(option, { checked, hover }) => {
          let background
          if (checked) background = riskColorLookup[option.risk]
          else if (hover) background = riskColorLookup[option.risk]
          else background = 'light-4'
          return (
            <Box direction='row'>
              <Box
                alignSelf='center'
                background={background}
                round
                width='20px'
                height='20px'
              />
              <Box direction='column' margin={{ left: 'small' }}>
                <Text>{option.label}</Text>
                <Text color={riskColorLookup[option.risk]} hidden={!checked} size='xsmall'>{option.risk}</Text>
              </Box>
            </Box>
          )
        }}
      </RadioButtonGroup>
    </>),
  () => (
    <FormField
      label='Other Notes'
      htmlFor='legal6OtherNotes'
      name='legal6OtherNotes'
    >
      <TextArea
        id='legal6OtherNotes'
        name='legal6OtherNotes'
      />
    </FormField>
  )
]

// Legal 7 of 7
const stepTwentySixOptions = [
  {
    value: 'legal7_Imminent_Immigration_deadline',
    label: 'Imminent Immigration deadline',
    risk: 'High Complexity'
  },
  {
    value: 'legal7_Awaiting_BRP_card',
    label: 'Awaiting BRP card',
    risk: 'Medium Complexity'
  }
]

const stepTwentySixInputs = [
  (setValid) => (
    <>
      <RadioButtonGroup
        name='legal7'
        options={stepTwentySixOptions}
        onChange={(event) => updateAnswer(event.target, stepTwentySixOptions, 'legal', 'legal7')}
      >
        {(option, { checked, hover }) => {
          let background
          if (checked) background = riskColorLookup[option.risk]
          else if (hover) background = riskColorLookup[option.risk]
          else background = 'light-4'
          return (
            <Box direction='row'>
              <Box
                alignSelf='center'
                background={background}
                round
                width='20px'
                height='20px'
              />
              <Box direction='column' margin={{ left: 'small' }}>
                <Text>{option.label}</Text>
                <Text color={riskColorLookup[option.risk]} hidden={!checked} size='xsmall'>{option.risk}</Text>
              </Box>
            </Box>
          )
        }}
      </RadioButtonGroup>
    </>),
  () => (
    <FormField
      label='Other Notes'
      htmlFor='legal7OtherNotes'
      name='legal7OtherNotes'
    >
      <TextArea
        id='legal7OtherNotes'
        name='legal7OtherNotes'
      />
    </FormField>
  )
]

// Mental 1 of 4
const stepTwentySevenOptions = [
  {
    value: 'mental1_Actively_Suicidal',
    label: 'Actively Suicidal',
    risk: 'Safeguarding'
  },
  {
    value: 'mental1_Diagnosed_complex_psychiatric_disorder',
    label: 'Diagnosed complex psychiatric disorder',
    risk: 'High Complexity'
  },
  {
    value: 'mental1_Mental_Health_problems_without_mental_health_support',
    label: 'Mental Health problems without mental health support',
    risk: 'Medium Complexity'
  },
  {
    value: 'mental1_Currently_receiving_mental_health_support',
    label: 'Currently receiving mental health support',
    risk: 'Low Complexity'
  },
  {
    value: 'mental1_Able_to_independently_access_mental_health_services_if_needed',
    label: 'Able to independently access mental health services if needed',
    risk: 'Independent'
  }
]

const stepTwentySevenInputs = [
  (setValid) => (
    <>
      <RadioButtonGroup
        name='mental1'
        options={stepTwentySevenOptions}
        onChange={(event) => updateAnswer(event.target, stepTwentySevenOptions, 'mental', 'mental1')}
      >
        {(option, { checked, hover }) => {
          let background
          if (checked) background = riskColorLookup[option.risk]
          else if (hover) background = riskColorLookup[option.risk]
          else background = 'light-4'
          return (
            <Box direction='row'>
              <Box
                alignSelf='center'
                background={background}
                round
                width='20px'
                height='20px'
              />
              <Box direction='column' margin={{ left: 'small' }}>
                <Text>{option.label}</Text>
                <Text color={riskColorLookup[option.risk]} hidden={!checked} size='xsmall'>{option.risk}</Text>
              </Box>
            </Box>
          )
        }}
      </RadioButtonGroup>
    </>),
  () => (
    <FormField
      label='Other Notes'
      htmlFor='mental1OtherNotes'
      name='mental1OtherNotes'
    >
      <TextArea
        id='mental1OtherNotes'
        name='mental1OtherNotes'
      />
    </FormField>
  )
]

// Mental 2 of 4
const stepTwentyEightOptions = [
  {
    value: 'mental2_Risk_to_self_others',
    label: 'Risk to self/others',
    risk: 'Safeguarding'
  },
  {
    value: 'mental2_Suicidal_ideation',
    label: 'Suicidal ideation',
    risk: 'High Complexity'
  },
  {
    value: 'mental2_Occasional_self_harming_behaviour',
    label: 'Occasional self-harming behaviour',
    risk: 'Medium Complexity'
  },
  {
    value: 'mental2_Sometimes_struggles_with_low_mood_MH',
    label: 'Sometimes struggles with low mood/MH',
    risk: 'Low Complexity'
  }
]

const stepTwentyEightInputs = [
  (setValid) => (
    <>
      <RadioButtonGroup
        name='mental2'
        options={stepTwentyEightOptions}
        onChange={(event) => updateAnswer(event.target, stepTwentyEightOptions, 'mental', 'mental2')}
      >
        {(option, { checked, hover }) => {
          let background
          if (checked) background = riskColorLookup[option.risk]
          else if (hover) background = riskColorLookup[option.risk]
          else background = 'light-4'
          return (
            <Box direction='row'>
              <Box
                alignSelf='center'
                background={background}
                round
                width='20px'
                height='20px'
              />
              <Box direction='column' margin={{ left: 'small' }}>
                <Text>{option.label}</Text>
                <Text color={riskColorLookup[option.risk]} hidden={!checked} size='xsmall'>{option.risk}</Text>
              </Box>
            </Box>
          )
        }}
      </RadioButtonGroup>
    </>),
  () => (
    <FormField
      label='Other Notes'
      htmlFor='mental2OtherNotes'
      name='mental2OtherNotes'
    >
      <TextArea
        id='mental2OtherNotes'
        name='mental2OtherNotes'
      />
    </FormField>
  )
]

// Mental 3 of 4
const stepTwentyNineOptions = [
  {
    value: 'mental3_Unable_to_adequately_care_for_children_due_to_mental_health_problems',
    label: 'Unable to adequately care for children due to mental health problems',
    risk: 'Safeguarding'
  },
  {
    value: 'mental3_Not_taking_prescribed_medication',
    label: 'Not taking prescribed medication',
    risk: 'High Complexity'
  },
  {
    value: 'mental3_Eating_disorder',
    label: 'Eating disorder',
    risk: 'Medium Complexity'
  }
]

const stepTwentyNineInputs = [
  (setValid) => (
    <>
      <RadioButtonGroup
        name='mental3'
        options={stepTwentyNineOptions}
        onChange={(event) => updateAnswer(event.target, stepTwentyNineOptions, 'mental', 'mental3')}
      >
        {(option, { checked, hover }) => {
          let background
          if (checked) background = riskColorLookup[option.risk]
          else if (hover) background = riskColorLookup[option.risk]
          else background = 'light-4'
          return (
            <Box direction='row'>
              <Box
                alignSelf='center'
                background={background}
                round
                width='20px'
                height='20px'
              />
              <Box direction='column' margin={{ left: 'small' }}>
                <Text>{option.label}</Text>
                <Text color={riskColorLookup[option.risk]} hidden={!checked} size='xsmall'>{option.risk}</Text>
              </Box>
            </Box>
          )
        }}
      </RadioButtonGroup>
    </>),
  () => (
    <FormField
      label='Other Notes'
      htmlFor='mental3OtherNotes'
      name='mental3OtherNotes'
    >
      <TextArea
        id='mental3OtherNotes'
        name='mental3OtherNotes'
      />
    </FormField>
  )
]

// Mental 4 of 4
const stepThirtyOptions = [
  {
    value: 'mental4_Regular_or_serious_self_harming_behaviour',
    label: 'Regular or serious self-harming behaviour',
    risk: 'High Complexity'
  }
]

const stepThirtyInputs = [
  (setValid) => (
    <>
      <RadioButtonGroup
        name='mental4'
        options={stepThirtyOptions}
        onChange={(event) => updateAnswer(event.target, stepThirtyOptions, 'mental', 'mental4')}
      >
        {(option, { checked, hover }) => {
          let background
          if (checked) background = riskColorLookup[option.risk]
          else if (hover) background = riskColorLookup[option.risk]
          else background = 'light-4'
          return (
            <Box direction='row'>
              <Box
                alignSelf='center'
                background={background}
                round
                width='20px'
                height='20px'
              />
              <Box direction='column' margin={{ left: 'small' }}>
                <Text>{option.label}</Text>
                <Text color={riskColorLookup[option.risk]} hidden={!checked} size='xsmall'>{option.risk}</Text>
              </Box>
            </Box>
          )
        }}
      </RadioButtonGroup>
    </>),
  () => (
    <FormField
      label='Other Notes'
      htmlFor='mental4OtherNotes'
      name='mental4OtherNotes'
    >
      <TextArea
        id='mental4OtherNotes'
        name='mental4OtherNotes'
      />
    </FormField>
  )
]

// Physical 1 of 3
const stepThirtyOneOptions = [
  {
    value: 'physical1_Palliative_Care',
    label: 'Palliative Care',
    risk: 'Safeguarding'
  },
  {
    value: 'physical1_Not_receiving_correct_treatment_for_health_problems',
    label: 'Not receiving correct treatment for health problems',
    risk: 'High Complexity'
  },
  {
    value: 'physical1_Chronic_physical_health_problem',
    label: 'Chronic physical health problem',
    risk: 'Medium Complexity'
  },
  {
    value: 'physical1_Needs_support_to_attend_health_appointments',
    label: 'Needs support to attend health appointments',
    risk: 'Low Complexity'
  },
  {
    value: 'physical1_Able_to_independently_access_health_appointments_when_needed',
    label: 'Able to independently access health appointments when needed',
    risk: 'Independent'
  }
]

const stepThirtyOneInputs = [
  (setValid) => (
    <>
      <RadioButtonGroup
        name='physical1'
        options={stepThirtyOneOptions}
        onChange={(event) => updateAnswer(event.target, stepThirtyOneOptions, 'physical', 'physical1')}
      >
        {(option, { checked, hover }) => {
          let background
          if (checked) background = riskColorLookup[option.risk]
          else if (hover) background = riskColorLookup[option.risk]
          else background = 'light-4'
          return (
            <Box direction='row'>
              <Box
                alignSelf='center'
                background={background}
                round
                width='20px'
                height='20px'
              />
              <Box direction='column' margin={{ left: 'small' }}>
                <Text>{option.label}</Text>
                <Text color={riskColorLookup[option.risk]} hidden={!checked} size='xsmall'>{option.risk}</Text>
              </Box>
            </Box>
          )
        }}
      </RadioButtonGroup>
    </>),
  () => (
    <FormField
      label='Other Notes'
      htmlFor='physical1OtherNotes'
      name='physical1OtherNotes'
    >
      <TextArea
        id='physical1OtherNotes'
        name='physical1OtherNotes'
      />
    </FormField>
  )
]

// Physical 2 of 3
const stepThirtyTwoOptions = [
  {
    value: 'physical2_Significant_unmet_care_needs',
    label: 'Significant unmet care needs',
    risk: 'Safeguarding'
  },
  {
    value: 'physical2_Struggling_to_access_appropriate_services',
    label: 'Struggling to access appropriate services',
    risk: 'High Complexity'
  },
  {
    value: 'physical2_Currently_undergoing_consultant_treatment',
    label: 'Currently undergoing consultant treatment',
    risk: 'Medium Complexity'
  }
]

const stepThirtyTwoInputs = [
  (setValid) => (
    <>
      <RadioButtonGroup
        name='physical2'
        options={stepThirtyTwoOptions}
        onChange={(event) => updateAnswer(event.target, stepThirtyTwoOptions, 'physical', 'physical2')}
      >
        {(option, { checked, hover }) => {
          let background
          if (checked) background = riskColorLookup[option.risk]
          else if (hover) background = riskColorLookup[option.risk]
          else background = 'light-4'
          return (
            <Box direction='row'>
              <Box
                alignSelf='center'
                background={background}
                round
                width='20px'
                height='20px'
              />
              <Box direction='column' margin={{ left: 'small' }}>
                <Text>{option.label}</Text>
                <Text color={riskColorLookup[option.risk]} hidden={!checked} size='xsmall'>{option.risk}</Text>
              </Box>
            </Box>
          )
        }}
      </RadioButtonGroup>
    </>),
  () => (
    <FormField
      label='Other Notes'
      htmlFor='physical2OtherNotes'
      name='physical2OtherNotes'
    >
      <TextArea
        id='physical2OtherNotes'
        name='physical2OtherNotes'
      />
    </FormField>
  )
]

// Physical 3 of 3
const stepThirtyThreeOptions = [
  {
    value: 'physical3_Pregnancy',
    label: 'Pregnancy',
    risk: 'Medium Complexity'
  }
]

const stepThirtyThreeInputs = [
  (setValid) => (
    <>
      <RadioButtonGroup
        name='physical3'
        options={stepThirtyThreeOptions}
        onChange={(event) => updateAnswer(event.target, stepThirtyThreeOptions, 'physical', 'physical3')}
      >
        {(option, { checked, hover }) => {
          let background
          if (checked) background = riskColorLookup[option.risk]
          else if (hover) background = riskColorLookup[option.risk]
          else background = 'light-4'
          return (
            <Box direction='row'>
              <Box
                alignSelf='center'
                background={background}
                round
                width='20px'
                height='20px'
              />
              <Box direction='column' margin={{ left: 'small' }}>
                <Text>{option.label}</Text>
                <Text color={riskColorLookup[option.risk]} hidden={!checked} size='xsmall'>{option.risk}</Text>
              </Box>
            </Box>
          )
        }}
      </RadioButtonGroup>
    </>),
  () => (
    <FormField
      label='Other Notes'
      htmlFor='physical3OtherNotes'
      name='physical3OtherNotes'
    >
      <TextArea
        id='physical3OtherNotes'
        name='physical3OtherNotes'
      />
    </FormField>
  )
]

// Relationship 1 of 5
const stepThirtyFourOptions = [
  {
    value: 'relationship1_Current_Active_Domestic_Violence_relationship_with_a_child',
    label: 'Current/Active Domestic Violence relationship (with a child)',
    risk: 'Safeguarding'
  },
  {
    value: 'relationship1_Caring_for_a_disabled_child',
    label: 'Caring for a disabled child',
    risk: 'High Complexity'
  },
  {
    value: 'relationship1_Unsafe_risky_relationships_with_family',
    label: 'Unsafe/risky relationships with family',
    risk: 'Medium Complexity'
  },
  {
    value: 'relationship1_Accessing_community_groups',
    label: 'Accessing community groups',
    risk: 'Low Complexity'
  },
  {
    value: 'relationship1_Ability_to_make_friends_and_know_healthy_from_unhealthy_relationships',
    label: 'Ability to make friends and know healthy from unhealthy relationships',
    risk: 'Independent'
  }
]

const stepThirtyFourInputs = [
  (setValid) => (
    <>
      <RadioButtonGroup
        name='relationship1'
        options={stepThirtyFourOptions}
        onChange={(event) => updateAnswer(event.target, stepThirtyFourOptions, 'relationship', 'relationship1')}
      >
        {(option, { checked, hover }) => {
          let background
          if (checked) background = riskColorLookup[option.risk]
          else if (hover) background = riskColorLookup[option.risk]
          else background = 'light-4'
          return (
            <Box direction='row'>
              <Box
                alignSelf='center'
                background={background}
                round
                width='20px'
                height='20px'
              />
              <Box direction='column' margin={{ left: 'small' }}>
                <Text>{option.label}</Text>
                <Text color={riskColorLookup[option.risk]} hidden={!checked} size='xsmall'>{option.risk}</Text>
              </Box>
            </Box>
          )
        }}
      </RadioButtonGroup>
    </>),
  () => (
    <FormField
      label='Other Notes'
      htmlFor='relationship1OtherNotes'
      name='relationship1OtherNotes'
    >
      <TextArea
        id='relationship1OtherNotes'
        name='relationship1OtherNotes'
      />
    </FormField>
  )
]

// Relationship 2 of 5
const stepThirtyFiveOptions = [
  {
    value: 'relationship2_Still_in_contact_involved_with_traffickers',
    label: 'Still in contact/involved with traffickers',
    risk: 'Safeguarding'
  },
  {
    value: 'relationship2_Caring_for_a_child_who_has_been_through_trauma',
    label: 'Caring for a child who has been through trauma',
    risk: 'High Complexity'
  },
  {
    value: 'relationship2_Potentially_exploitative_relationships',
    label: 'Potentially exploitative relationships',
    risk: 'Medium Complexity'
  },
  {
    value: 'relationship2_Learning_to_differentiate_healthy_from_unhealthy_friendships_and_relationship',
    label: 'Learning to differentiate healthy from unhealthy friendships and relationship',
    risk: 'Low Complexity'
  },
  {
    value: 'relationship2_Has_friends_and_community',
    label: 'Has friends and community',
    risk: 'Independent'
  }
]

const stepThirtyFiveInputs = [
  (setValid) => (
    <>
      <RadioButtonGroup
        name='relationship2'
        options={stepThirtyFiveOptions}
        onChange={(event) => updateAnswer(event.target, stepThirtyFiveOptions, 'relationship', 'relationship2')}
      >
        {(option, { checked, hover }) => {
          let background
          if (checked) background = riskColorLookup[option.risk]
          else if (hover) background = riskColorLookup[option.risk]
          else background = 'light-4'
          return (
            <Box direction='row'>
              <Box
                alignSelf='center'
                background={background}
                round
                width='20px'
                height='20px'
              />
              <Box direction='column' margin={{ left: 'small' }}>
                <Text>{option.label}</Text>
                <Text color={riskColorLookup[option.risk]} hidden={!checked} size='xsmall'>{option.risk}</Text>
              </Box>
            </Box>
          )
        }}
      </RadioButtonGroup>
    </>),
  () => (
    <FormField
      label='Other Notes'
      htmlFor='relationship2OtherNotes'
      name='relationship2OtherNotes'
    >
      <TextArea
        id='relationship2OtherNotes'
        name='relationship2OtherNotes'
      />
    </FormField>
  )
]

// Relationship 3 of 5
const stepThirtySixOptions = [
  {
    value: 'relationship3_Recently_left_DV_relationship',
    label: 'Recently left DV relationship',
    risk: 'High Complexity'
  },
  {
    value: 'relationship3_Children_overseas_or_family_reunion',
    label: 'Children overseas or family reunion',
    risk: 'Medium Complexity'
  },
  {
    value: 'relationship3_A_few_close_friends',
    label: 'A few close friends',
    risk: 'Low Complexity'
  }
]

const stepThirtySixInputs = [
  (setValid) => (
    <>
      <RadioButtonGroup
        name='relationship3'
        options={stepThirtySixOptions}
        onChange={(event) => updateAnswer(event.target, stepThirtySixOptions, 'relationship', 'relationship3')}
      >
        {(option, { checked, hover }) => {
          let background
          if (checked) background = riskColorLookup[option.risk]
          else if (hover) background = riskColorLookup[option.risk]
          else background = 'light-4'
          return (
            <Box direction='row'>
              <Box
                alignSelf='center'
                background={background}
                round
                width='20px'
                height='20px'
              />
              <Box direction='column' margin={{ left: 'small' }}>
                <Text>{option.label}</Text>
                <Text color={riskColorLookup[option.risk]} hidden={!checked} size='xsmall'>{option.risk}</Text>
              </Box>
            </Box>
          )
        }}
      </RadioButtonGroup>
    </>),
  () => (
    <FormField
      label='Other Notes'
      htmlFor='relationship3OtherNotes'
      name='relationship3OtherNotes'
    >
      <TextArea
        id='relationship3OtherNotes'
        name='relationship3OtherNotes'
      />
    </FormField>
  )
]

// Relationship 4 of 5
const stepThirtySevenOptions = [
  {
    value: 'relationship4_Disengaged_from_caseworker_and_community_group',
    label: 'Disengaged from caseworker and community group',
    risk: 'High Complexity'
  },
  {
    value: 'relationship4_Socially_isolated',
    label: 'Socially isolated',
    risk: 'Medium Complexity'
  }
]

const stepThirtySevenInputs = [
  (setValid) => (
    <>
      <RadioButtonGroup
        name='relationship4'
        options={stepThirtySevenOptions}
        onChange={(event) => updateAnswer(event.target, stepThirtySevenOptions, 'relationship', 'relationship4')}
      >
        {(option, { checked, hover }) => {
          let background
          if (checked) background = riskColorLookup[option.risk]
          else if (hover) background = riskColorLookup[option.risk]
          else background = 'light-4'
          return (
            <Box direction='row'>
              <Box
                alignSelf='center'
                background={background}
                round
                width='20px'
                height='20px'
              />
              <Box direction='column' margin={{ left: 'small' }}>
                <Text>{option.label}</Text>
                <Text color={riskColorLookup[option.risk]} hidden={!checked} size='xsmall'>{option.risk}</Text>
              </Box>
            </Box>
          )
        }}
      </RadioButtonGroup>
    </>),
  () => (
    <FormField
      label='Other Notes'
      htmlFor='relationship4OtherNotes'
      name='relationship4OtherNotes'
    >
      <TextArea
        id='relationship4OtherNotes'
        name='relationship4OtherNotes'
      />
    </FormField>
  )
]

// Relationship 5 of 5
const stepThirtyEightOptions = [
  {
    value: 'relationship5_Parenting_issues',
    label: 'Parenting issues',
    risk: 'High Complexity'
  }
]

const stepThirtyEightInputs = [
  (setValid) => (
    <>
      <RadioButtonGroup
        name='relationship5'
        options={stepThirtyEightOptions}
        onChange={(event) => updateAnswer(event.target, stepThirtyEightOptions, 'relationship', 'relationship5')}
      >
        {(option, { checked, hover }) => {
          let background
          if (checked) background = riskColorLookup[option.risk]
          else if (hover) background = riskColorLookup[option.risk]
          else background = 'light-4'
          return (
            <Box direction='row'>
              <Box
                alignSelf='center'
                background={background}
                round
                width='20px'
                height='20px'
              />
              <Box direction='column' margin={{ left: 'small' }}>
                <Text>{option.label}</Text>
                <Text color={riskColorLookup[option.risk]} hidden={!checked} size='xsmall'>{option.risk}</Text>
              </Box>
            </Box>
          )
        }}
      </RadioButtonGroup>
    </>),
  () => (
    <FormField
      label='Other Notes'
      htmlFor='relationship5OtherNotes'
      name='relationship5OtherNotes'
    >
      <TextArea
        id='relationship5OtherNotes'
        name='relationship5OtherNotes'
      />
    </FormField>
  )
]

const StepOne = () => {
  const { valid, setValid } = useContext(RiskContext)
  const size = useContext(ResponsiveContext)

  return (
    <>
      <Grid
        columns={size !== 'small' ? { count: 'fit', size: 'medium' } : '100%'}
        gap={size !== 'small' ? 'large' : undefined}
        margin={{ bottom: 'medium' }}
      >
        {stepOneInputs.map((input, index) => (
          <Box width={{ max: 'medium' }} key={index}>
            {input(setValid)}
          </Box>
        ))}
      </Grid>
      {/* {!valid && (
        <Error>There is an error with one or more inputs.</Error>
      )} */}
    </>
  )
}

const DisplayStep = (props) => {
  const { valid, setValid } = useContext(RiskContext)
  const size = useContext(ResponsiveContext)

  return (
    <>
      {/* <Grid
        columns={size !== 'small' ? { count: 'fit', size: 'medium' } : '100%'}
        gap={size !== 'small' ? 'large' : undefined}
        margin={{ bottom: 'medium' }}
      > */}
      <Box direction='row-responsive' justify='between'>
        {props.input.map((input, index) => (
          <Box width={{ max: 'medium' }} key={index}>
            {input(setValid)}
          </Box>
        ))}
      </Box>
      {/* </Grid> */}
      {/* {!valid && (
          <Error>There is an error with one or more inputs.</Error>
        )} */}
    </>
  )
}

// const StepTwo = () => {
//   const { valid, setValid } = useContext(RiskContext)
//   const size = useContext(ResponsiveContext)

//   return (
//     <>
//       {/* <Grid
//         columns={size !== 'small' ? { count: 'fit', size: 'medium' } : '100%'}
//         gap={size !== 'small' ? 'large' : undefined}
//         margin={{ bottom: 'medium' }}
//       > */}
//       <Box direction='row-responsive'>
//         {stepTwoInputs.map((input, index) => (
//           <Box width={{ max: 'medium' }} key={index}>
//             {input(setValid)}
//           </Box>
//         ))}
//       </Box>
//       {/* </Grid> */}
//       {/* {!valid && (
//           <Error>There is an error with one or more inputs.</Error>
//         )} */}
//     </>
//   )
// }

// const StepThree = () => {
//   const { valid, setValid } = useContext(RiskContext)
//   const size = useContext(ResponsiveContext)

//   return (
//     <>
//       {/* <Grid
//         columns={size !== 'small' ? { count: 'fit', size: 'medium' } : '100%'}
//         gap={size !== 'small' ? 'large' : undefined}
//         margin={{ bottom: 'medium' }}
//       > */}
//       <Box direction='row-responsive'>
//         {stepThreeInputs.map((input, index) => (
//           <Box width={{ max: 'medium' }} key={index}>
//             {input(setValid)}
//           </Box>
//         ))}
//       </Box>
//       {/* </Grid> */}
//       {/* {!valid && (
//           <Error>There is an error with one or more inputs.</Error>
//         )} */}
//     </>
//   )
// }

// const StepFour = () => {
//   const { valid, setValid } = useContext(RiskContext)
//   const size = useContext(ResponsiveContext)

//   return (
//     <>
//       {/* <Grid
//         columns={size !== 'small' ? { count: 'fit', size: 'medium' } : '100%'}
//         gap={size !== 'small' ? 'large' : undefined}
//         margin={{ bottom: 'medium' }}
//       > */}
//       <Box direction='row-responsive'>
//         {stepFourInputs.map((input, index) => (
//           <Box width={{ max: 'medium' }} key={index}>
//             {input(setValid)}
//           </Box>
//         ))}
//       </Box>
//       {/* </Grid> */}
//       {/* {!valid && (
//           <Error>There is an error with one or more inputs.</Error>
//         )} */}
//     </>
//   )
// }

// const StepFive = () => {
//   const { valid, setValid } = useContext(RiskContext)
//   const size = useContext(ResponsiveContext)

//   return (
//     <>
//       {/* <Grid
//         columns={size !== 'small' ? { count: 'fit', size: 'medium' } : '100%'}
//         gap={size !== 'small' ? 'large' : undefined}
//         margin={{ bottom: 'medium' }}
//       > */}
//       <Box direction='row-responsive'>
//         {stepFiveInputs.map((input, index) => (
//           <Box width={{ max: 'medium' }} key={index}>
//             {input(setValid)}
//           </Box>
//         ))}
//       </Box>
//       {/* </Grid> */}
//       {/* {!valid && (
//           <Error>There is an error with one or more inputs.</Error>
//         )} */}
//     </>
//   )
// }

const createFinalGrid = () => {
  const boxGrid = []
  let index = 0

  for (const section in stepAnswers) {
    for (const subSection in stepAnswers[section]) {
      // Skip riskScore
      if (subSection !== 'riskScore') {
        boxGrid.push(<Box background={riskColorLookup[stepAnswers[section][subSection]?.risk?.risk]} border height='20px' key={index++} width='20px' />)
      }
    }
  }
  return boxGrid
}

const GetOtherNotes = () => {
  const { formValues } = useContext(RiskContext)
  const noteLabels = [
    {
      section: 'Accommodation',
      subSection: 'Accommodation1',
      name: 'accommodation1OtherNotes'
    },
    {
      section: 'Accommodation',
      subSection: 'Accommodation2',
      name: 'accommodation2OtherNotes'
    },
    {
      section: 'Accommodation',
      subSection: 'Accommodation3',
      name: 'accommodation3OtherNotes'
    },
    {
      section: 'Addiction',
      subSection: 'Addiction1',
      name: 'addiction1OtherNotes'
    },
    {
      section: 'Addiction',
      subSection: 'Addiction2',
      name: 'addiction2OtherNotes'
    },
    {
      section: 'Disability',
      subSection: 'Disability1',
      name: 'disability1OtherNotes'
    },
    {
      section: 'Disability',
      subSection: 'Disability2',
      name: 'disability2OtherNotes'
    },
    {
      section: 'Disability',
      subSection: 'Disability3',
      name: 'disability3OtherNotes'
    },
    {
      section: 'Disability',
      subSection: 'Disability4',
      name: 'disability4OtherNotes'
    },
    {
      section: 'Finances/Budgeting',
      subSection: 'Finances1',
      name: 'finances1OtherNotes'
    },
    {
      section: 'Finances/Budgeting',
      subSection: 'Finances2',
      name: 'finances2OtherNotes'
    },
    {
      section: 'Finances/Budgeting',
      subSection: 'Finances3',
      name: 'finances3OtherNotes'
    },
    {
      section: 'Finances/Budgeting',
      subSection: 'Finances4',
      name: 'finances4OtherNotes'
    },
    {
      section: 'Finances/Budgeting',
      subSection: 'Finances5',
      name: 'finances5OtherNotes'
    },
    {
      section: 'Finances/Budgeting',
      subSection: 'Finances6',
      name: 'finances6OtherNotes'
    },
    {
      section: 'Language',
      subSection: 'Language1',
      name: 'language1OtherNotes'
    },
    {
      section: 'Language',
      subSection: 'Language2',
      name: 'language2OtherNotes'
    },
    {
      section: 'Language',
      subSection: 'Language3',
      name: 'language3OtherNotes'
    }
  ]

  const toDisplay = noteLabels.map((label, index) => {
    if (formValues[label.name]) {
      return (
        <Box direction='column' key={index}>
          <Text color='brand' margin={{ top: 'medium', bottom: 'small' }} weight='bold'>{label.section}-{label.subSection}</Text>
          <Text>{formValues[label.name]}</Text>
        </Box>)
    }
  })

  return toDisplay
}

const StepFinal = () => {
  const { formValues } = useContext(RiskContext)

  return (
    <Box direction='column'>
      <Text size='xsmall'>Overall Risk</Text>
      <Text size='large' weight='bold'>{formValues.firstName} {formValues.lastName}</Text>

      <Text weight='bold'>Latest Risk Matrix</Text>
      <Box direction='row'>
        {createFinalGrid()}
      </Box>

      <Text margin={{ top: 'medium' }} weight='bold'>Notes</Text>
      {GetOtherNotes()}
    </Box>
  )
}

const sections = [
  {
    section: 'Client Details'
  },
  {
    section: 'Accommodation',
    value: 'accommodation'
  },
  {
    section: 'Addiction',
    value: 'addiction'
  },
  {
    section: 'Disability',
    value: 'disability'
  },
  {
    section: 'Finances/Budgeting',
    value: 'finances'
  },
  {
    section: 'Language',
    value: 'language'
  },
  {
    section: 'Legal',
    value: 'legal'
  },
  {
    section: 'Mental Health',
    value: 'mental'
  },
  {
    section: 'Physical Health',
    value: 'physical'
  },
  {
    section: 'Relationships',
    value: 'relationship'
  }
]

const steps = [
  {
    description: 'Complete the following to continue',
    inputs: <StepOne />,
    section: 'Client Details',
    title: 'Client Details',
    question: 'Question 1 of 1',
    riskLevel: 'Missing Answers'
  },
  {
    description: 'Select an option to continue',
    // inputs: <StepTwo />,
    inputs: <DisplayStep input={stepTwoInputs} />,
    section: 'Accommodation',
    title: 'Homelessness',
    question: 'Question 1 of 3',
    riskLevel: '',
    selected: ''
  },
  {
    description: 'Complete the following to continue',
    inputs: <DisplayStep input={stepThreeInputs} />,
    section: 'Accommodation',
    question: 'Question 2 of 3',
    title: 'Homelessness2',
    riskLevel: '',
    selected: ''
  },
  {
    description: 'Complete the following to continue',
    inputs: <DisplayStep input={stepFourInputs} />,
    section: 'Accommodation',
    question: 'Question 3 of 3',
    title: 'Homelessness3',
    riskLevel: '',
    selected: ''
  },
  {
    description: 'Complete the following to continue',
    inputs: <DisplayStep input={stepFiveInputs} />,
    section: 'Addiction',
    question: 'Question 1 of 2',
    title: 'Addiction1',
    riskLevel: '',
    selected: ''
  },
  {
    description: 'Complete the following to continue',
    inputs: <DisplayStep input={stepSixInputs} />,
    section: 'Addiction',
    question: 'Question 2 of 2',
    title: 'Addiction2',
    riskLevel: '',
    selected: ''
  },
  {
    description: 'Complete the following to continue',
    inputs: <DisplayStep input={stepSevenInputs} />,
    section: 'Disability',
    question: 'Question 1 of 4',
    title: 'Disability1',
    riskLevel: '',
    selected: ''
  },
  {
    description: 'Complete the following to continue',
    inputs: <DisplayStep input={stepEightInputs} />,
    section: 'Disability',
    question: 'Question 2 of 4',
    title: 'Disability2',
    riskLevel: '',
    selected: ''
  },
  {
    description: 'Complete the following to continue',
    inputs: <DisplayStep input={stepNineInputs} />,
    section: 'Disability',
    question: 'Question 3 of 4',
    title: 'Disability3',
    riskLevel: '',
    selected: ''
  },
  {
    description: 'Complete the following to continue',
    inputs: <DisplayStep input={stepTenInputs} />,
    section: 'Disability',
    question: 'Question 4 of 4',
    title: 'Disability4',
    riskLevel: '',
    selected: ''
  },
  {
    description: 'Complete the following to continue',
    inputs: <DisplayStep input={stepElevenInputs} />,
    section: 'Finances/Budgeting',
    question: 'Question 1 of 6',
    title: 'Finances1',
    riskLevel: '',
    selected: ''
  },
  {
    description: 'Complete the following to continue',
    inputs: <DisplayStep input={stepTwelveInputs} />,
    section: 'Finances/Budgeting',
    question: 'Question 2 of 6',
    title: 'Finances2',
    riskLevel: '',
    selected: ''
  },
  {
    description: 'Complete the following to continue',
    inputs: <DisplayStep input={stepThirteenInputs} />,
    section: 'Finances/Budgeting',
    question: 'Question 3 of 6',
    title: 'Finances3',
    riskLevel: '',
    selected: ''
  },
  {
    description: 'Complete the following to continue',
    inputs: <DisplayStep input={stepFourteenInputs} />,
    section: 'Finances/Budgeting',
    question: 'Question 4 of 6',
    title: 'Finances4',
    riskLevel: '',
    selected: ''
  },
  {
    description: 'Complete the following to continue',
    inputs: <DisplayStep input={stepFifteenInputs} />,
    section: 'Finances/Budgeting',
    question: 'Question 5 of 6',
    title: 'Finances5',
    riskLevel: '',
    selected: ''
  },
  {
    description: 'Complete the following to continue',
    inputs: <DisplayStep input={stepSixteenInputs} />,
    section: 'Finances/Budgeting',
    question: 'Question 6 of 6',
    title: 'Finances6',
    riskLevel: '',
    selected: ''
  },
  {
    description: 'Complete the following to continue',
    inputs: <DisplayStep input={stepSeventeenInputs} />,
    section: 'Language',
    question: 'Question 1 of 3',
    title: 'Language1',
    riskLevel: '',
    selected: ''
  },
  {
    description: 'Complete the following to continue',
    inputs: <DisplayStep input={stepEighteenInputs} />,
    section: 'Language',
    question: 'Question 2 of 3',
    title: 'Language2',
    riskLevel: '',
    selected: ''
  },
  {
    description: 'Complete the following to continue',
    inputs: <DisplayStep input={stepNineteenInputs} />,
    section: 'Language',
    question: 'Question 3 of 3',
    title: 'Language3',
    riskLevel: '',
    selected: ''
  },
  {
    description: 'Complete the following to continue',
    inputs: <DisplayStep input={stepTwentyInputs} />,
    section: 'Legal',
    question: 'Question 1 of 7',
    title: 'Legal1',
    riskLevel: '',
    selected: ''
  },
  {
    description: 'Complete the following to continue',
    inputs: <DisplayStep input={stepTwentyOneInputs} />,
    section: 'Legal',
    question: 'Question 2 of 7',
    title: 'Legal2',
    riskLevel: '',
    selected: ''
  },
  {
    description: 'Complete the following to continue',
    inputs: <DisplayStep input={stepTwentyTwoInputs} />,
    section: 'Legal',
    question: 'Question 3 of 7',
    title: 'Legal3',
    riskLevel: '',
    selected: ''
  },
  {
    description: 'Complete the following to continue',
    inputs: <DisplayStep input={stepTwentyThreeInputs} />,
    section: 'Legal',
    question: 'Question 4 of 7',
    title: 'Legal4',
    riskLevel: '',
    selected: ''
  },
  {
    description: 'Complete the following to continue',
    inputs: <DisplayStep input={stepTwentyFourInputs} />,
    section: 'Legal',
    question: 'Question 5 of 7',
    title: 'Legal5',
    riskLevel: '',
    selected: ''
  },
  {
    description: 'Complete the following to continue',
    inputs: <DisplayStep input={stepTwentyFiveInputs} />,
    section: 'Legal',
    question: 'Question 6 of 7',
    title: 'Legal6',
    riskLevel: '',
    selected: ''
  },
  {
    description: 'Complete the following to continue',
    inputs: <DisplayStep input={stepTwentySixInputs} />,
    section: 'Legal',
    question: 'Question 7 of 7',
    title: 'Legal7',
    riskLevel: '',
    selected: ''
  },
  {
    description: 'Complete the following to continue',
    inputs: <DisplayStep input={stepTwentySevenInputs} />,
    section: 'Mental',
    question: 'Question 1 of 4',
    title: 'Mental1',
    riskLevel: '',
    selected: ''
  },
  {
    description: 'Complete the following to continue',
    inputs: <DisplayStep input={stepTwentyEightInputs} />,
    section: 'Mental',
    question: 'Question 2 of 4',
    title: 'Mental2',
    riskLevel: '',
    selected: ''
  },
  {
    description: 'Complete the following to continue',
    inputs: <DisplayStep input={stepTwentyNineInputs} />,
    section: 'Mental',
    question: 'Question 3 of 4',
    title: 'Mental3',
    riskLevel: '',
    selected: ''
  },
  {
    description: 'Complete the following to continue',
    inputs: <DisplayStep input={stepThirtyInputs} />,
    section: 'Mental',
    question: 'Question 4 of 4',
    title: 'Mental4',
    riskLevel: '',
    selected: ''
  },
  {
    description: 'Complete the following to continue',
    inputs: <DisplayStep input={stepThirtyOneInputs} />,
    section: 'Physical',
    question: 'Question 1 of 3',
    title: 'Physical1',
    riskLevel: '',
    selected: ''
  },
  {
    description: 'Complete the following to continue',
    inputs: <DisplayStep input={stepThirtyTwoInputs} />,
    section: 'Physical',
    question: 'Question 2 of 3',
    title: 'Physical2',
    riskLevel: '',
    selected: ''
  },
  {
    description: 'Complete the following to continue',
    inputs: <DisplayStep input={stepThirtyThreeInputs} />,
    section: 'Physical',
    question: 'Question 3 of 3',
    title: 'Physical3',
    riskLevel: '',
    selected: ''
  },
  {
    description: 'Complete the following to continue',
    inputs: <DisplayStep input={stepThirtyFourInputs} />,
    section: 'Relationship',
    question: 'Question 1 of 5',
    title: 'Relationship1',
    riskLevel: '',
    selected: ''
  },
  {
    description: 'Complete the following to continue',
    inputs: <DisplayStep input={stepThirtyFiveInputs} />,
    section: 'Relationship',
    question: 'Question 2 of 5',
    title: 'Relationship2',
    riskLevel: '',
    selected: ''
  },
  {
    description: 'Complete the following to continue',
    inputs: <DisplayStep input={stepThirtySixInputs} />,
    section: 'Relationship',
    question: 'Question 3 of 5',
    title: 'Relationship3',
    riskLevel: '',
    selected: ''
  },
  {
    description: 'Complete the following to continue',
    inputs: <DisplayStep input={stepThirtySevenInputs} />,
    section: 'Relationship',
    question: 'Question 4 of 5',
    title: 'Relationship4',
    riskLevel: '',
    selected: ''
  },
  {
    description: 'Complete the following to continue',
    inputs: <DisplayStep input={stepThirtyEightInputs} />,
    section: 'Relationship',
    question: 'Question 5 of 5',
    title: 'Relationship5',
    riskLevel: '',
    selected: ''
  },
  {
    description: 'Complete the following to continue',
    inputs: <StepFinal />,
    section: 'FINAL',
    question: 'Question 1 of 1',
    title: 'Final page',
    riskLevel: ''
  }
]

function RiskMatrixWizard () {
  const history = useHistory()

  const size = useContext(ResponsiveContext)

  //   const theme = useContext(ThemeContext)
  const [activeIndex, setActiveIndex] = useState(0)
  // for readability, this is used to display numeric value of step on screen,
  // such as step 1 of 3. it will always be one more than the active array index
  const [activeStep, setActiveStep] = useState(activeIndex + 1)

  // store form values in state so they persist
  // when user goes back a step
  const [formValues, setFormValues] = useState(defaultFormValues)

  // controls state of cancel layer
  const [open, setOpen] = useState(false)

  // tracks validation results for the current step
  const [valid, setValid] = useState(true)

  // ref allows us to access the wizard container and ensure scroll position
  // is at the top as user advances between steps. useEffect is triggered
  // when the active step changes.
  const riskRef = useRef()

  const [riskScores, setRiskScores] = useState({
    accommodation: -1,
    addiction: -1,
    disability: -1,
    finances: -1,
    language: -1,
    legal: -1,
    mental: -1,
    physical: -1,
    relationship: -1,
    overall: -1
  })

  useEffect(() => {
    setActiveStep(activeIndex + 1)
  }, [activeIndex])

  const id = 'simple-wizard'

  const params = useParams()

  const userId = params.userid

  // scroll to top of step when step changes
  useEffect(() => {
    const container = riskRef.current
    const header = document.querySelector(`#${id}`)
    container.scrollTop = -header.getBoundingClientRect().bottom
  }, [activeIndex, open])

  useEffect(() => {
    let unmounted = false

    const getUserDetails = async (userId) => {
      const userDetails = await UserService.getUser(apiToken, userId, {
        type: 'client',
        fields: 'reference,firstName,lastName,email,dob,ethnicity,gender'
      })

      console.log(userDetails)
      if (!unmounted) {
        setFormValues(prevState => ({
          ...prevState,
          // reference: userDetails?.data?.[0]?.reference,
          firstName: userDetails?.data?.[0]?.firstName,
          lastName: userDetails?.data?.[0]?.lastName,
          // email: userDetails?.data?.[0]?.email,
          // dob: userDetails?.data?.[0]?.dob ? moment(userDetails?.data?.[0]?.dob).format('YYYY-MM-DD') : null,
          // ethnicity: userDetails?.data?.[0]?.ethnicity || '',
          gender: userDetails?.data?.[0]?.gender
          // age: moment().diff(userDetails?.data?.[0]?.dob, 'years')
        // console.log(userDetails.data)
        }))
      }
    }

    if (userId !== 'new') {
      getUserDetails(userId)
    }

    return () => { unmounted = true }
  }, [])

  // const numberColumns = 2
  //   const width = getWidth(numberColumns, theme, size)

  const updateRiskScores = (riskMatrixScore) => {
    // If there's an error it's likely because we have submitted
    // the client details so there will be no score yet
    if (!riskMatrixScore.error) {
      const riskMatrixArray = Object.entries(riskMatrixScore.summary)

      for (let [riskSection, riskLevel] of riskMatrixArray) {
        // Convert riskSection to match what we have here
        switch (riskSection) {
          case 'Finance':
            riskSection = 'finances'
            break
          case 'MentalHealth':
            riskSection = 'Mental'
            break
          case 'PhysicalHealth':
            riskSection = 'Physical'
            break
          case 'Relationships':
            riskSection = 'Relationship'
            break
        }

        // Check if there is a risk level
        if (Object.keys(riskLevel).length > 0) {
          setRiskScores(previousValues => ({
            ...previousValues,
            [riskSection.toLowerCase()]: riskLevel.level
          }))
        }
      }
    }
  }

  const onSubmit = async (values) => {
    console.log(stepAnswers)
    console.log(values)

    var submitArray = []
    var item = {
      key: '',
      questionText: '',
      answer: ''
    }

    // Loop through each step answer
    for (var primaryKey in stepAnswers) {
      for (var subKey in stepAnswers[primaryKey]) {
        if (subKey !== 'riskScore') {
          if (stepAnswers[primaryKey][subKey].risk !== undefined) {
            item = {
              questionKey: stepAnswers[primaryKey][subKey].risk.value,
              questionText: stepAnswers[primaryKey][subKey].risk.label,
              response: stepAnswers[primaryKey][subKey].risk.risk
            }
            submitArray.push(item)
          }
        }
      }
    }

    // Now add the personal details
    const personalDetails = {
      firstName: values.firstName,
      lastName: values.lastName,
      age: values.age,
      gender: values.gender,
      nationality: values.nationality
    }

    submitArray.push({
      questionKey: 'personalDetails',
      questionText: 'Personal Details',
      response: JSON.stringify(personalDetails)
    })

    // And any notes
    const riskNotes = {
      accommodation1OtherNotes: values.accommodation1OtherNotes,
      accommodation2OtherNotes: values.accommodation2OtherNotes,
      accommodation3OtherNotes: values.accommodation3OtherNotes,
      addiction1OtherNotes: values.addiction1OtherNotes,
      addiction2OtherNotes: values.addiction2OtherNotes,
      disability1OtherNotes: values.disability1OtherNotes,
      disability2OtherNotes: values.disability2OtherNotes,
      disability3OtherNotes: values.disability3OtherNotes,
      disability4OtherNotes: values.disability4OtherNotes,
      finances1OtherNotes: values.finances1OtherNotes,
      finances2OtherNotes: values.finances2OtherNotes,
      finances3OtherNotes: values.finances3OtherNotes,
      finances4OtherNotes: values.finances4OtherNotes,
      finances5OtherNotes: values.finances5OtherNotes,
      finances6OtherNotes: values.finances6OtherNotes,
      language1OtherNotes: values.language1OtherNotes,
      language2OtherNotes: values.language2OtherNotes,
      language3OtherNotes: values.language3OtherNotes,
      legal1OtherNotes: values.legal1OtherNotes,
      legal2OtherNotes: values.legal2OtherNotes,
      legal3OtherNotes: values.legal3OtherNotes,
      legal4OtherNotes: values.legal4OtherNotes,
      legal5OtherNotes: values.legal5OtherNotes,
      legal6OtherNotes: values.legal6OtherNotes,
      legal7OtherNotes: values.legal7OtherNotes,
      mental1OtherNotes: values.mental1OtherNotes,
      mental2OtherNotes: values.mental2OtherNotes,
      mental3OtherNotes: values.mental3OtherNotes,
      mental4OtherNotes: values.mental4OtherNotes,
      physical1OtherNotes: values.physical1OtherNotes,
      physical2OtherNotes: values.physical2OtherNotes,
      physical3OtherNotes: values.physical3OtherNotes,
      relationship1OtherNotes: values.relationship1OtherNotes,
      relationship2OtherNotes: values.relationship2OtherNotes,
      relationship3OtherNotes: values.relationship3OtherNotes,
      relationship4OtherNotes: values.relationship4OtherNotes,
      relationship5OtherNotes: values.relationship5OtherNotes
    }

    submitArray.push({
      questionKey: 'riskNotes',
      questionText: 'Risk Notes',
      response: JSON.stringify(riskNotes)
    })

    // Build output data
    // Change to Published if on last page
    let submissionStatus = ''
    if (activeIndex === steps.length - 1) {
      submissionStatus = 'Published'
    } else {
      submissionStatus = 'Draft'
    }
    // const draft = 'Draft'

    const data = {
      user: userId,
      organisation: activeOrg.id,
      status: submissionStatus,
      type: 'riskmatrix',
      reference: '',
      // notify: JSON.stringify(notifyArray),
      responses: submitArray
    }

    console.log(data)
    // // if (userId === 'new') {
    // console.log('Notify ', notifications)
    var submissionAdded = await FormService.makeSubmissions(apiToken, data)
    console.log(submissionAdded)
    if (submissionAdded.error) {
      NotificationService.error(submissionAdded.error)
    } else {
      const submissionId = submissionAdded.data.id

      const riskMatrixScore = await RiskMatrixService.riskMatrix(apiToken, submissionId)

      updateRiskScores(riskMatrixScore)
      // console.log(riskMatrixScore)

      // Display if on last page
      if (activeIndex === steps.length - 1) {
        NotificationService.info('Risk Matrix added')
      }
      // history.push('/clients')
    }
  }

  return (
    <RiskContext.Provider
      value={{
        activeIndex,
        id,
        defaultFormValues,
        setActiveIndex,
        activeStep,
        setActiveStep,
        valid,
        ref: riskRef,
        setValid,
        steps,
        formValues,
        setFormValues,
        stepAnswers,
        overallRisk
        // wizardTitle: 'Wizard Title',
        // width
      }}
    >
      <Box width='xlarge'>
        <Box background='white' direction='column' elevation='small' flex='grow' gap='small' margin={{ bottom: 'medium' }} round='small'>
          <Box direction='column' pad={{ horizontal: 'medium' }}>
            <Text margin={{ top: 'small' }} size='xlarge'>Risk Form: {formValues.firstName ? formValues.firstName + ' ' + formValues.lastName : 'New User'}</Text>
            <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'><Anchor href='/'>Snowdrop CRM</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></Text>
          </Box>
        </Box>

        <Box direction='row-responsive' gap='small'>
          <Box background='white' direction='column' elevation='small' flex='grow' gap='small' margin={{ bottom: 'medium' }} pad='small' round='small' width={size === 'small' ? '100%' : '33%'}>
            <Box direction='row' width={{ max: 'medium' }}>
              <Box
                alignSelf='center'
                background={riskColorLookup[riskLabels[riskScores.overall]] || 'brand'}
                height='20px'
                round
                width='20px'
              />
              <Box direction='column'>
                <Text margin={{ left: 'small' }} size='large' weight='bold'>Overall Risk</Text>
                <Text color={riskColorLookup[riskLabels[riskScores.overall]]} margin={{ left: 'small' }} size='xsmall'>{riskLabels[riskScores.overall] || ''}</Text>
              </Box>
            </Box>
            <Divider color='primary' />

            {sections.map((section, index) => (
              <Box direction='row' width={{ max: 'medium' }} key={index}>
                <Box
                  alignSelf='center'
                  background={riskColorLookup[riskLabels[riskScores[section.value]]] || 'brand'}
                  height='20px'
                  round
                  width='20px'
                />
                <Box direction='column'>
                  <Text margin={{ left: 'small' }}>{section.section}</Text>
                  <Text color={riskColorLookup[riskLabels[riskScores[section.value]]]} margin={{ left: 'small' }} size='xsmall'>{riskLabels[riskScores[section.value]] || ''}</Text>
                </Box>
              </Box>
            ))}
          </Box>

          <Box background='white' elevation='small' direction='column' flex='grow' gap='small' margin={{ bottom: 'medium' }} round='small' width={size === 'small' ? '100%' : '66%'}>

            {/* <WizardHeader setOpen={setOpen} /> */}
            <StepContent
              // onSubmit={({ value }) => console.log('onSubmit:', value)}
              onSubmit={({ value }) => onSubmit(value)}
            />
            <StepFooter />
          </Box>
        </Box>
      </Box>
      {/* {open && <CancellationLayer onSetOpen={setOpen} />} */}
    </RiskContext.Provider>
  )
};

export default RiskMatrixWizard
