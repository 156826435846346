import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { useStore } from 'react-context-hook'

import moment from 'moment'

// Error handler
import useErrorHandler from '../utils/useErrorHandler'
import FlashMessage from '../utils/FlashMessage'
import { capitalize } from '../utils/text'

// Services
import FormService from '../services/FormService'
import LocalStorageService from '../services/LocalStorageService'
import NotificationMessagesService from '../services/NotificationMessagesService'
import OrganisationService from '../services/OrganisationService'

// Appt Components
import Anchor from '../components/simple/anchor/Anchor'
import Box from '../components/simple/box/Box'
import Button from '../components/simple/button/Button'
import FontAwesome from '../components/compound/fontAwesome/FontAwesome'
import List from '../components/simple/list/List'
import Text from '../components/simple/text/Text'
import H1 from '../components/simple/heading/H1'
import H3 from '../components/simple/heading/H3'

// Appt Widgets
import CounterBox from '../components/compound/counterBox/CounterBox'
import ListBox from '../components/compound/listBox/ListBox'

function Dashboard (props) {
  const history = useHistory()
  const { error, showError } = useErrorHandler(null)

  // const [apiToken, setApiToken, deleteApiToken] = useStore()
  // const [currentUser, setCurrentUser, deleteCurrentUser] = useStore({})
  // const [currentOrgs, setCurrentOrgs, deleteCurrentOrgs] = useStore([])
  // const [activeOrg, setActiveOrg, deleteActiveOrg] = useStore()

  const activeOrganisation = LocalStorageService.get('activeOrg')
  const apiToken = LocalStorageService.get('apiToken')

  const [dashboardState, setDashboardState] = useState({
    users: 0,
    students: 0
  })

  // Store the notifications
  const [notifications, setNotifications] = useState([])

  // Mark notification as read
  const notificationRead = async (id) => {
    (async () => {
      await NotificationMessagesService.read(id)
      await getNotifications()
    })()
  }

  // Get the notifications
  const getNotifications = async () => {
    const params = {
      // fields: 'createdAt,id,message',
      limit: 1000,
      orgId: activeOrganisation.id,
      where: {
        read: false
      }
    }

    const where = {
    }
    // const where = {
    //   organisationId: activeOrganisation.id
    // }

    const notifications = await NotificationMessagesService.get(params, where)
    if (notifications?.data) {

      const filteredNotifications = notifications.data.filter((item) => !item.read)

      const mappedNotifications = filteredNotifications.map((item) => {
        return {
          notification: (
            <Box direction='column'>
              <Box direction='row' margin={{ left: 'none' }}>
                <Text>{item.type ? `${capitalize(item.type)}: ` : ''}{item.message}</Text>
                <Button
                  margin={{ left: 'small', top: '-2px' }}
                  onClick={() => { notificationRead(item.id) }}
                  plain
                >
                  <FontAwesome color='brand' icon={['fal', 'minus-circle']} size='sm'/>
                </Button>
              </Box>
              <Box direction='row' margin={{ bottom: 'medium', left: 'none' }}>
                <Text size='xsmall'>{moment(item.date).fromNow()}</Text>
              </Box>
            </Box>)
        }
      })

      setNotifications(mappedNotifications)
    }
  }

  // Get number of Users for this Organisation
  const getDashboardCounts = async () => {
    console.log('This is dash props', props)

    // if (!activeOrg || !activeOrg.id || !apiToken) {
    const apiToken = LocalStorageService.get('apiToken')
    const activeOrg = LocalStorageService.get('activeOrg')
    const currentUser = LocalStorageService.get('userDetails')
    const organisationId = activeOrg.id

    var userCount = {
      users: 0,
      students: 0
    }
    userCount.users = await OrganisationService.getUserCount(apiToken, 'staff')
    userCount.students = await OrganisationService.getUserCount(apiToken, 'client')

    const params = {
      fields: 'id,user,organisation,reference,type',
      limit: 1000
    }

    const where = {
      organisationId: organisationId,
      status: 'Published',
      user: currentUser.id
    }

    const formSubmissions = await FormService.getSubmissions(apiToken, params, where)

    userCount.formSubmissions = 0
    if (formSubmissions?.page?.total) {
      userCount.formSubmissions = formSubmissions.page.total
    }

    getNotifications()

    setDashboardState(userCount)
  // }
  }

  useEffect(() => {
    getDashboardCounts()
  }, [props.activeOrg])

  return (
    <>
      <Box elevation='small' flex='grow' background='white' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>
        <Box direction='column' pad={{ horizontal: 'medium' }}>
          <H1 margin={{ top: 'small', bottom: 'none' }}>Dashboard </H1>
          {error &&
            <FlashMessage message={error} />}
          <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'><Anchor href='/'>Snowdrop CRM</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></Text>
        </Box>
      </Box>

      <Box direction='row-responsive' flex='grow' gap='small' justify='between' margin={{ bottom: 'medium' }}>
        <CounterBox width='23%' counter={dashboardState.students || 0} iconColor='#52bc94' icon='user'>Clients</CounterBox>
        <CounterBox width='23%' counter={dashboardState.users || 0} iconColor='#52bc94' icon='user-friends'>Staff</CounterBox>
        {/* <CounterBox width='23%' counter={0} iconColor='#52bc94' icon='user-friends'>Records</CounterBox> */}
        <CounterBox width='23%' counter={dashboardState.formSubmissions || 0} iconColor='#52bc94' icon='exclamation-triangle'>Submissions</CounterBox>
      </Box>

      <Box direction='row-responsive' flex='grow' gap='medium'>
        <Box background='white' elevation='small' direction='column' gap='medium' pad='medium' round='small' width='large'>
          <H3 margin={{ vertical: 'none' }} weight='bold'>Notifications</H3>
          {!notifications.length && <Text size='small'>There are no notifications to display.</Text>}

          <List border={false} data={notifications} pad={{ left: 'none' }} primaryKey='notification' />
        </Box>

        {/* <Box background='white' elevation='small' direction='column' gap='medium' pad='medium' round='small' width='medium'>
          <Text weight='bold'>Welcome to Snowdrop CRM</Text>
          <Text>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vel vestibulum risus. Maecenas ut justo vestibulum, malesuada dui id, gravida velit.</Text>
        </Box> */}
        <Box direction='row-responsive' gap='medium' width='medium'>
          {/* <ListBox data={['Core - Article', 'Core-Audio', 'Core - Category', 'Core - Image', 'Core - PDF', 'Core - Video', 'Core - Website Link']} heading='Enabled Modules' /> */}
          {/* dashboardState.recentContent &&
            <ListBox data={dashboardState.recentContent} primaryKey='location' secondaryKey='icon' heading='Recently Created Content' /> */}
        </Box>
      </Box>
    </>
  )
}

export default Dashboard
