import React, { useState, useEffect } from 'react'

// Spinner
import PulseLoader from 'react-spinners/PulseLoader'

import { constants } from '../../../utils/constants'

import moment from 'moment'

// Appt Components
import Box from '../../../components/simple/box/Box'
import Button from '../../../components/simple/button/Button'
import DateInput from '../../../components/simple/input/DateInput'
import DataTable from '../../../components/simple/dataTable/DataTable'
import Divider from '../../../components/simple/divider/Divider'
import FontAwesome from '../../../components/compound/fontAwesome/FontAwesome'
import Form from '../../../components/simple/form/Form.js'
import FormField from '../../../components/simple/formField/FormField.js'
import H2 from '../../../components/simple/heading/H2.js'
import OptionButtons from '../../../components/compound/optionButtons/OptionButtons'
import RadioButtonGroup from '../../../components/simple/input/RadioButtonGroup'
import Text from '../../../components/simple/text/Text'
import TextArea from '../../../components/simple/input/TextArea'
import TextInput from '../../../components/simple/input/TextInput'

function Family (props) {
  const [loading, setLoading] = useState(false)

  const [questions, setQuestions] = useState(props.questions)
  const [localFormDetails, setLocalFormDetails] = useState(props.formDetails)

  // Determine if housing importantEvent Add button is enabled or not
  const [housingImportantEventAddButton, setHousingImportantEventAddButton] = useState(true)
  // Array of housing important events
  const [housingImportantEvents, setHousingImportantEvents] = useState([])
  // Array of housing important events mapped for DataTable
  const [mappedHousingImportantEvents, setMappedHousingImportantEvents] = useState([])
  // DataTable columns for housing important events
  const [housingImportantEventsColumns, setHousingImportantEventsDataColumns] = useState()

  const previousPage = () => {
    props.previousPage()
  }

  const submitForm = () => {
    props.submitForm(localFormDetails, 'Draft')
  }

  // Housing Important Event management functions
  const addHousingImportantEvent = () => {
    // Don't save if all fields empty
    if (localFormDetails.housingImportantEvent !== '' || localFormDetails.housingImportantEventDate !== '') {
      const event = {
        housingImportantEvent: localFormDetails.housingImportantEvent,
        housingImportantEventDate: localFormDetails.housingImportantEventDate
      }

      setHousingImportantEvents(housingImportantEvents => [...housingImportantEvents, event])

      // Clear input fields
      setLocalFormDetails(localFormDetails => ({
        ...localFormDetails,
        housingImportantEvent: '',
        housingImportantEventDate: ''
      }))

      setHousingImportantEventAddButton(true)
    }
  }

  const removeHousingImportantEvent = (data) => {
    const newEvent = housingImportantEvents.filter((item) => item.housingImportantEvent !== data.housingImportantEvent)

    setHousingImportantEvents(newEvent)
  }

  const editHousingImportantEvent = (data) => {
    setLocalFormDetails(localFormDetails => ({
      ...localFormDetails,
      housingImportantEvent: data.housingImportantEvent,
      housingImportantEventDate: data.housingImportantEventDate
    }))

    removeHousingImportantEvent(data)
    setHousingImportantEventAddButton(false)
  }

  // Housing Important events have been altered
  // so rebuild the display list
  useEffect(() => {
    setHousingImportantEventsDataColumns([
      {
        property: 'housingImportantEvent',
        header: <Text pad='none' margin='none'>Event</Text>
      },
      {
        property: 'housingImportantEventDate',
        header: <Text>Date</Text>
      },
      {
        property: 'actions',
        render: datum => (
          <Box direction='row'>
            <Button label={<Text><FontAwesome icon={['fal', 'minus-circle']} /></Text>} margin={{ horizontal: 'xsmall' }} style={{ background: '#333' }} onClick={() => removeHousingImportantEvent(datum)} target='_self' />
            <Button label={<Text><FontAwesome icon={['fal', 'edit']} /></Text>} margin={{ horizontal: 'xsmall' }} style={{ background: '#333' }} onClick={() => editHousingImportantEvent(datum)} target='_self' />
          </Box>
        )
      }
    ])

    const mappedHousingImportantEvents = housingImportantEvents.map((event, index) => ({
      housingImportantEvent: event.housingImportantEvent,
      housingImportantEventDate: moment(event.housingImportantEventDate).format('YYYY-MM-DD')
    }))

    setMappedHousingImportantEvents(mappedHousingImportantEvents)

    // Stringify housing important events and store them
    const housingImportantEventsToSave = JSON.stringify(housingImportantEvents)

    setLocalFormDetails(localFormDetails => ({
      ...localFormDetails,
      housingImportantEvents: housingImportantEventsToSave
    }))
  }, [housingImportantEvents])

  return (
    <Box gridArea='main' background='white' direction='column' gap='small' round='small'>
      <Divider color='primary' />
      <Box
        gap='small'
        margin={{ horizontal: 'small' }}
        pad='small'
        round='small'
      >

        <Box fill direction='row' gap='small'>
          <H2 margin={{ vertical: 'none' }}>Housing Status</H2>
        </Box>

        <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
        {localFormDetails && questions.length
          ? (
            <Form
              onChange={nextValue => {
                setLocalFormDetails(nextValue)
                setHousingImportantEventAddButton(nextValue?.housingImportantEvent === '' || nextValue?.housingImportantEventDate === '')
              }}
              onSubmit={({ value: nextValue }) => {
                submitForm(nextValue)
              }}
              value={localFormDetails}
            >
              <Box direction='row-responsive' gap='small'>
                {/* Housing status */}
                <FormField
                  // label='Are there any Urgent Needs?'
                  name={questions[0].key}
                >
                  <RadioButtonGroup
                    // direction='row-responsive'
                    labelKey='label'
                    name={questions[0].key}
                    // onChange={event => {
                    //   //   setToSend(event.value)
                    //   props.setNotifications(event.value)
                    //   console.log('Group1: ', event.value)
                    // }}
                    options={constants.housingStatus}
                    valueKey='value'
                  />
                </FormField>
              </Box>

              <Box direction='row-responsive' gap='small'>
                {/* Housing risk */}
                <FormField
                  label={questions[1].questionText}
                  name={questions[1].key}
                >
                  <OptionButtons
                    id={questions[1].key}
                    name={questions[1].key}
                  />
                </FormField>
              </Box>

              {localFormDetails.housingRisk === 'Yes' &&
                <Box direction='row-responsive' gap='small'>
                  {/* Housing risk reason */}
                  <FormField
                    label={questions[2].questionText}
                    name={questions[2].key}
                    width='100%'
                  >
                    <TextArea
                      name={questions[2].key}
                    />
                  </FormField>
                </Box>}

              <Box direction='row-responsive' gap='small'>
                {/* housingChanges */}
                <FormField
                  label={questions[3].questionText}
                  name={questions[3].key}
                  width='100%'
                >
                  <TextArea
                    name={questions[3].key}
                  />
                </FormField>
              </Box>

              <Divider color='primary' margin={{ top: 'medium', bottom: 'small' }} />

              <Text margin={{ top: 'xsmall' }} size='large'>Important Events</Text>

              {mappedHousingImportantEvents.length > 0 &&
                <Box direction='row-responsive' gap='medium'>
                  <DataTable
                    columns={housingImportantEventsColumns}
                    data={mappedHousingImportantEvents}
                    fill='horizontal'
                    size='large'
                  />
                </Box>}

              <Box direction='row-responsive' gap='medium'>
                {/* housingImportantEvent */}
                <FormField
                  label={questions[4].questionText}
                  name={questions[4].key}
                >
                  <TextInput
                    name={questions[4].key}
                    type='text'
                  />
                </FormField>

                {/* housingImportantEventDate */}
                <FormField
                  label={questions[5].questionText}
                  name={questions[5].key}
                >
                  <DateInput
                    name={questions[5].key}
                  />
                </FormField>

                <Button disabled={housingImportantEventAddButton} label={<Text>Add</Text>} color='primary' onClick={() => addHousingImportantEvent()} style={{ height: '35px' }} alignSelf='end' margin={{ bottom: 'small' }} />
              </Box>
              <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />

              <Box direction='row' justify='between' margin={{ top: 'medium' }}>
                <Button label='< Back' onClick={() => previousPage()} secondary />
                <Button type='submit' label='Next Page' primary />
              </Box>
            </Form>)
          : (
            <Box align='center'>
              <PulseLoader
                size={15}
                color='orange'
                loading={loading}
              />
            </Box>)}
      </Box>
    </Box>
  )
}
export default Family
