import React, { useState, useEffect } from 'react'

import { useHistory, useParams } from 'react-router-dom'
import { useStore } from 'react-context-hook'

import LocalStorageService from '../../services/LocalStorageService'
import NotificationService from '../../services/NotificationService'
import RoleService from '../../services/RoleService'

import { capitalize } from '../../utils/text'

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor'
import Box from '../../components/simple/box/Box'
import Grid from '../../components/simple/grid/Grid'
import Button from '../../components/simple/button/Button'
import CheckBoxGroup from '../../components/simple/input/CheckBoxGroup'
import Divider from '../../components/simple/divider/Divider'
import Form from '../../components/simple/form/Form.js'
import FormField from '../../components/simple/formField/FormField.js'
import H4 from '../../components/simple/heading/H4'
import H5 from '../../components/simple/heading/H5'

import Text from '../../components/simple/text/Text'
import TextInput from '../../components/simple/input/TextInput'

function RoleEdit () {
  const history = useHistory()

  // const [apiToken, setApiToken, deleteApiToken] = useStore(null)
  // const [currentUser, setCurrentUser, deleteCurrentUser] = useStore({})
  // const [currentOrgs, setCurrentOrgs, deleteCurrentOrgs] = useStore([])
  // const [activeOrg, setActiveOrg, deleteActiveOrg] = useStore()

  const [roleDetails, setRoleDetails] = useState({
    title: '',
    permissions: []
  })

  const params = useParams()

  const roleId = params.roleid

  // Permissions from Flytt tranlsated into key/label pairs
  const allPermissions = {
    clientAll: {
      label: 'Client (All)',
      permissions: {
        basic: [
          {
            key: 'clientCreate',
            label: 'Create'
          }
        ],
        moderate: [
          {
            key: 'clientViewAll',
            label: 'Access'
          },
          {
            key: 'clientViewAllBasic',
            label: 'View Basic Info'
          },
          {
            key: 'clientViewAllPersonal',
            label: 'View Personal Info'
          },
          {
            key: 'clientEditAll',
            label: 'Edit'
          },
          {
            key: 'clientAccessArchive',
            label: 'Access Archive'
          },
          {
            key: 'clientArchiveAll',
            label: 'Archive'
          },
          {
            key: 'clientRestoreAll',
            label: 'Restore'
          }
        ],
        privileged: [
          {
            key: 'clientDestroyAll',
            label: 'Destroy'
          },
          {
            key: 'clientGrantDirectAccess',
            label: 'Assign Direct Access'
          },
          {
            key: 'clientViewAllSensitive',
            label: 'View Sensitive Info'
          }
        ]
      }
    },
    clientMy: {
      label: 'Client (Assigned)',
      permissions: {
        basic: [
          {
            key: 'clientViewMy',
            label: 'Access'
          },
          {
            key: 'clientViewMyBasic',
            label: 'View Basic Info'
          },
          {
            key: 'clientEditMy',
            label: 'Edit'
          },
          {
            key: 'clientArchiveMy',
            label: 'Archive'
          }
        ],
        moderate: [
          {
            key: 'clientViewMyPersonal',
            label: 'View Personal Info'
          }
        ],
        privileged: [
          {
            key: 'clientViewMySensitive',
            label: 'View Sensitive Info'
          }
        ]
      }
    },
    clientNotes: {
      label: 'Client Notes',
      permissions: {
        basic: [
          {
            key: 'clientNotesView',
            label: 'View'
          },
          {
            key: 'clientNotesEdit',
            label: 'Edit'
          }
        ],
        moderate: [
          {
            key: 'clientNotesArchive',
            label: 'Archive'
          },
          {
            key: 'clientNotesRestore',
            label: 'Restore'
          }

        ],
        privileged: [
        ]
      }
    },
    clientDocs: {
      label: 'Client Documents',
      permissions: {
        basic: [
          {
            key: 'clientDocsView',
            label: 'View'
          },
          {
            key: 'clientDocsEdit',
            label: 'Edit'
          }
        ],
        moderate: [
          {
            key: 'clientDocsArchive',
            label: 'Archive'
          },
          {
            key: 'clientDocsRestore',
            label: 'Restore'
          }

        ],
        privileged: [
        ]
      }
    },
    clientForms: {
      label: 'Client Forms',
      permissions: {
        basic: [
          {
            key: 'clientFormsView',
            label: 'View'
          },
          {
            key: 'clientFormsEdit',
            label: 'Edit'
          }
        ],
        moderate: [
          {
            key: 'clientFormsArchive',
            label: 'Archive'
          },
          {
            key: 'clientFormsRestore',
            label: 'Restore'
          }

        ],
        privileged: [
        ]
      }
    },

    // partnerOrganisation: {
    //   basic: [
    //     {
    //       key: 'partnerOrgViewMy',
    //       label: 'PartnerOrganisation - View Assigned'
    //     },
    //     {
    //       key: 'partnerOrgEditMy',
    //       label: 'PartnerOrganisation - Edit Assigned'
    //     },
    //     {
    //       key: 'partnerOrgArchiveMy',
    //       label: 'PartnerOrganisation - Archive Assigned'
    //     },
    //     {
    //       key: 'partnerOrgCreateMy',
    //       label: 'PartnerOrganisation - Create Assigned'
    //     }
    //   ],
    //   moderate: [
    //     {
    //       key: 'partnerOrgViewAll',
    //       label: 'PartnerOrganisation - View All'
    //     },
    //     {
    //       key: 'partnerOrgEditAll',
    //       label: 'PartnerOrganisation - Edit All'
    //     },
    //     {
    //       key: 'partnerOrgArchiveAll',
    //       label: 'PartnerOrganisation - Archive All'
    //     },
    //     {
    //       key: 'partnerOrgRestoreAll',
    //       label: 'PartnerOrganisation - Restore All'
    //     },
    //     {
    //       key: 'partnerOrgCreateAll',
    //       label: 'PartnerOrganisation - Create All'
    //     },
    //     {
    //       key: 'partnerOrgAccessArchive',
    //       label: 'PartnerOrganisation - Access Archive'
    //     }
    //   ],
    //   privileged: [
    //     {
    //       key: 'partnerOrgDestroyAll',
    //       label: 'PartnerOrganisation - Destroy All'
    //     },
    //     {
    //       key: 'partnerOrgGrantDirectAccess',
    //       label: 'PartnerOrganisation - Can Grant Direct Access'
    //     }
    //   ]
    // },
    // partnerContact: {
    //   basic: [
    //     {
    //       key: 'partnerContactViewMy',
    //       label: 'PartnerContact - View Assigned'
    //     },
    //     {
    //       key: 'partnerContactEditMy',
    //       label: 'PartnerContact - Edit Assigned'
    //     },
    //     {
    //       key: 'partnerContactArchiveMy',
    //       label: 'PartnerContact - Archive Assigned'
    //     },
    //     {
    //       key: 'partnerContactCreateMy',
    //       label: 'PartnerContact - Create Assigned'
    //     }
    //   ],
    //   moderate: [
    //     {
    //       key: 'partnerContactViewAll',
    //       label: 'PartnerContact - View All'
    //     },
    //     {
    //       key: 'partnerContactEditAll',
    //       label: 'PartnerContact - Edit All'
    //     },
    //     {
    //       key: 'partnerContactArchiveAll',
    //       label: 'PartnerContact - Archive All'
    //     },
    //     {
    //       key: 'partnerContactRestoreAll',
    //       label: 'PartnerContact - Restore All'
    //     },
    //     {
    //       key: 'partnerContactCreateAll',
    //       label: 'PartnerContact - Create All'
    //     },
    //     {
    //       key: 'partnerContactAccessArchive',
    //       label: 'PartnerContact - Access Archive'
    //     }
    //   ],
    //   privileged: [
    //     {
    //       key: 'partnerContactDestroyAll',
    //       label: 'PartnerContact - Destroy All'
    //     },
    //     {
    //       key: 'partnerContactGrantDirectAccess',
    //       label: 'PartnerContact - Can Grant Direct Access'
    //     }
    //   ]
    // },
    form: {
      label: 'Form',
      permissions: {
        basic: [
          {
            key: 'formAccessModule',
            label: 'Access Module'
          },

          {
            key: 'formAccessCaseworkReferral',
            label: 'Casework Referral'
          },
          {
            key: 'formAccessMonitoring',
            label: 'Monitoring Tool'
          },
          {
            key: 'formAccessClientCarePlan',
            label: 'Client Care Plan'
          },
          {
            key: 'formAccessRiskMatrix',
            label: 'Risk Matrix'
          }
        ],
        moderate: [
          {
            key: 'formAccessAll',
            label: 'Access All Forms'
          }
        ],
        privileged: [
          // {
          //   key: 'formGrantDirectAccess',
          //   label: 'Form - Can Grant Direct Access'
          // }
        ]
      }
    },
    // report: {
    //   basic: [
    //     {
    //       key: 'reportAccessModule',
    //       label: 'Report - Access Module'
    //     }
    //   ],
    //   moderate: [
    //     {
    //       key: 'reportAccessAll',
    //       label: 'Report - Access All Reports'
    //     }
    //   ],
    //   privileged: [
    //     {
    //       key: 'reportGrantDirectAccess',
    //       label: 'Report - Can Grant Direct Access'
    //     }
    //   ]
    // },
    role: {
      label: 'Role',
      permissions: {
        basic: [
        ],
        moderate: [
          {
            key: 'roleAccessModule',
            label: 'Access Module'
          }
        ],
        privileged: [
          {
            key: 'roleManage',
            label: 'Manage'
          }
        ]
      }
    },
    staffAll: {
      label: 'Staff (All)',
      permissions: {
        basic: [
          {
            key: 'staffCreate',
            label: 'Create'
          }
          // {
          //   key: 'staffViewMy',
          //   label: 'Assigned - Access'
          // },
          // {
          //   key: 'staffEditMy',
          //   label: 'Assigned - Edit'
          // },
          // {
          //   key: 'staffArchiveMy',
          //   label: 'Assigned - Archive'
          // }
        ],
        moderate: [
          {
            key: 'staffViewAll',
            label: 'Access'
          },
          {
            key: 'staffEditAll',
            label: 'Edit'
          },
          {
            key: 'staffAccessArchive',
            label: 'Access Archive'
          },
          {
            key: 'staffArchiveAll',
            label: 'Archive'
          },
          {
            key: 'staffRestoreAll',
            label: 'Restore'
          }
        ],
        privileged: [
          {
            key: 'staffDestroyAll',
            label: 'Destroy All'
          }
          // {
          //   key: 'staffGrantDirectAccess',
          //   label: 'Staff - Can Grant Direct Access'
          // }
        ]
      }
    },
    staffNotes: {
      label: 'Staff Notes',
      permissions: {
        basic: [
          {
            key: 'staffNotesView',
            label: 'View'
          },
          {
            key: 'staffNotesEdit',
            label: 'Edit'
          }
        ],
        moderate: [
          {
            key: 'staffNotesArchive',
            label: 'Archive'
          },
          {
            key: 'staffNotesRestore',
            label: 'Restore'
          }

        ],
        privileged: [
        ]
      }
    },
    staffDocs: {
      label: 'Staff Documents',
      permissions: {
        basic: [
          {
            key: 'staffDocsView',
            label: 'View'
          },
          {
            key: 'staffDocsEdit',
            label: 'Edit'
          }
        ],
        moderate: [
          {
            key: 'staffDocsArchive',
            label: 'Archive'
          },
          {
            key: 'staffDocsRestore',
            label: 'Restore'
          }

        ],
        privileged: [
        ]
      }
    },
    system: {
      label: 'System',
      permissions: {
        basic: [
        ],
        moderate: [
        ],
        privileged: [
          {
            key: 'systemManagementAccess',
            label: 'System Management Access'
          }
          // {
          //   key: 'staffGrantDirectAccess',
          //   label: 'Staff - Can Grant Direct Access'
          // }
        ]
      }
    }
  }

  // Build the permsissions into a format for rendering
  const buildPermissions = () => {
    const buildPerms = []
    for (const role in allPermissions) {
      buildPerms.push(<Box><H5 weight='bold' margin={{ top: 'none' }}>{allPermissions[role].label}</H5></Box>)
      for (const level in allPermissions[role].permissions) {
        if (!allPermissions[role].permissions[level].length) {
          buildPerms.push(
            <Box>
              <FormField
                label={`${capitalize(level)}`}
                name='permissions'
              >
                <Text color='dark-3'>N/A</Text>
              </FormField>
            </Box>
          )
        } else {
          buildPerms.push(
            <Box background={`${level}Permissions`} pad={{vertical: 'none', horizontal: 'small'}}>
              <FormField
                label={`${capitalize(level)}`}
                name='permissions'
              >
                <CheckBoxGroup
                  labelKey='label'
                  name='permissions'
                  valueKey='key'
                  options={allPermissions[role].permissions[level]}
                />
              </FormField>
            </Box>
          )
        }
      }
    }

    return buildPerms
  }

  // Submit form values
  const submitForm = async (values) => {
    // const apiToken = LocalStorageService.get('apiToken')

    if (roleId === 'new') {
      const roleAdded = await RoleService.addRole(apiToken, values)
      if (roleAdded.error) {
        NotificationService.error('Error adding the role, please try again - ' + roleAdded.error)
      } else {
        NotificationService.success('Role added')
        if (roleAdded?.data?.[0]?.id) {
          history.push(`/role/${roleAdded.data[0].id}/edit`)
        } else {
          history.push('/role')
        }
      }
    } else {
      const roleUpdated = await RoleService.updateRole(apiToken, values)
      if (roleUpdated?.error) {
        NotificationService.error('Error updating the role, please try again - ' + roleUpdated.error)
      } else {
        NotificationService.success('Role updated')
        console.log('Role updated ', roleUpdated)
      }
    }
  }

  const goBack = () => {
    history.push('/roles')
  }

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')
  const activeSite = LocalStorageService.get('activeSite')

  // Basically componentDidMount
  useEffect(() => {
    let unmounted = false

    // Get Role details
    const getRoleDetails = async (userId) => {
      const roleDetails = await RoleService.getRole(apiToken, roleId, {
        orgId: activeOrg.id,
        siteId: activeSite?.id || null,
      })
      if (roleDetails.error) {
        NotificationService.error(roleDetails.error)
      } else {
        if (!unmounted) {
          setRoleDetails(roleDetails.data[0])
          console.log(roleDetails.data[0])
        }
      }
    }

    if (roleId !== 'new') {
      getRoleDetails(roleId)
    }

    // mapPermissions()

    return () => { unmounted = true }
  }, [])

  return (
    <Box>
      <Box background='white' elevation='small' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>
        <Box direction='column' pad={{ horizontal: 'medium' }}>
          <Text margin={{ top: 'small' }} size='xlarge'>Role: {roleDetails.id ? roleDetails.title : 'New Role'}</Text>
          <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'><Anchor href='/'>Snowdrop CRM</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></Text>
        </Box>
      </Box>

      <Box background='white' elevation='small' direction='column' gap='small' round='small'>
        <Box
          gap='small'
          margin={{ horizontal: 'small' }}
          pad='small'
          round='small'
        >
          <Box direction='column' gap='medium'>
            <Form
              onChange={nextValue => {
                // console.log(nextValue)
                setRoleDetails(nextValue)
              }}
              onSubmit={({ value: nextValue }) => {
                // console.log(nextValue)
                submitForm(nextValue)
              }}
              value={roleDetails}
            >
              <Text>Role Details</Text>
              <FormField
                label='Name'
                name='title'
                required
              >
                <TextInput
                  name='title'
                  type='text'
                />
              </FormField>

              <Box>
                <H4>Permissions</H4>
              </Box>

              <Grid
                rows={['auto', 'auto', 'auto', 'auto']}
                columns={['small', 'auto', 'auto', 'auto']}
                gap='small'
                areas={[
                  { name: 'main', start: [0, 0], end: [1, 1] }
                ]}
              >
                {buildPermissions()}
              </Grid>

              <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />

              <Box direction='row' justify='between' margin={{ top: 'medium' }}>
                <Button label='< Back' onClick={() => goBack()} secondary />
                <Button type='submit' label='Save' primary />
              </Box>
            </Form>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default RoleEdit
