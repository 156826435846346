import React, { useState, useEffect } from 'react'

import moment from 'moment'

// Spinner
import PulseLoader from 'react-spinners/PulseLoader'

// Appt Components
import Box from '../../../components/simple/box/Box'
import Button from '../../../components/simple/button/Button'
import DateInput from '../../../components/simple/input/DateInput'
import DataTable from '../../../components/simple/dataTable/DataTable'
import Divider from '../../../components/simple/divider/Divider'
import FontAwesome from '../../../components/compound/fontAwesome/FontAwesome'
import Form from '../../../components/simple/form/Form.js'
import FormField from '../../../components/simple/formField/FormField.js'
import H2 from '../../../components/simple/heading/H2.js'
import OptionButtons from '../../../components/compound/optionButtons/OptionButtons'
import Text from '../../../components/simple/text/Text'
import TextArea from '../../../components/simple/input/TextArea'
import TextInput from '../../../components/simple/input/TextInput'

function Hopes (props) {
  const [loading, setLoading] = useState(false)

  const [questions, setQuestions] = useState(props.questions)
  const [localFormDetails, setLocalFormDetails] = useState(props.formDetails)

  // Determine if hopes goal Add button is enabled or not
  const [hopesGoalAddButton, setHopesGoalAddButton] = useState(true)
  // Array of hopes goal
  const [hopesGoals, setHopesGoals] = useState([])
  // Array of hopes goals mapped for DataTable
  const [mappedHopesGoals, setMappedHopesGoals] = useState([])
  // DataTable columns for hopes goals
  const [hopesGoalsColumns, setHopesGoalsDataColumns] = useState()

  const previousPage = () => {
    props.previousPage()
  }

  const submitForm = () => {
    props.submitForm(localFormDetails, 'Draft')
  }

  // Hopes Goals management functions
  const addHopesGoal = () => {
    // Don't save if all fields empty
    if (localFormDetails.hopesGoal !== '' || localFormDetails.hopesGoalAction !== '' || localFormDetails.hopesGoalActionee !== '' || localFormDetails.hopesGoalDate !== '') {
      const event = {
        hopesGoal: localFormDetails.hopesGoal,
        hopesGoalAction: localFormDetails.hopesGoalAction,
        hopesGoalActionee: localFormDetails.hopesGoalActionee,
        hopesGoalDate: localFormDetails.hopesGoalDate
      }

      setHopesGoals(hopesGoals => [...hopesGoals, event])

      // Clear input fields
      setLocalFormDetails(localFormDetails => ({
        ...localFormDetails,
        hopesGoal: '',
        hopesGoalAction: '',
        hopesGoalActionee: '',
        hopesGoalDate: ''
      }))

      setHopesGoalAddButton(true)
    }
  }

  const removeHopesGoal = (data) => {
    const newEvent = hopesGoals.filter((item) => item.hopesGoal !== data.hopesGoal)

    setHopesGoals(newEvent)
  }

  const editHopesGoal = (data) => {
    setLocalFormDetails(localFormDetails => ({
      ...localFormDetails,
      hopesGoal: data.hopesGoal,
      hopesGoalAction: data.hopesGoalAction,
      hopesGoalActionee: data.hopesGoalActionee,
      hopesGoalDate: data.hopesGoalDate
    }))

    removeHopesGoal(data)
    setHopesGoalAddButton(false)
  }

  // Hopes Goals have been altered
  // so rebuild the display list
  useEffect(() => {
    setHopesGoalsDataColumns([
      {
        property: 'hopesGoal',
        header: <Text pad='none' margin='none'>Goal</Text>
      },
      {
        property: 'hopesGoalAction',
        header: <Text>What action is needed?</Text>
      },
      {
        property: 'hopesGoalActionee',
        header: <Text pad='none' margin='none'>Who is going to do this?</Text>
      },
      {
        property: 'hopesGoalDate',
        header: <Text pad='none' margin='none'>By when?</Text>
      },
      {
        property: 'actions',
        render: datum => (
          <Box direction='row'>
            <Button label={<Text><FontAwesome icon={['fal', 'minus-circle']} /></Text>} margin={{ horizontal: 'xsmall' }} style={{ background: '#333' }} onClick={() => removeHopesGoal(datum)} target='_self' />
            <Button label={<Text><FontAwesome icon={['fal', 'edit']} /></Text>} margin={{ horizontal: 'xsmall' }} style={{ background: '#333' }} onClick={() => editHopesGoal(datum)} target='_self' />
          </Box>
        )
      }
    ])

    const mappedHopesGoals = hopesGoals.map((event, index) => ({
      hopesGoal: event.hopesGoal,
      hopesGoalAction: event.hopesGoalAction,
      hopesGoalActionee: event.hopesGoalActionee,
      hopesGoalDate: moment(event.hopesGoalDate).format('YYYY-MM-DD')
    }))

    setMappedHopesGoals(mappedHopesGoals)

    // Stringify hopes goals and store them
    const hopesGoalsToSave = JSON.stringify(hopesGoals)

    setLocalFormDetails(localFormDetails => ({
      ...localFormDetails,
      hopesGoals: hopesGoalsToSave
    }))
  }, [hopesGoals])

  return (
    <Box gridArea='main' background='white' direction='column' gap='small' round='small'>
      <Divider color='primary'/>
      <Box
        gap='small'
        margin={{ horizontal: 'small' }}
        pad='small'
        round='small'
      >

        <Box fill direction='row' gap='small'>
          <H2 margin={{ vertical: 'none' }}>Hopes and Security</H2>
        </Box>

        <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
        {localFormDetails && questions.length
          ? (
            <Form
              onChange={nextValue => {
                setLocalFormDetails(nextValue)
                setHopesGoalAddButton(nextValue?.hopesGoal === '' || nextValue?.hopesGoalAction === '' || nextValue?.hopesGoalActionee === '' || nextValue?.hopesGoalDate === '')
              }}
              onSubmit={({ value: nextValue }) => {
                submitForm(nextValue)
              }}
              value={localFormDetails}
            >
              <Box direction='row-responsive' gap='small'>
                {/* hopes */}
                <FormField
                  label={questions[0].questionText}
                  name={questions[0].key}
                  width='100%'
                >
                  <TextArea
                    name={questions[0].key}
                  />
                </FormField>
              </Box>

              <Box direction='row-responsive' gap='small'>
                {/* hopesRisk */}
                <FormField
                  label={questions[1].questionText}
                  name={questions[1].key}
                >
                  <OptionButtons
                    id={questions[1].key}
                    name={questions[1].key}
                  />
                </FormField>
              </Box>

              {localFormDetails.hopesRisk === 'Yes' &&
                <Box direction='row-responsive' gap='small'>
                  {/* hopesRiskReason */}
                  <FormField
                    label={questions[2].questionText}
                    name={questions[2].key}
                    width='100%'
                  >
                    <TextArea
                      name={questions[2].key}
                    />
                  </FormField>
                </Box>}

              <Divider color='primary' margin={{ top: 'medium', bottom: 'small' }} />

              <Text margin={{ top: 'xsmall' }} size='large'>Goals</Text>

              {mappedHopesGoals.length > 0 &&
                <Box direction='row-responsive' gap='medium'>
                  <DataTable
                    columns={hopesGoalsColumns}
                    data={mappedHopesGoals}
                    fill='horizontal'
                    size='large'
                  />
                </Box>}

              <Box direction='row-responsive' gap='medium'>
                {/* hopesGoal */}
                <FormField
                  label={questions[3].questionText}
                  name={questions[3].key}
                >
                  <TextInput
                    name={questions[3].key}
                    type='text'
                  />
                </FormField>

                {/* hopesGoalAction */}
                <FormField
                  label={questions[4].questionText}
                  name={questions[4].key}
                >
                  <TextInput
                    name={questions[4].key}
                    type='text'
                  />
                </FormField>

                {/* hopesGoalActionee */}
                <FormField
                  label={questions[5].questionText}
                  name={questions[5].key}
                >
                  <TextInput
                    name={questions[5].key}
                    type='text'
                  />
                </FormField>

                {/* hopesGoalDate */}
                <FormField
                  label={questions[6].questionText}
                  name={questions[6].key}
                >
                  <DateInput
                    name={questions[6].key}
                  />
                </FormField>

                <Button disabled={hopesGoalAddButton} label={<Text>Add</Text>} color='primary' onClick={() => addHopesGoal()} style={{ height: '35px' }} alignSelf='end' margin={{ bottom: 'small' }} />
              </Box>

              <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />

              <Box direction='row' justify='between' margin={{ top: 'medium' }}>
                <Button label='< Back' onClick={() => previousPage()} secondary />
                <Button type='submit' label='Next Page' primary />
              </Box>
            </Form>)
          : (
            <Box align='center'>
              <PulseLoader
                size={15}
                color='orange'
                loading={loading}
              />
            </Box>)}
      </Box>
    </Box>
  )
}
export default Hopes
