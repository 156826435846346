import React, { useState } from 'react'

// Grommet
import { ResponsiveContext } from 'grommet'
// Spinner
import PulseLoader from 'react-spinners/PulseLoader'

// Appt Components
import Box from '../../../components/simple/box/Box'
import Button from '../../../components/simple/button/Button'
import Form from '../../../components/simple/form/Form.js'
import FormField from '../../../components/simple/formField/FormField.js'
import Divider from '../../../components/simple/divider/Divider'
import H2 from '../../../components/simple/heading/H2.js'
import OptionButtons from '../../../components/compound/optionButtons/OptionButtons'
import TextArea from '../../../components/simple/input/TextArea'
import TextInput from '../../../components/simple/input/TextInput'

function Health (props) {
  const [loading, setLoading] = useState(false)

  const [questions, setQuestions] = useState(props.questions)
  const [localFormDetails, setLocalFormDetails] = useState(props.formDetails)

  const previousPage = () => {
    props.previousPage()
  }

  const submitForm = () => {
    props.submitForm(localFormDetails, 'Draft')
  }

  return (
    <Box gridArea='main' background='white' direction='column' gap='small' round='small'>
      <Divider color='primary' />
      <Box
        gap='small'
        margin={{ horizontal: 'small' }}
        pad='small'
        round='small'
      >

        <Box fill direction='row' gap='small'>
          <H2 margin={{ vertical: 'none' }}>Health</H2>
        </Box>

        <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
        {localFormDetails && questions.length
          ? (
            <Form
              onChange={nextValue => {
                setLocalFormDetails(nextValue)
              }}
              onSubmit={({ value: nextValue }) => {
                submitForm(nextValue)
              }}
              value={localFormDetails}
            >
              <ResponsiveContext.Consumer>
                {responsive => (
                  <Box direction='row-responsive' gap='small'>
                    <Box width={responsive === 'small' ? '100%' : '50%'}>
                      {/* GP name */}
                      <FormField
                        label={questions[0].questionText}
                        name={questions[0].key}
                      >
                        <TextInput
                          name={questions[0].key}
                          type='text'
                        />
                      </FormField>
                    </Box>

                    <Box width={responsive === 'small' ? '100%' : '50%'}>
                      {/* GP Phone */}
                      <FormField
                        label={questions[1].questionText}
                        name={questions[1].key}
                      >
                        <TextInput
                          name={questions[1].key}
                          type='tel'
                        />
                      </FormField>
                    </Box>
                  </Box>)}
              </ResponsiveContext.Consumer>

              <ResponsiveContext.Consumer>
                {responsive => (
                  <Box direction='row-responsive' gap='small'>
                    <Box width={responsive === 'small' ? '100%' : '50%'}>
                      {/* Dentist name */}
                      <FormField
                        label={questions[2].questionText}
                        name={questions[2].key}
                      >
                        <TextInput
                          name={questions[2].key}
                          type='text'
                        />
                      </FormField>
                    </Box>

                    <Box width={responsive === 'small' ? '100%' : '50%'}>
                      {/* Dentist phone */}
                      <FormField
                        label={questions[3].questionText}
                        name={questions[3].key}
                      >
                        <TextInput
                          name={questions[3].key}
                          type='tel'
                        />
                      </FormField>
                    </Box>
                  </Box>)}
              </ResponsiveContext.Consumer>

              <ResponsiveContext.Consumer>
                {responsive => (
                  <Box direction='row-responsive' gap='small'>
                    <Box width={responsive === 'small' ? '100%' : '50%'}>
                      {/* Counsellor name */}
                      <FormField
                        label={questions[4].questionText}
                        name={questions[4].key}
                      >
                        <TextInput
                          name={questions[4].key}
                          type='text'
                        />
                      </FormField>
                    </Box>

                    <Box width={responsive === 'small' ? '100%' : '50%'}>
                      {/* Counsellor phone */}
                      <FormField
                        label={questions[5].questionText}
                        name={questions[5].key}
                      >
                        <TextInput
                          name={questions[5].key}
                          type='tel'
                        />
                      </FormField>
                    </Box>
                  </Box>)}
              </ResponsiveContext.Consumer>

              <ResponsiveContext.Consumer>
                {responsive => (
                  <Box direction='row-responsive' gap='small'>
                    <Box width={responsive === 'small' ? '100%' : '50%'}>
                      {/* Other health name */}
                      <FormField
                        label={questions[6].questionText}
                        name={questions[6].key}
                      >
                        <TextInput
                          name={questions[6].key}
                          type='text'
                        />
                      </FormField>
                    </Box>

                    <Box width={responsive === 'small' ? '100%' : '50%'}>
                      {/* Other health phone */}
                      <FormField
                        label={questions[7].questionText}
                        name={questions[7].key}
                      >
                        <TextInput
                          name={questions[7].key}
                          type='tel'
                        />
                      </FormField>
                    </Box>
                  </Box>)}
              </ResponsiveContext.Consumer>

              <Box direction='row' gap='small'>
                {/* Physical disability */}
                <FormField
                  label={questions[8].questionText}
                  name={questions[8].key}
                  width='100%'
                >
                  <TextArea
                    name={questions[8].key}
                  />
                </FormField>
              </Box>

              <Box direction='row' gap='small'>
                {/* Health details */}
                <FormField
                  label={questions[9].questionText}
                  name={questions[9].key}
                >
                  <TextArea
                    name={questions[9].key}
                  />
                </FormField>
              </Box>

              <Box direction='row-responsive' gap='small'>
                {/* Collect medication */}
                <FormField
                  label={questions[10].questionText}
                  name={questions[10].key}
                >
                  <OptionButtons
                    id={questions[10].key}
                    name={questions[10].key}
                  />
                </FormField>
              </Box>

              <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />

              <Box direction='row' justify='between' margin={{ top: 'medium' }}>
                <Button label='< Back' onClick={() => previousPage()} secondary />
                <Button type='submit' label='Next Page' primary />
              </Box>
            </Form>)
          : (
            <Box align='center'>
              <PulseLoader
                size={15}
                color='orange'
                loading={loading}
              />
            </Box>)}
      </Box>
    </Box>
  )
}
export default Health
