import React, { useState, useEffect } from 'react'

import { useHistory, useParams } from 'react-router-dom'

import FormService from '../services/FormService'
import LocalStorageService from '../services/LocalStorageService'
import NotificationService from '../services/NotificationService'
import UserService from '../services/UserService'
import UserTagService from '../services/UserTagService'

// Spinner
import PulseLoader from 'react-spinners/PulseLoader'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// utils
import { capitalize } from '../utils/text'

// Appt Components
import Anchor from '../components/simple/anchor/Anchor'
import Box from '../components/simple/box/Box'
import Button from '../components/simple/button/Button'
import Divider from '../components/simple/divider/Divider'
import Meter from '../components/simple/meter/Meter'
import Tabs from '../components/simple/tabs/Tabs.js'
import Text from '../components/simple/text/Text'

import BehaviourDetails from './BehaviourPlan-BehaviourDetails'
import BehaviourTechniques from './BehaviourPlan-BehaviourTechniques'
import FollowUp from './BehaviourPlan-FollowUp'
// import PhysicalTechniques from './IncidentEdit-PhysicalTechniques'
// import PostIncidentLearning from './IncidentEdit-PostIncidentLearning'
import ReportSend from './BehaviourPlan-ReportSend'
import RiskAssessment from './BehaviourPlan-RiskAssessment'

function BehaviourPlan (props) {
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [userValues, setUserValues] = useState([])
  const [studentValues, setStudentValues] = useState([])
  // const [submissionResponses, setSubmissionResponses] = useState([])
  const [behaviourPlanValues, setBehaviourPlanValues] = useState({
    // Risk Assessment
    studentName: '',
    dateOfPlan: '',
    reviewDate: '',
    completedBy: '',
    additionalAdjustments: '',
    triggers: '',
    triggerSupport: '',
    // Behaviour Details
    lowLevelBehaviour: '',
    lowLevelTried: '',
    lowLevelOtherThings: '',
    lowLevelHelpMyself: '',
    mediumLevelBehaviour: '',
    mediumLevelTried: '',
    mediumLevelOtherThings: '',
    mediumLevelHelpMyself: '',
    highLevelBehaviour: '',
    highLevelTried: '',
    highLevelOtherThings: '',
    highLevelHelpMyself: '',
    // FollowUp
    recoveryIShow: '',
    helpMyself: '',
    staffHelp: '',
    interests: '',
    factors: '',
    otherInformation: '',
    // Behaviour Management Techniques
    calmTalking: '',
    calmTalkingNotes: '',
    optionsOffered: '',
    optionsOfferedNotes: '',
    plannedIgnoring: '',
    plannedIgnoringNotes: '',
    successReminded: '',
    successRemindedNotes: '',
    contingentTouch: '',
    contingentTouchNotes: '',
    listening: '',
    listeningNotes: '',
    negotiation: '',
    negotiationNotes: '',
    takeUpTime: '',
    takeUpTimeNotes: '',
    empathy: '',
    empathyNotes: ''
  })

  const [notifications, setNotifications] = useState([])

  const [userDetails, setUserDetails] = useState({
    organisation: 0,
    role: 0,
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    jobtitle: ''
  })

  const [keyContacts, setKeyContacts] = useState([])

  const [meterValue, setMeterValue] = useState()

  const [questions, setQuestions] = useState([
    // Risk Assessment
    {
      tabId: 0,
      questions: [
        {
          questionText: `${capitalize(props?.terms?.serviceUser || 'Client')} Name`,
          key: 'studentName'
        },
        {
          questionText: 'Date of Plan',
          key: 'dateOfPlan'
        },
        {
          questionText: 'Review Date of Plan',
          key: 'reviewDate'
        },
        {
          questionText: 'Completed By',
          key: 'completedBy'
        },
        {
          questionText: 'What should be done additionally?',
          key: 'additionalAdjustments'
        },
        {
          questionText: 'Triggers',
          key: 'triggers'
        },
        {
          questionText: 'Support for Triggers',
          key: 'triggerSupport'
        }
      ]
    },
    // Behaviour Details
    {
      tabId: 1,
      questions: [
        {
          questionText: 'Low Level Behaviour I show',
          key: 'lowLevelBehaviour'
        },
        {
          questionText: "What's been tried?",
          key: 'lowLevelTried'
        },
        {
          questionText: 'Other things that could be tried',
          key: 'lowLevelOtherThings'
        },
        {
          questionText: 'What can I do to help myself?',
          key: 'lowLevelHelpMyself'
        },
        {
          questionText: 'Medium Level Behaviour I show',
          key: 'mediumLevelBehaviour'
        },
        {
          questionText: "What's been tried?",
          key: 'mediumLevelTried'
        },
        {
          questionText: 'Other things that could be tried',
          key: 'mediumLevelOtherThings'
        },
        {
          questionText: 'What can I do to help myself?',
          key: 'mediumLevelHelpMyself'
        },
        {
          questionText: 'High Level Behaviour I show',
          key: 'highLevelBehaviour'
        },
        {
          questionText: "What's been tried?",
          key: 'highLevelTried'
        },
        {
          questionText: 'Other things that could be tried',
          key: 'highLevelOtherThings'
        },
        {
          questionText: 'What can I do to help myself?',
          key: 'highLevelHelpMyself'
        }
      ]
    },
    // Follow Up
    {
      tabId: 2,
      questions: [
        {
          questionText: 'Recovery Behaviour I sometimes show',
          key: 'recoveryIShow'
        },
        {
          questionText: 'What can I do to help myself?',
          key: 'helpMyself'
        },
        {
          questionText: 'What can staff do to help me?',
          key: 'staffHelp'
        },
        {
          questionText: 'What interests me?',
          key: 'interests'
        },
        {
          questionText: 'Are there any factors to consider when debriefing?',
          key: 'factors'
        },
        {
          questionText: 'Any other information',
          key: 'otherInformation'
        }
      ]
    },
    // Behaviour Management Techniques
    {
      tabId: 3,
      questions: [
        {
          questionText: '#1: C.A.L.M talking',
          key: 'calmTalking'
        },
        {
          questionText: 'Notes',
          key: 'calmTalkingNotes'
        },
        {
          questionText: '#2: Options Offered',
          key: 'optionsOffered'
        },
        {
          questionText: 'Notes',
          key: 'optionsOfferedNotes'
        },
        {
          questionText: '#3: Planned Ignoring',
          key: 'plannedIgnoring'
        },
        {
          questionText: 'Notes',
          key: 'plannedIgnoringNotes'
        },
        {
          questionText: '#4: Success Reminded',
          key: 'successReminded'
        },
        {
          questionText: 'Notes',
          key: 'successRemindedNotes'
        },
        {
          questionText: '#5: Contingent touch',
          key: 'contingentTouch'
        },
        {
          questionText: 'Notes',
          key: 'contingentTouchNotes'
        },
        {
          questionText: '#1: Listening',
          key: 'listening'
        },
        {
          questionText: 'Notes',
          key: 'listeningNotes'
        },
        {
          questionText: '#2: Negotiation',
          key: 'negotiation'
        },
        {
          questionText: 'Notes',
          key: 'negotiationNotes'
        },
        {
          questionText: '#3: Negotiation???',
          key: 'negotiation?'
        },
        {
          questionText: 'Notes',
          key: 'negotiationNotes'
        },
        {
          questionText: '#4: Take up time',
          key: 'takeUpTime'
        },
        {
          questionText: 'Notes',
          key: 'takeUpTimeNotes'
        },
        {
          questionText: '#5: Empathy',
          key: 'empathy'
        },
        {
          questionText: 'Notes',
          key: 'empathyNotes'
        }
      ]
    },
    {
      tabId: 4,
      questions: [
        {
          questionText: 'Which key contacts should receive a copy of this report?',
          key: 'notify'
        }
      ]
    }
  ])

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrganisation = LocalStorageService.get('activeOrg')

  const params = useParams()

  const userId = params.userid

  // Tab controls
  const [tabIndex, setTabIndex] = useState(0)
  const onActiveTab = (nextIndex) => {
    setMeterValue((nextIndex + 1) * 100 / questions.length)
    setTabIndex(nextIndex)
  }

  const tabContent = [
    {
      name: 'Behaviour Plan',
      hideTitle: true,
      content: <RiskAssessment behaviourPlanValues={behaviourPlanValues} questions={questions[0].questions} setBehaviourPlanValues={setBehaviourPlanValues} setStudentValues={setStudentValues} studentValues={studentValues} userId={userId} userValues={userValues} setUserValues={setUserValues} />
    },
    {
      name: 'Behaviour Details',
      hideTitle: true,
      content: <BehaviourDetails behaviourPlanValues={behaviourPlanValues} questions={questions[1].questions} setBehaviourPlanValues={setBehaviourPlanValues} />
    },
    {
      name: 'Follow Up Support',
      hideTitle: true,
      content: <FollowUp behaviourPlanValues={behaviourPlanValues} questions={questions[2].questions} setBehaviourPlanValues={setBehaviourPlanValues} />
    },
    {
      name: 'Behaviour Management Techniques',
      hideTitle: true,
      content: <BehaviourTechniques behaviourPlanValues={behaviourPlanValues} questions={questions[3].questions} setBehaviourPlanValues={setBehaviourPlanValues} />
    },
    {
      name: 'Send Report',
      hideTitle: true,
      content: <ReportSend contacts={keyContacts} questions={questions[4].questions} setNotifications={setNotifications} />
    }
  ]

  const getContacts = async () => {
    const params = {
      fields: 'user,type,key,organisation,value'
    }

    const where = {
      user: behaviourPlanValues.studentName
    }

    let contacts = await UserTagService.get(apiToken, params, where)

    if (contacts) {
      // Filter out the 'keycontacts'
      contacts = contacts.filter((item) => item.key === 'keycontacts')
    }

    if (contacts) {
      const parsedContacts = JSON.parse(contacts[0].value)
      const mappedContacts = parsedContacts.map((contact, index) => ({
        display: <><Box direction='column'><Text weight='bold'>{contact.firstName} {contact.lastName}</Text><Text>{contact.role}</Text></Box></>,
        email: contact.email,
        firstName: contact.firstName,
        lastName: contact.lastName,
        name: contact.firstName + ' ' + contact.lastName,
        role: contact.role
      }))

      console.log('saved contacts ', mappedContacts)

      setKeyContacts(mappedContacts)
    }
  }

  // Check if the passed in input field has any value
  const checkInputHasContent = (input) => {
    let inputHasContent = false

    switch (typeof (input)) {
      case 'string':
        inputHasContent = input.length > 0
        break
      case 'number':
        inputHasContent = input > 0
        break
      case 'boolean':
        break
      case 'object':
        inputHasContent = input.length > 0
        break
    }

    return inputHasContent
  }

  // Submit form values
  const submitForm = async (draft) => {
    console.log('submit', behaviourPlanValues)

    var submitArray = []
    var item = {
      key: '',
      questionText: '',
      answer: ''
    }

    // Loop through each form value
    for (var key in behaviourPlanValues) {
      // And questions on each page of the form
      questions.forEach(function (pageOfQuestions, index) {
        // To match them up
        var found = pageOfQuestions.questions.find(question => question.key === key)

        if (found && checkInputHasContent(behaviourPlanValues[key])) {
          item = {
            questionKey: key,
            questionText: found.questionText,
            response: behaviourPlanValues[key]
          }
          submitArray.push(item)
        }
      })
    }

  //   // Get action button result
  //   // submitArray.push({ key: 'bestInterestQuestion', questionText: 'Was your action in the best interest of the [student]?', answer: actionButton })
  //   // console.log(submitArray)

  //   // Add interventions if there are any
  //   if (interventions.length > 0) {
  //     const injuries = []

  //     interventions.forEach(intervention => {
  //       injuries.push(intervention)
  //     })

  //     submitArray.push({
  //       // organisation: activeOrganisation.id,
  //       questionKey: 'interventions',
  //       questionText: 'Interventions',
  //       response: JSON.stringify(injuries)
  //     })
  //   }

  //   // Add notifications
  //   if (notifications.length > 0) {
  //     var notifyArray = []

  //     let contactFound = {}

  //     // Build array of notifications
  //     notifications.forEach(notification => {
  //       contactFound = keyContacts.find((contact) => contact.email === notification)
  //       notifyArray.push({
  //         firstName: contactFound.firstName,
  //         lastName: contactFound.lastName,
  //         email: contactFound.email
  //       })
  //     })
  //   }

    // Build output data
    const data = {
      user: behaviourPlanValues.studentName,
      organisation: activeOrganisation.id,
      status: draft ? 'Draft' : 'Published',
      type: 'behaviourplan',
      reference: '',
      // notify: JSON.stringify(notifyArray),
      responses: submitArray
    }

    console.log(data)
    // if (userId === 'new') {
      console.log('Notify ', notifications)
      var submissionAdded = await FormService.makeSubmissions(apiToken, data)
      if (submissionAdded.error) {
        NotificationService.error(submissionAdded.error)
      } else {
        NotificationService.info('Behaviour Plan added')
        // history.push('/users', { message: userAdded.data.message })
      }
    // }
  }

  // Map the form values into format required for api submission
  //   const mapForm = (values) => {
  //     var newArray = []
  //     var newObject = {
  //       key: '',
  //       questionText: '',
  //       answer: ''
  //     }
  //     for (var key in values) {
  //       var found = questions.find(q => q.key === key)
  //       if (found) {
  //         console.log(found.questionText)
  //         newObject = {
  //           key: key,
  //           questionText: found.questionText,
  //           answer: values[key]
  //         }
  //         newArray.push(newObject)
  //       }
  //     }
  //     console.log(newArray)
  //   }

  // Go Back
  const goBack = () => {
    history.goBack()
  }

  const previousPage = () => {
    if (tabIndex === 0) {
      goBack()
    } else {
      const prevPageIndex = tabIndex - 1
      onActiveTab(prevPageIndex)
    }
  }

  const nextPage = () => {
    if (tabIndex === tabContent.length - 1) {
      // submitForm()
    } else {
      const nextPageIndex = tabIndex + 1
      onActiveTab(nextPageIndex)
    }
  }

  // Basically componentDidMount
  useEffect(() => {
    let unmounted = false

    const getUsers = async () => {
      const params = {
        fields: 'id,firstName,lastName,reference,type',
        limit: 1000
      }

      setLoading(true)
      const users = await UserService.getUsers(apiToken, params)
      if (users?.error) {
        NotificationService.error(users.error)
      } else {
        if (!unmounted) {
          if (users?.error) {
            setUserValues(null)
          } else if (users?.data) {
            // Get system users
            const mappedUsers = users.data.filter((user) => user.type !== 'student').map((data, index) => ({
              id: data.id,
              learnerNumber: data.reference,
              name: data.firstName + ' ' + data.lastName
            }))
            // const mappedUsers = systemUsers.map((data, index) => ({
            //   id: data.id,
            //   learnerNumber: data.reference,
            //   name: data.firstName + ' ' + data.lastName
            // }))

            setUserValues(mappedUsers)
            // console.log(mappedUsers)
            // Now get students
            const studentUsers = users.data.filter((user) => user.type === 'student').map((data, index) => ({
              id: data.id,
              learnerNumber: data.reference,
              name: data.firstName + ' ' + data.lastName
            }))

            setStudentValues(studentUsers)
          }
        }
      }
    }

    const getUserDetails = async (userId) => {
      const userDetails = await UserService.getUser(apiToken, userId)
      if (userDetails?.error) {
        NotificationService.error(userDetails.error)
      } else {
        if (!unmounted) {
          if (userDetails?.data) {
            setUserDetails(userDetails.data[0])
          }
        }
      }
    }

    const getUserSubmissions = async (userId) => {
      const params = {
        fields: 'id,user,organisation,reference,type',
        limit: 1000,
        sort: 'createdAt DESC'
      }

      let where = {
        status: 'Published',
        type: 'incidentreport',
        user: userId
      }

      const submissions = await FormService.getSubmissions(apiToken, params, where)
      if (submissions?.error) {
        NotificationService.error(submissions.error)
      } else {
        if (!unmounted) {
          if (submissions?.data) {
            where = {
              submission: submissions.data[0].id
            }
            const responses = await FormService.getResponses(apiToken, params, where)
            if (responses?.error) {
              NotificationService.error(responses.error)
            } else {
              const behaviourResponses = {}
              responses.data.forEach((item) => {
                if (item.questionKey === 'lowLevelBehaviour') {
                  behaviourResponses.lowLevelResponse = item.response
                  console.log(item.response)
                }
                if (item.questionKey === 'mediumLevelBehaviour') {
                  behaviourResponses.mediumLevelResponse = item.response
                  console.log(item.response)
                }
                if (item.questionKey === 'highLevelBehaviour') {
                  behaviourResponses.highLevelResponse = item.response
                  console.log(item.response)
                }
              })
              setBehaviourPlanValues(behaviourValues => ({
                ...behaviourValues,
                lowLevelBehaviour: behaviourResponses.lowLevelResponse,
                mediumLevelBehaviour: behaviourResponses.mediumLevelResponse,
                highLevelBehaviour: behaviourResponses.highLevelResponse
              }))

              // setSubmissionResponses(responses.data)
            }
          }
        }
      }
    }

    getUsers('student')

    if (userId !== 'new') {
      getUserDetails(userId)
      getUserSubmissions(userId)

      setBehaviourPlanValues(behaviourValues => ({
        ...behaviourValues,
        studentName: parseInt(userId)
      }))
    }

    setLoading(false)

    // Set initial Meter setting
    setMeterValue(100 / questions.length)

    return () => { unmounted = true }
  }, [])

  useEffect(() => {
    if (behaviourPlanValues.studentName) {
      getContacts()
    }
  }, [behaviourPlanValues.studentName])

  return (
    <Box width='xlarge'>
      <Box background='white' elevation='small' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>
        <Box direction='column' pad={{ horizontal: 'medium' }}>
          <Text margin={{ top: 'small' }} size='xlarge'>{userId === 'new' ? 'New ' : ''}New Behaviour Plan: {userDetails.firstName} {userDetails.lastName}</Text>
          <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'><Anchor href='/'>Snowdrop CRM</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></Text>
        </Box>
      </Box>
      <Box gridArea='main' background='white' direction='column' gap='small' round='small'>
        <Box
          gap='small'
          margin={{ horizontal: 'small' }}
          pad='small'
          round='small'
        >
          <Meter background='light-2' size='full' thickness='xsmall' type='bar' values={[{ color: 'brand', value: meterValue }]} />
          {studentValues
            ? <Tabs activeIndex={tabIndex} content={tabContent} onActive={onActiveTab} full />
            : (
              <Box align='center'>
                <PulseLoader
                  size={15}
                  color='orange'
                  loading={loading}
                />
              </Box>)}

          <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />
          <Box direction='row' justify='between' margin={{ top: 'medium' }}>
            <Button label='< Back' onClick={() => previousPage()} secondary />
            {tabIndex < tabContent.length - 1 &&
              <Button label={<Text><FontAwesomeIcon icon={['fal', 'cloud-upload']} /> Save for later</Text>} onClick={() => submitForm(true)} />}
            <Button label={tabIndex !== tabContent.length - 1 ? 'Next Page' : 'Submit'} onClick={() => nextPage()} primary />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default BehaviourPlan
