import React, { useState } from 'react'

// Appt Components
import Select from '../../components/simple/input/Select'

const JoinBox = (props) => {
//   const apiToken = LocalStorageService.get('apiToken')
//   const activeOrg = LocalStorageService.get('activeOrg')

  const [queryOptions, setQueryOptions] = useState(['AND', 'OR'])

  return (
    <>
      <Select
        {...props}
        options={queryOptions}
      />
    </>
  )
}

export default JoinBox
