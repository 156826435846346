// React
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link, useLocation, useHistory } from 'react-router-dom'

import { Text, Button, Nav, ResponsiveContext, Sidebar as GSidebar } from 'grommet'
import styled from 'styled-components'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  Menu as MenuIcon
} from 'grommet-icons'

// Appt
import Box from '../../simple/box/Box.js'
import Divider from '../../simple/divider/Divider.js'
import Image from '../../simple/image/Image'
import Select from '../../simple/input/Select'

// Services
import LocalStorageService from '../../../services/LocalStorageService'

/**
 * `Sidebar` renders a Sidebar
 */
function Sidebar(props) {
  const location = useLocation()
  const history = useHistory()

  // const [selectedOrganisation, setSelectedOrganisation] = useState(props.selectedOrganisation)
  // const [selectedSite, setSelectedSite] = useState(props.selectedSite)
  // Load this string from localStorage
  const lastActiveIndexString = LocalStorageService.get('lastActiveIndex')

  // Parse it to a number
  const lastActiveIndex = Number(lastActiveIndexString)

  const [activeIndex, setActiveIndex] = useState(lastActiveIndex || 0)

  const [apiToken, setApiToken] = useState()

  const [userOrganisations, setUserOrganisations] = useState([])
  const [userSites, setUserSites] = useState([])
  const [activeOrganisation, setActiveOrganisation] = useState({})
  const [activeSite, setActiveSite] = useState({})
  const [sitesToDisplay, setSitesToDisplay] = useState([])


  const SidebarItem = styled(Button)`
    padding: 0px 5px;
    transition: all 0.25s ease-in-out;
    color: ${props => props.active ? '#F58466' : '#00788A'};
    font-size: 24px;
    letter-spacing: -1px;
    font-weight: ${props => props.active ? '500' : '300'};
    background: none;
    //margin: 4px 12px;
    //border-radius: 4px;
    text-align: left;

    p {
      color: blue;
      font-weight: bold;
      text-decoration: none;
      margin: 4px;
    }

    &:focus {
      outline: none
    }

    &:hover {
      cursor:pointer;
      // font-weight: 500;
    }

    //&:hover:not(:first-child) {
      //background: #c34a36;
    //}
  `

  const StyledLink = styled(Link)`
    text-decoration: none;

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;

    &:hover:not(:first-child) {
      // background: #c34a36;
    }
  `

  const selectChangedOrg = (nextValue) => {
    setActiveOrganisation(nextValue)
    props.selectActiveOrganisation(nextValue)
    history.push('/dashboard')
    window.location.reload()
  }

  const selectChangedSite = (nextValue) => {
    setActiveSite(nextValue)
    props.selectActiveSite(nextValue)
    history.push('/dashboard')
    window.location.reload()
  }

  const SidebarHeader = () => (
    <>
      <Box margin={{ top: 'small', bottom: 'small' }} height='175px'>
        <Image fit='contain' src={props.image} />
      </Box>
      {/* Only display the org selector if the user has more than one org */}
      {userOrganisations?.length > 0 &&
        <>
          <Text size='xsmall' color='grey'>Organisation</Text>

          <Select
            id='currentOrg'
            name='currentOrg'
            labelKey='name'
            value={activeOrganisation}
            valueKey={{ key: 'id' }}
            options={userOrganisations}
            onChange={({ value: nextValue }) => selectChangedOrg(nextValue)}
          />
        </>}
      {sitesToDisplay?.length > 0 &&
        <>
          <Text size='xsmall' color='grey'>Site</Text>
          <Select
            id='currentSite'
            name='currentSite'
            labelKey='name'
            value={activeSite}
            valueKey={{ key: 'id' }}
            options={sitesToDisplay}
            onChange={({ value: nextValue }) => selectChangedSite(nextValue)}
          />
        </>}
      <Divider color={props.dividerColor} margin={{ top: 'small', bottom: 'small' }} />
    </>
  )

  const SidebarFooter = () => (
    <Box pad={{ bottom: 'small' }}>
      <Divider color={props.dividerColor} margin={{ top: 'small', bottom: 'small' }} />
      <StyledLink key='logout' to='/logout'>
        <Text color='secondary' weight={600} margin={{ left: '10px', bottom: '20px' }}>Logout</Text>
      </StyledLink>
    </Box>
  )

  const MainNavigation = () => (
    <ResponsiveContext.Consumer>
      {responsive =>
        responsive === 'small' ? (
          <Button icon={<MenuIcon />} hoverIndicator onClick={() => alert('comingSoon')} />
          // <Box height='xsmall'>
          //   <DropButton
          //     label={<MenuIcon />}
          //     dropAlign={{ top: 'bottom', right: 'right' }}
          //     dropContent={props.data.map((item, index) => {
          //       const isActive = index === activeIndex

          //       const icon = (
          //         <Box background={isActive ? 'secondary' : 'focus'} width='35px' height='35px' round='large' gap='none' align='center' pad={{ top: '6px' }}>
          //           <FontAwesomeIcon icon={['fal', item.icon]} color='#fff' />
          //         </Box>
          //       )

          //       return (
          //         <StyledLink key={item.linkTitle} to={item.linkHref}>
          //           <SidebarItem
          //             alignSelf='start'
          //             gap='small'
          //             label={item.linkTitle}
          //             plain
          //             active={isActive}
          //             icon={icon}
          //             key={item.linkTitle}
          //           />
          //         </StyledLink>
          //       )
          //     })}
          //   />
          // </Box>
        ) : (
          <Nav gap='small' responsive={false}>
            {props.data.map((item, index) => {
              const isActive = index === activeIndex

              const icon = (
                <Box background='#fff' width='35px' height='35px' round='large' gap='none' align='center' pad={{ top: '6px' }}>
                  <FontAwesomeIcon icon={['fal', item.icon]} color={(isActive ? '#F58466' : '#00788A')} />
                </Box>
              )

              return (
                <StyledLink key={item.linkTitle} to={item.linkHref}>
                  <SidebarItem
                    alignSelf='start'
                    gap='small'
                    label={item.linkTitle}
                    plain
                    active={isActive}
                    icon={icon}
                    key={item.linkTitle}
                  />
                </StyledLink>
              )
            })}
          </Nav>
        )}
    </ResponsiveContext.Consumer>
  )

  const changeActiveIndex = (newIndex) => {
    LocalStorageService.set('lastActiveIndex', newIndex)
    setActiveIndex(newIndex)
    // setOrgValue(newIndex)
  }

  const getPath = (path) => {
    if (path.charAt(0) !== '/') {
      return '/' + path
    }
    return path
  }

  const getDefaultSelectedSite = (orgId, sites) => {
    return sites.find((site) => {
      return site.organisation === orgId && site.permissions
    })
  }

  useEffect(() => {
    const activeItem = props.data.findIndex(item => getPath(item.linkHref) === getPath(location.pathname))
    changeActiveIndex(activeItem)
  }, [location])

  useEffect(() => {
    // get api token from localstorage
    const localApiToken = LocalStorageService.get('apiToken')
    if (localApiToken) {
      setApiToken(localApiToken)
    }

  }, [])

  useEffect(() => {
    // get the users orgs an sites

    // get the users organisation and sites
    const storedOrganisations = LocalStorageService.get('userOrganisations')
    const storedSites = LocalStorageService.get('userSites')
    setUserOrganisations(storedOrganisations)
    setUserSites(storedSites)

    // get the active organisations and site
    // const storedActiveOrganisation = LocalStorageService.get('activeOrg')
    // setActiveOrganisation(storedActiveOrganisation)
    var newActiveOrganisation = LocalStorageService.get('activeOrg')
    if (!newActiveOrganisation) {
      newActiveOrganisation = storedOrganisations[0]
    }
    if (newActiveOrganisation && storedOrganisations?.length) {
      setActiveOrganisation(newActiveOrganisation)
    }
    // if there's no active site, get the default selectedSite
    var newActiveSite = LocalStorageService.get('activeSite')
    if (!newActiveSite && storedSites?.length) {
      newActiveSite = getDefaultSelectedSite(newActiveOrganisation.id, storedSites)
    }
    if (newActiveSite) {
      setActiveSite(newActiveSite)
    }

  }, [apiToken])


  useEffect(() => {
    var sitesToDisplay = []
    if (activeOrganisation?.id && userSites?.length) {
      // filter the user sites based on the selected organisation

      for (const site of userSites) {
        // return the site that match that org
        // ignore sites that have no permissions, we can't do anything with those
        if (site.organisation === activeOrganisation.id && site?.permissions?.length) {
          sitesToDisplay.push(site)
        }
      }
    }
    if (sitesToDisplay && sitesToDisplay.length) {
      setSitesToDisplay(sitesToDisplay)
      // only update the active site if the organisation has changed
      if (activeSite?.organisation !== activeOrganisation?.id && sitesToDisplay[0].permissions) {
        setActiveSite(sitesToDisplay[0])
      }
    }
  }, [activeOrganisation])

  return (
    <GSidebar
      gap='medium'
      elevation='small'
      header={
        <SidebarHeader />
      }
      footer={
        <SidebarFooter />
      }
      {...props}
    >
      <MainNavigation />
    </GSidebar>)
  // <>
  //   <Box background={props.background || '#fff'} elevation={props.elevation} pad='medium' round='small'>
  //     <Anchor alignSelf='center' href='/'>
  //       <Image fit='contain' src={props.image} />
  //     </Anchor>
  //     <Divider color={props.dividerColor} margin={{ top: 'medium', bottom: 'large' }} />
  //     {listData}
  //   </Box>
  // </>)
}

Sidebar.propTypes = {
  /**
   * Either a color identifier to use for the background Boxcolor.
   * For example: 'neutral-1'. Or, a 'url()' for an image. Dark is not needed if color is provided.
   *
   * "neutral-1"
   * "url(//my.com/assets/img.png)"
   * {
   * &nbsp;"color": "neutral-1",
   * &nbsp;"dark": true,
   * &nbsp;"opacity": true,
   * &nbsp;"position": "bottom",
   * &nbsp;"repeat": "no-repeat",
   * &nbsp;"size": "cover",
   * &nbsp;"image": "url(//my.com/assets/img.png)"
   * }
   * {"dark": "light-2", "light": "dark-2"}
   *
   * where opacity could be:
   *  &nbsp;"weak"
   *  &nbsp;"medium"
   *  &nbsp;"strong"
   *  &nbsp;true
   *
   * where position could be:
   *  &nbsp;any CSS for background-position
   *
   * where repeat could be:
   *  &nbsp;"no-repeat"
   *  &nbsp;"repeat"
   *  &nbsp;"string"
   *
   * where size could be:
   *  &nbsp;"cover"
   *  &nbsp;"contain"
   *  &nbsp;"string"
   */
  background: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  /**
   * Array of data objects.
   *
   * [
   * &nbsp;{
   * &nbsp;&nbsp;linkHref: "#"
   * &nbsp;&nbsp;linkColor: "dark-1"
   * &nbsp;&nbsp;linkTitle: "My Link"
   * &nbsp;}
   * ]
   */
  data: PropTypes.array,
  /**
   * Color of the divider line
   */
  dividerColor: PropTypes.string,
  /**
   * Elevated height above the underlying context for the backgorund Box, indicated via a drop shadow.
   *
   * **"none"**
   * "xsmall"
   * "small"
   * "medium"
   * "large"
   * "xlarge"
   * "any custom elevation name in the current theme"
   */
  elevation: PropTypes.string,
  /**
   * The image at the top of the Login screen
   */
  image: PropTypes.string
}

export default Sidebar
