import React, { useEffect, useState } from 'react'

import { constants } from '../../../utils/constants'

import moment from 'moment'

// Spinner
import PulseLoader from 'react-spinners/PulseLoader'

// Appt Components
import Box from '../../../components/simple/box/Box'
import Button from '../../../components/simple/button/Button'
import DataTable from '../../../components/simple/dataTable/DataTable'
import DateInput from '../../../components/simple/input/DateInput'
import Divider from '../../../components/simple/divider/Divider'
import FontAwesome from '../../../components/compound/fontAwesome/FontAwesome'
import Form from '../../../components/simple/form/Form.js'
import FormField from '../../../components/simple/formField/FormField.js'
import H2 from '../../../components/simple/heading/H2.js'
import H4 from '../../../components/simple/heading/H4.js'
import OptionButtons from '../../../components/compound/optionButtons/OptionButtons'
import RadioButtonGroup from '../../../components/simple/input/RadioButtonGroup'
import Text from '../../../components/simple/text/Text'
import TextInput from '../../../components/simple/input/TextInput'

function LegalStatus (props) {
  const [loading, setLoading] = useState(false)

  const [questions, setQuestions] = useState(props.questions)
  const [localFormDetails, setLocalFormDetails] = useState(props.formDetails)

  const [compensations, setCompensations] = useState([])
  const [compensationAddButton, setCompensationAddButton] = useState(false)

  const [mappedCompensation, setMappedCompensation] = useState([])
  const [compensationColumns, setCompensationDataColumns] = useState()

  const previousPage = () => {
    props.previousPage()
  }

  const submitForm = () => {
    props.submitForm(localFormDetails, 'Draft')
  }

  // Compensation management functions
  const addCompensation = () => {
    // Don't save if all fields empty
    if (localFormDetails.compensationType !== '' || localFormDetails.compensationDate !== '') {
      const compensation = {
        compensationKey: compensations.length,
        compensationType: localFormDetails.compensationType,
        compensationDate: localFormDetails.compensationDate
      }

      setCompensations(prevValues => [...prevValues, compensation])

      // Clear input fields
      setLocalFormDetails(localFormDetails => ({
        ...localFormDetails,
        compensationType: '',
        compensationDate: ''
      }))

      setCompensationAddButton(false)
    }
  }

  const ListCompensation = () => {
    if (mappedCompensation.length > 0) {
      return (
        <Box direction='row-responsive' gap='medium'>
          <DataTable
            columns={compensationColumns}
            data={mappedCompensation}
            fill='horizontal'
            size='large'
          />
        </Box>
      )
    } else {
      return null
    }
  }

  const removeCompensation = (data) => {
    const newCompensation = compensations.filter((item) => item.compensationKey !== data.compensationKey)

    // Renumber the compensation keys
    newCompensation.forEach((compensation, index) => {
      compensation.compensationKey = index
    })

    setCompensations(newCompensation)
  }

  const editCompensation = (data) => {
    setLocalFormDetails(localFormDetails => ({
      ...localFormDetails,
      compensationType: compensations[data.compensationKey].compensationType,
      compensationDate: compensations[data.compensationKey].compensationDate
      // moment(data.compensationDate).format('YYYY-MM-DD')
    }))

    setCompensationAddButton(true)
    removeCompensation(data)
  }

  // Compensation have been altered
  // so rebuild the display list
  useEffect(() => {
    setCompensationDataColumns([
      {
        property: 'compensationType',
        header: <Text pad='none' margin='none'>Compensation Type</Text>
      },
      {
        property: 'compensationDate',
        header: <Text>Date of Claim</Text>
      },
      {
        property: 'actions',
        render: datum => (
          <Box direction='row'>
            <Button label={<Text><FontAwesome icon={['fal', 'minus-circle']} /></Text>} margin={{ horizontal: 'xsmall' }} style={{ background: '#333' }} onClick={() => removeCompensation(datum)} target='_self' />
            <Button label={<Text><FontAwesome icon={['fal', 'edit']} /></Text>} margin={{ horizontal: 'xsmall' }} style={{ background: '#333' }} onClick={() => editCompensation(datum)} target='_self' />
          </Box>
        )
      }
    ])

    const mappedCompensation = compensations.map((item, index) => ({
      compensationKey: index,
      compensationType: item.compensationType,
      compensationDate: moment(item.compensationDate).format('DD-MM-YYYY')
    }))

    setMappedCompensation(mappedCompensation)

    // Stringify compensation and store them
    const compensationToSave = JSON.stringify(compensations)

    setLocalFormDetails(localFormDetails => ({
      ...localFormDetails,
      compensation: compensationToSave
    }))
  }, [compensations])

  return (
    <Box gridArea='main' background='white' direction='column' gap='small' round='small'>
      <Divider color='primary' />
      <Box
        gap='small'
        margin={{ horizontal: 'small' }}
        pad='small'
        round='small'
      >

        <Box fill direction='row' gap='small'>
          <H2 margin={{ vertical: 'none' }}>Legal Status</H2>
        </Box>

        <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
        {localFormDetails && questions.length
          ? (
            <Form
              onChange={nextValue => {
                setLocalFormDetails(nextValue)
                setCompensationAddButton(nextValue?.compensationType !== '' && nextValue?.compensationDate !== '')
              }}
              onSubmit={({ value: nextValue }) => {
                submitForm(nextValue)
              }}
              value={localFormDetails}
            >
              <Box direction='row-responsive' gap='small'>
                <FormField
                  name='legalStatus'
                >
                  <RadioButtonGroup
                    direction='row-responsive'
                    labelKey='label'
                    name='legalStatus'
                    // onChange={event => {
                    //   //   setToSend(event.value)
                    //   props.setNotifications(event.value)
                    //   console.log('Group1: ', event.value)
                    // }}
                    options={constants.immigrationStatus}
                    valueKey='value'
                  />
                </FormField>
              </Box>

              <Box direction='row-responsive' gap='small'>
                <FormField
                  label='Discretionary Leave'
                  name='discretionaryLeave'
                >
                  <RadioButtonGroup
                    direction='row-responsive'
                    labelKey='label'
                    name='discretionaryLeave'
                    // onChange={event => {
                    //   //   setToSend(event.value)
                    //   props.setNotifications(event.value)
                    //   console.log('Group1: ', event.value)
                    // }}
                    options={constants.discretionaryLeave}
                    valueKey='value'
                  />
                </FormField>
              </Box>

              {localFormDetails.legalStatus === 'asylumSeeker' &&
                <Box direction='row-responsive' gap='small'>
                  <FormField
                    label='If Asylum Seeker please indicate'
                    name='asylumClaim'
                  >
                    <RadioButtonGroup
                      direction='row-responsive'
                      labelKey='label'
                      name='asylumClaim'
                      // onChange={event => {
                      //   //   setToSend(event.value)
                      //   props.setNotifications(event.value)
                      //   console.log('Group1: ', event.value)
                      // }}
                      options={constants.housingStatus}
                      valueKey='value'
                    />
                  </FormField>
                </Box>}

              <Box direction='row-responsive' gap='small'>
                <FormField
                  label='Has the person made a claim for compensation?'
                  name='compensationClaim'
                >
                  <OptionButtons
                    id='compensationclaim'
                    name='compensationClaim'
                  />
                </FormField>

                {localFormDetails.compensationClaim === 'Yes' &&
                  <>
                    <Box direction='row' gap='small'>
                      <FormField
                        label='Type of compensation'
                        name='compensationType'
                      >
                        <TextInput
                          name='compensationType'
                          type='text'
                        />
                      </FormField>

                      <FormField
                        label='Date of claim'
                        name='compensationDate'
                      >
                        <DateInput
                          name='compensationDate'
                        />
                      </FormField>

                      <Button disabled={!compensationAddButton} label={<Text><FontAwesome icon={['fal', 'plus-circle']} /></Text>} color='primary' style={{ height: '35px' }} alignSelf='center' onClick={() => addCompensation()} margin={{ bottom: 'medium' }} />
                    </Box>
                  </>}

              </Box>
              <ListCompensation />

              <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />

              <H4>Solicitor Details</H4>
              <Box direction='column' gap='small'>
                <Text weight='bold'>Immigration/Asylum Solicitor Details</Text>

                <Box direction='row-responsive' gap='small'>
                  <FormField
                    label='Name'
                    name='immigrationAsylumSolicitor'
                  >
                    <TextInput
                      name='immigrationAsylumSolicitor'
                      type='text'
                    />
                  </FormField>

                  <FormField
                    label='Address'
                    name='immigrationAsylumSolicitorAddress'
                  >
                    <TextInput
                      name='immigrationAsylumSolicitorAddress'
                      type='text'
                    />
                  </FormField>

                  <FormField
                    label='Phone'
                    name='immigrationAsylumSolicitorPhone'
                  >
                    <TextInput
                      name='immigrationAsylumSolicitorPhone'
                      type='text'
                    />
                  </FormField>

                  <FormField
                    label='Email'
                    name='immigrationAsylumSolicitorEmail'
                  >
                    <TextInput
                      name='immigrationAsylumSolicitorEmail'
                      type='text'
                    />
                  </FormField>
                </Box>

                <Text weight='bold'>Compensation Solicitor Details</Text>

                <Box direction='row-responsive' gap='small'>
                  <FormField
                    label='Name'
                    name='compensationSolicitor'
                  >
                    <TextInput
                      name='compensationSolicitor'
                      type='text'
                    />
                  </FormField>

                  <FormField
                    label='Address'
                    name='compensationSolicitorAddress'
                  >
                    <TextInput
                      name='compensationSolicitorAddress'
                      type='text'
                    />
                  </FormField>

                  <FormField
                    label='Phone'
                    name='compensationSolicitorPhone'
                  >
                    <TextInput
                      name='compensationSolicitorPhone'
                      type='text'
                    />
                  </FormField>

                  <FormField
                    label='Email'
                    name='compensationSolicitorEmail'
                  >
                    <TextInput
                      name='compensationSolicitorEmail'
                      type='text'
                    />
                  </FormField>
                </Box>

                <Text weight='bold'>Criminal Solicitor Details</Text>

                <Box direction='row-responsive' gap='small'>
                  <FormField
                    label='Name'
                    name='criminalSolicitor'
                  >
                    <TextInput
                      name='criminalSolicitor'
                      type='text'
                    />
                  </FormField>

                  <FormField
                    label='Address'
                    name='criminalSolicitorAddress'
                  >
                    <TextInput
                      name='criminalSolicitorAddress'
                      type='text'
                    />
                  </FormField>

                  <FormField
                    label='Phone'
                    name='criminalSolicitorPhone'
                  >
                    <TextInput
                      name='criminalSolicitorPhone'
                      type='text'
                    />
                  </FormField>

                  <FormField
                    label='Email'
                    name='criminalSolicitorEmail'
                  >
                    <TextInput
                      name='criminalSolicitorEmail'
                      type='text'
                    />
                  </FormField>
                </Box>
              </Box>

              <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />

              <Box direction='row' justify='between' margin={{ top: 'medium' }}>
                <Button label='< Back' onClick={() => previousPage()} secondary />
                <Button type='submit' label='Next Page' primary />
              </Box>
            </Form>)
          : (
            <Box align='center'>
              <PulseLoader
                size={15}
                color='orange'
                loading={loading}
              />
            </Box>)}
      </Box>
    </Box>
  )
}
export default LegalStatus
