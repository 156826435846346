
import ApiService from './ApiService'

class OrganisationTagService {
  set = async (apiToken, data) => {
    var url = '/api/organisationtag'

    var params = {}

    var options = {
      data: data
    }

    try {
      var result = await ApiService.put(apiToken, url, params, options)
    } catch (err) {
      console.log(err)
    }
    console.log('OrganisationTagService:set ', result)
    return result
  }

  get = async (apiToken, params, where) => {
    var url = '/api/organisationtag'

    if (where) {
      params.where = where
    }

    try {
      var organisationTags = await ApiService.get(apiToken, url, params)
    } catch (err) {
      console.log(err)
    }
    console.log('OrganisationTagService:get ', organisationTags)
    return organisationTags?.data
  }
}
export default new OrganisationTagService()
