import React, { useState, useEffect } from 'react'

import { useParams } from 'react-router-dom'

import { constants } from '../../utils/constants'
import Responsive from '../../utils/Responsive'

import LocalStorageService from '../../services/LocalStorageService'
import UserService from '../../services/UserService'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Spinner
import PulseLoader from 'react-spinners/PulseLoader'

// Appt Components
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import DateInput from '../../components/simple/input/DateInput'
import Divider from '../../components/simple/divider/Divider'
import Form from '../../components/simple/form/Form.js'
import FormField from '../../components/simple/formField/FormField.js'
import FormFieldRequired from '../../components/simple/formField/FormFieldRequired'
import H2 from '../../components/simple/heading/H2.js'
import Text from '../../components/simple/text/Text.js'
import OptionButtons from '../../components/compound/optionButtons/OptionButtons'
import Select from '../../components/simple/input/Select'
import TextArea from '../../components/simple/input/TextArea'
import TextInput from '../../components/simple/input/TextInput'

function Basic (props) {
  const [loading, setLoading] = useState(false)
  const [localUserDetails, setLocalUserDetails] = useState(props.userDetails)
  const [questions, setQuestions] = useState(props.questions)

  const [staffValues, setStaffValues] = useState([])

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')
  const activeSite = LocalStorageService.get('activeSite')

  const params = useParams()

  const userId = params.userid

  // Submit User Details
  const submitUserDetails = () => {
    props.submitForm(localUserDetails)
  }

  // Need to include this as it takes time for
  // props.userDetails to get populated
  useEffect(() => {
    setLocalUserDetails(props.userDetails)
  }, [props.userDetails])

  useEffect(() => {
    let unmounted = false

    // Get Users for this organisation
    const getUsers = async () => {
      const params = {
        fields: 'id,firstName,lastName,reference',
        limit: 1000,
        orgId: activeOrg.id,
        siteId: activeSite?.id || null,
        type: 'staff'
      }

      setLoading(true)
      const users = await UserService.getUsers(apiToken, params)
      if (users?.error) {
        // NotificationService.error(users.error)
      } else {
        if (!unmounted) {
          if (users?.error) {
            setStaffValues(null)
          } else if (users?.data) {
            const mappedUsers = users.data.map((data, index) => ({
              id: data.id,
              name: data.firstName + ' ' + data.lastName
            }))

            setStaffValues(mappedUsers)
          }
        }
      }
    }

    getUsers()
    setLoading(false)

    return () => { unmounted = true }
  }, [])
  return (
    <Box gridArea='main' background='white' direction='column' gap='small' round='small'>
      <Divider color='primary' />
      <Box
        gap='small'
        margin={{ horizontal: 'small' }}
        pad='small'
        round='small'
      >

        <Box fill direction='row' gap='small'>
          <H2 margin={{ vertical: 'none' }}>Basic</H2>
          <Button label={<FontAwesomeIcon icon={['fal', 'question-circle']} />} plain tip='Basic Client Details' />
        </Box>
        {localUserDetails && questions.length
          ? (
            <Form
              onChange={nextValue => {
                setLocalUserDetails(nextValue)
              }}
              onSubmit={({ value: nextValue }) => {
                submitUserDetails(nextValue)
              }}
              // validate='blur'
              value={localUserDetails}
            >

              <Responsive
                rows={['auto']}
                columns={{
                  small: ['auto'],
                  medium: ['2/3', '1/3'],
                  large: ['2/3', '1/3'],
                  xlarge: ['2/3', '1/3']
                }}
                gap='small'
              >
                <Box>
                  <Responsive
                    rows={['auto']}
                    columns={{
                      small: ['auto'],
                      medium: ['auto', 'auto', 'auto', 'auto'],
                      large: ['auto', 'auto', 'auto', 'auto'],
                      xlarge: ['auto', 'auto', 'auto', 'auto']
                    }}
                    gap='small'
                  >
                    <Box>
                      {/* Reference */}
                      <FormField
                        label={questions[0].questionText}
                        name={questions[0].key}
                      >
                        <Text>{userId && userId !== 'new' ? `C000${userId}` : 'NEW'}</Text>
                      </FormField>
                    </Box>

                    <Box>
                      {/* First Name */}
                      <FormFieldRequired
                        label={questions[1].questionText}
                        name={questions[1].key}
                      >
                        <TextInput
                          name={questions[1].key}
                          type='text'
                        />
                      </FormFieldRequired>
                    </Box>

                    <Box>
                      {/* Last Name */}
                      <FormFieldRequired
                        label={questions[2].questionText}
                        name={questions[2].key}
                      >
                        <TextInput
                          name={questions[2].key}
                          type='text'
                        />
                      </FormFieldRequired>
                    </Box>

                    <Box>
                      {/* Known As */}
                      <FormField
                        label={questions[3].questionText}
                        name={questions[3].key}
                      >
                        <TextInput
                          name={questions[3].key}
                          type='text'
                        />
                      </FormField>
                    </Box>
                  </Responsive>

                  <Responsive
                    rows={['auto']}
                    columns={{
                      small: ['auto'],
                      medium: ['auto', 'auto', 'auto', 'auto'],
                      large: ['auto', 'auto', 'auto', 'auto'],
                      xlarge: ['auto', 'auto', 'auto', 'auto']
                    }}
                    gap='small'
                  >
                    <Box>
                      {/* DoB */}
                      <FormField
                        label={questions[4].questionText}
                        name={questions[4].key}
                      >
                        <DateInput
                          name={questions[4].key}
                        />
                      </FormField>
                    </Box>
                    <Box>
                      {/* Gender */}
                      <FormField
                        label={questions[5].questionText}
                        name={questions[5].key}
                      >
                        <Select
                          name={questions[5].key}
                          emptySearchMessage='No genders found'
                          // onChange={
                          //   (event, option) => { setAcademicYear(event.value) }
                          // }
                          labelKey='label'
                          options={constants.gender}
                          valueKey={{ key: 'value', reduce: true }}
                        />
                      </FormField>
                    </Box>
                    <Box>
                      {/* Preferred Pronoun */}
                      <FormField
                        label={questions[6].questionText}
                        name={questions[6].key}
                      >
                        <TextInput
                          name={questions[6].key}
                          type='text'
                          placeholder='e.g. they/them'
                        />
                      </FormField>
                    </Box>
                    <Box>
                      {/* Date Support Started */}
                      <FormField
                        label={questions[7].questionText}
                        name={questions[7].key}
                      >
                        <DateInput
                          name={questions[7].key}
                        />
                      </FormField>
                    </Box>
                  </Responsive>

                  <Divider color='lightgrey' margin={{ top: 'small', bottom: 'small' }} />

                  <Responsive
                    rows={['auto']}
                    columns={{
                      small: ['auto'],
                      medium: ['auto', 'auto', 'auto'],
                      large: ['auto', 'auto', 'auto'],
                      xlarge: ['auto', 'auto', 'auto']
                    }}
                    gap='small'
                  >
                    <Box>
                      {/* Primary Language */}
                      <FormField
                        label={questions[8].questionText}
                        name={questions[8].key}
                      >
                        <Select
                          name={questions[8].key}
                          emptySearchMessage='No languages found'
                          // onChange={
                          //   (event, option) => { setAcademicYear(event.value) }
                          // }
                          labelKey='label'
                          options={constants.language}
                          valueKey={{ key: 'value', reduce: true }}
                        />
                      </FormField>
                    </Box>

                    <Box>
                      {/* Other Language */}
                      <FormField
                        label={questions[11].questionText}
                        name={questions[11].key}
                      >
                        <Select
                          name={questions[11].key}
                          emptySearchMessage='No languages found'
                          // onChange={
                          //   (event, option) => { setAcademicYear(event.value) }
                          // }
                          labelKey='label'
                          options={constants.language}
                          valueKey={{ key: 'value', reduce: true }}
                        />
                      </FormField>
                    </Box>
                  </Responsive>

                  <Responsive
                    rows={['auto']}
                    columns={{
                      small: ['auto'],
                      medium: ['auto', 'auto', 'auto'],
                      large: ['auto', 'auto', 'auto'],
                      xlarge: ['auto', 'auto', 'auto']
                    }}
                    gap='small'
                  >
                    <Box>
                      {/* emergencyContactName */}
                      <FormField
                        label={questions[12].questionText}
                        name={questions[12].key}
                      >
                        <TextInput
                          name={questions[12].key}
                          type='text'
                        />
                      </FormField>
                    </Box>

                    <Box>
                      {/* emergencyContactPhone */}
                      <FormField
                        label={questions[13].questionText}
                        name={questions[13].key}
                      >
                        <TextInput
                          name={questions[13].key}
                          type='text'
                        />
                      </FormField>
                    </Box>
                  </Responsive>

                  <Responsive
                    rows={['auto']}
                    columns={{
                      small: ['auto'],
                      medium: ['auto', 'auto', 'auto'],
                      large: ['auto', 'auto', 'auto'],
                      xlarge: ['auto', 'auto', 'auto']
                    }}
                    gap='small'
                  >

                    <Box>
                      {/* Interpreter Needed */}
                      <FormField
                        label={questions[14].questionText}
                        name={questions[14].key}
                      >
                        <OptionButtons
                          id={questions[14].questionText}
                          name={questions[14].key}
                        />
                      </FormField>
                    </Box>

                    <Box>
                      {/* Childcare Needed */}
                      <FormField
                        label={questions[15].questionText}
                        name={questions[15].key}
                      >
                        <OptionButtons
                          id={questions[15].key}
                          name={questions[15].key}
                        />
                      </FormField>
                    </Box>
                  </Responsive>

                  <Divider color='lightgrey' margin={{ top: 'small', bottom: 'small' }} />

                  <Responsive
                    rows={['auto']}
                    columns={{
                      small: ['auto'],
                      medium: ['auto', 'auto', 'auto', 'auto'],
                      large: ['auto', 'auto', 'auto', 'auto'],
                      xlarge: ['auto', 'auto', 'auto', 'auto']
                    }}
                    gap='small'
                  >
                    <Box>
                      {/* Support Organisation */}
                      <FormField
                        label={(<Box height='40px'>{questions[16].questionText}</Box>)}
                        name={questions[16].key}
                      >
                        <Select
                          name={questions[16].key}
                          emptySearchMessage='No organisations found'
                          // onChange={
                          //   (event, option) => { setAcademicYear(event.value) }
                          // }
                          labelKey='label'
                          options={constants.supportOrganisation}
                          valueKey={{ key: 'value', reduce: true }}
                        />
                      </FormField>
                    </Box>

                    <Box>
                      {/* Assign Caseworker */}
                      <FormField
                        label={(<Box height='40px'>{questions[17].questionText}</Box>)}
                        name={questions[17].key}
                      >
                        <Select
                          name={questions[17].key}
                          emptySearchMessage='No caseworkers found'
                          // onChange={
                          //   (event, option) => { setAcademicYear(event.value) }
                          // }
                          labelKey='name'
                          options={staffValues}
                          valueKey={{ key: 'id', reduce: true }}
                        />
                      </FormField>
                    </Box>

                    <Box>
                      {/* Assign Volunteers */}
                      <FormField
                        label={(<Box height='40px'>{questions[18].questionText}</Box>)}
                        name={questions[18].key}
                      >
                        <Select
                          name={questions[18].key}
                          emptySearchMessage='No volunteers found'
                          // onChange={
                          //   (event, option) => { setAcademicYear(event.value) }
                          // }
                          labelKey='name'
                          options={staffValues}
                          valueKey={{ key: 'id', reduce: true }}
                        />
                      </FormField>
                    </Box>

                    <Box>
                      {/* Freq. Volunteer Meetings */}
                      <FormField
                        label={(<Box height='40px'>{questions[19].questionText}</Box>)}
                        name={questions[19].key}
                      >
                        <Select
                          name={questions[19].key}
                          emptySearchMessage='No frequencies found'
                          // onChange={
                          //   (event, option) => { setAcademicYear(event.value) }
                          // }
                          labelKey='label'
                          options={constants.frequency}
                          valueKey={{ key: 'value', reduce: true }}
                        />
                      </FormField>
                    </Box>
                  </Responsive>

                  <Responsive
                    rows={['auto']}
                    columns={{
                      small: ['auto'],
                      medium: ['auto'],
                      large: ['auto'],
                      xlarge: ['auto']
                    }}
                    gap='small'
                  >
                    <Box>
                      {/* Registered Weeky Activities */}
                      <FormField
                        label={questions[20].questionText}
                        name={questions[20].key}
                      >
                        <TextArea
                          name={questions[20].key}
                        />
                      </FormField>
                    </Box>
                  </Responsive>
                </Box>
                <Box
                  margin={{ horizontal: 'medium' }}
                  direction='column'
                >
                  <Box>
                    {/* Date Counselling Referral */}
                    <FormField
                      label={questions[21].questionText}
                      name={questions[21].key}
                    >
                      <TextInput
                        name={questions[21].key}
                        type='text'
                      />
                    </FormField>
                  </Box>

                  <Box>
                    {/* Last Updated */}
                    <FormField
                      label={questions[22].questionText}
                      name={questions[22].key}
                    >
                      <TextInput
                        name={questions[22].key}
                        type='text'
                      />
                    </FormField>
                  </Box>

                  <Box height='medium'>
                    {/* Sticky Note */}
                    <FormField
                      contentProps={{ height: 'medium' }}
                      label={questions[23].questionText}
                      name={questions[23].key}
                    >
                      <TextArea
                        fill
                        name={questions[23].key}
                      />
                    </FormField>
                  </Box>
                </Box>
              </Responsive>

              <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />

              <Box direction='row' justify='between' margin={{ top: 'medium' }}>
                <Button label='< Back' onClick={() => props.previousPage()} secondary />
                <Button type='submit' label='Save' primary />
              </Box>
            </Form>)
          : (
            <Box align='center'>
              <PulseLoader
                size={15}
                color='orange'
                loading={loading}
              />
            </Box>)}
      </Box>
    </Box>
  )
}

export default Basic
