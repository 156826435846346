import React, { useEffect, useState } from 'react'

// Appt Components
import Box from '../../components/simple/box/Box'
import Select from '../../components/simple/input/Select'
import Text from '../../components/simple/text/Text'

// Services
import FormService from '../../services/FormService'
import LocalStorageService from '../../services/LocalStorageService'

const FieldBox = (props) => {
  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')

  const [submissions, setSubmissions] = useState([])
  const [responses, setResponses] = useState([])

  useEffect(() => {
    let unmounted = false

    // Get submissions
    const getSubmissions = async () => {
      const where = {
        organisationId: activeOrg.id,
        type: 'incidentreport',
        status: 'Published'
      }

      const incidentReportSubmissions = await FormService.getSubmissions(apiToken, where)

      if (!unmounted) {
        setSubmissions(incidentReportSubmissions.data)
        // console.log(userDetails.data)
      }
    }

    getSubmissions()

    return () => { unmounted = true }
  }, [])

  useEffect(() => {
    // Get responses
    const getResponses = async () => {
      const submissionIds = []

      for (const submission of submissions) {
        submissionIds.push(submission.id)
      }

      // submissions.forEach(submission => {
      //   submissionIds.push(submission.id)
      // })
      const params = {
        fields: 'id,user,organisation,submission,questionKey,questionText,response',
        limit: 1000
      }

      const where = {
        organisation: activeOrg.id,
        submission: submissionIds
      }

      console.log('WHERE ', where)
      const incidentReportResponses = await FormService.getResponses(apiToken, params, where)

      const questionData = []
      incidentReportResponses.data.forEach((item) => {
        var i = questionData.findIndex(x => x.questionKey === item.questionKey)
        if (i <= -1) {
          questionData.push({ questionKey: item.questionKey, questionText: item.questionText })
        }
      })
      // Create a Set so unique questionKey values can be extracted
      // const responsesSet = new Set(incidentReportResponses.data)
      // const questionKeys = [...new Set(incidentReportResponses.data.map((o) => {
      //   const questionData = {}

      //   questionData.questionKey = o.questionKey
      //   questionData.questionText = o.questionText

      //   return questionData
      // }))]

      // console.log(incidentReportResponses)
      // setResponses(incidentReportResponses.data)
      setResponses(questionData)
    }

    if (submissions?.length) {
      getResponses()
    }
  }, [submissions])

  return (
    <>
      <Select
        {...props}
        // id='student'
        //   disabled={props.userId !== 'new'}
        //   name={questions[0].key}
        labelKey='questionText'
        options={responses}
        valueKey={{ key: 'questionKey', reduce: true }}
      />
    </>
  // <Box background={props.type === 'M' ? 'status-ok' : 'status-error'} direction='row-responsive' margin={{ horizontal: 'medium' }} pad={{ horizontal: 'medium', vertical: 'small' }} round='xsmall'>
    //   <Box>
    //     {/* <Button type='button' class='close' data-dismiss='alert'>×</Button> */}
    //     <Text>{props.message}</Text>
    //   </Box>
    // </Box>
  )
}

export default FieldBox
